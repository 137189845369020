import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { createCopyProjectByHash } from '../../api/projects';

function ShareHandler() {
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!hash) return;

    const copyProject = async () => {
      const formData = new FormData();

      formData.append('scene', hash);

      try {
        const { digitalGood: digitalGoodId } = await createCopyProjectByHash(
          formData,
        );

        window.location.href = `/tool/scene/${digitalGoodId}/`;
      } catch {
        setLoading(false);
      }
    };

    copyProject();
  }, []);

  return (
    <div className="bg-[#212121] flex justify-center items-center h-screen">
      <div className="text-center text-white font-bold text-2xl tracking-[.02em]">
        {loading ? (
          <>
            <h1
              text="coolab"
              className="text-8xl mb-6 tracking-[.05em] preloader-logo"
            >
              coolab
            </h1>
            <span className="leading-10">[ loading ]</span>
          </>
        ) : (
          <span className="leading-10">
            [ error ]
            <br />
            something broke:
            <br />
            please refresh and try again.
          </span>
        )}
      </div>
    </div>
  );
}

export default ShareHandler;
