import React from 'react';

const StudioProjectsIcon = ({ fill = 'white' }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33199 11.3844C0.959788 11.3844 0.644738 11.2555 0.386843 10.9976C0.128948 10.7397 0 10.4246 0 10.0524V1.94721C0 1.57502 0.128948 1.25997 0.386843 1.00208C0.644738 0.744182 0.959788 0.615234 1.33199 0.615234H5.80974L7 1.8055H12.668C13.0402 1.8055 13.3553 1.93444 13.6132 2.19234C13.8711 2.45023 14 2.76528 14 3.13749V10.0524C14 10.4246 13.8711 10.7397 13.6132 10.9976C13.3553 11.2555 13.0402 11.3844 12.668 11.3844H1.33199ZM1.10525 1.94721V10.0524C1.10525 10.1186 1.12651 10.1729 1.16902 10.2154C1.21154 10.2579 1.26586 10.2792 1.33199 10.2792H12.668C12.7341 10.2792 12.7885 10.2579 12.831 10.2154C12.8735 10.1729 12.8948 10.1186 12.8948 10.0524V3.13749C12.8948 3.07136 12.8735 3.01703 12.831 2.97452C12.7885 2.932 12.7341 2.91074 12.668 2.91074H6.54798L5.35772 1.72048H1.33199C1.26586 1.72048 1.21154 1.74174 1.16902 1.78426C1.12651 1.82676 1.10525 1.88108 1.10525 1.94721ZM1.10525 1.94721V1.72048V10.2792V1.94721Z"
        fill={fill}
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default StudioProjectsIcon;
