import React from 'react';

// components
import GenreAssetsTopbar from './GenreAssetsTopbar';
import GenreAssetsContent from './GenreAssetsContent';

const GenreAssets = () => {
  return (
    <>
      <GenreAssetsTopbar />
      <GenreAssetsContent />
    </>
  );
};

export default GenreAssets;
