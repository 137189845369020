import React from 'react';
import cn from 'classnames';

// types
import {
  IFoldersListContextMenu,
  ProjectsFolderType,
} from '../../../types/studio.types';

const FoldersListContextMenu = ({
  isShown,
  position,
  isToolStudio,
  projectFolders,
  onReturnToSelectedContextMenu,
  onMoveProjectsToFolder,
}: IFoldersListContextMenu) => {
  if (!isShown) {
    return null;
  }

  return (
    <div
      style={{ top: position.y, left: position.x }}
      className={cn('context-menu', 'folder', {
        light: !isToolStudio,
      })}
    >
      <button
        className="context-menu--option"
        onClick={onReturnToSelectedContextMenu}
        id="selected-context-menu-add-to-folder"
        type="button"
      >
        <img
          src="/static/img/icons/arrow-back-white.svg"
          alt="arrow"
          className="w-[14px] h-[16px] mb-1"
        />
        <span className="context-menu--option item-text folder-text">Back</span>
      </button>
      {projectFolders &&
        projectFolders.map((folder: ProjectsFolderType) => {
          return (
            <button
              className="context-menu--option folder"
              onClick={() => {
                if (onMoveProjectsToFolder) {
                  onMoveProjectsToFolder(folder.id);
                }
              }}
              id={`selected-context-menu-folder-${folder.id}`}
              type="button"
              key={folder.id}
            >
              <img
                src="/static/img/icons/folder-white.svg"
                alt="folder"
                className="w-[16px] h-[16px] mb-1"
              />
              <span className="context-menu--option item-text folder-text">
                {folder.name}
              </span>
            </button>
          );
        })}
    </div>
  );
};

export default FoldersListContextMenu;
