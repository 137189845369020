import React, { useState } from 'react';
import cn from 'classnames';

// components
import Toggle from '../../../elements/Toggle';

// api
import { createNewVariantsDesignAssistance } from '../../../api/helpers';

// types
import { ToggleOptionType } from '../../../types/elements.types';

const CreateNewVariantsOption = () => {
  const [variantsAmount, setVariantsAmount] = useState<ToggleOptionType | null>(
    null,
  );
  const [shouldAddBrandAssets, setShouldAddBrandAssets] =
    useState<ToggleOptionType | null>(null);
  const [addAssetInsteadOfReplacing, setAddAssetInsteadOfReplacing] =
    useState<ToggleOptionType | null>(null);

  const isProceedDisabled =
    !variantsAmount || !shouldAddBrandAssets || !addAssetInsteadOfReplacing;

  const onToggleVariantsAmount = (selectedOption: ToggleOptionType) => {
    setVariantsAmount(selectedOption);
  };

  const onToggleShouldAddBrandAssets = (selectedOption: ToggleOptionType) => {
    setShouldAddBrandAssets(selectedOption);
  };

  const onToggleAddAssetInsteadOfReplacing = (
    selectedOption: ToggleOptionType,
  ) => {
    setAddAssetInsteadOfReplacing(selectedOption);
  };

  const onProceedToBilling = () => {
    createNewVariantsDesignAssistance({
      numberOfVariants: variantsAmount?.value,
      brandAssets: shouldAddBrandAssets?.value,
      addOrChangeAsset: addAssetInsteadOfReplacing?.value,
    }).then((resp) => {
      console.log(resp);
    });
  };

  return (
    <div>
      <p className="design-assistance-label">
        How many variants would you like to order?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 1, label: '1', value: 1 },
            { id: 2, label: '2', value: 2 },
            { id: 3, label: '3', value: 3 },
            { id: 4, label: '4', value: 4 },
            { id: 5, label: '5', value: 5 },
          ]}
          selectedOption={variantsAmount}
          toggleHandler={onToggleVariantsAmount}
        />
      </div>

      <p className="design-assistance-label">
        Would you like brand assets added to the design?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={shouldAddBrandAssets}
          toggleHandler={onToggleShouldAddBrandAssets}
        />
      </div>

      <p className="design-assistance-label">
        Would you like to add an asset or change an existing asset?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={addAssetInsteadOfReplacing}
          toggleHandler={onToggleAddAssetInsteadOfReplacing}
        />
      </div>

      <div className="design-assistance-conclusion">
        <p className="design-assistance-conclusion--total">total</p>
        <p className="design-assistance-conclusion--price">$100</p>
        <button
          type="button"
          className={cn('design-assistance-conclusion--proceed-billing', {
            disabled: isProceedDisabled,
          })}
          disabled={isProceedDisabled}
          onClick={onProceedToBilling}
        >
          Proceed to billing
        </button>
      </div>
    </div>
  );
};

export default CreateNewVariantsOption;
