import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EntityId } from '@reduxjs/toolkit';

import Header from '../Header';
import Logo from '../Header/Logo';
import ProjectsButton from '../Header/ProjectsButton';
import UndoButton from '../Header/UndoButton';
import EditableTitle from '../Header/EditableTitle';
import ZoomButton from '../Header/ZoomButton';
import ActionButton from '../Header/ActionButton';
import ThreeDScene from '../3DScene';
import Sidebar from '../Sidebar';
import UnsupportedDeviceScreen from '../UnsupportedDeviceScreen';

import { actions as navbarActions } from '../../ducks/navbar';
import { actions as sidebarContentActions } from '../../ducks/sidebarContent';
import { navbarData, sidebarContentData } from './config';

import { useAppDispatch, useMount } from '../../helpers/hooks';

import { InputChangeEventType } from '../../types/common.types';

function Sandbox() {
  const dispatch = useAppDispatch();

  const [projectTitle, setProjectTitle] = useState('Test project name');
  const [zoomValue, setZoomValue] = useState('100%');
  const [isZoomDropdownOpened, setIsZoomDropdownOpened] = useState(false);
  const [selectedId, setSelectedId] = useState<EntityId | null>(null);

  const [searchParams] = useSearchParams();

  const templateId = searchParams.get('templateId');

  useMount(() => {
    dispatch(navbarActions.setNavbar(navbarData));
    dispatch(sidebarContentActions.setSidebarContent(sidebarContentData));
  });

  const onProjectsButtonClick = () => {
    window.location.href = templateId ? '/templates/' : '/projects/';
  };

  const onTitleChange = (e: InputChangeEventType) => {
    setProjectTitle(e.target.value);
  };

  const openZoomDropdown = () => {
    setIsZoomDropdownOpened(true);
  };

  const closeZoomDropdown = () => {
    setIsZoomDropdownOpened(false);
  };

  const onZoomOptionSelect = (zoomOptionValue: string) => {
    zoomOptionValue && setZoomValue(zoomOptionValue);
  };

  if (window && window.innerWidth < 768) {
    return <UnsupportedDeviceScreen />;
  }

  return (
    <div
      style={{
        width: '100vw',
        height: 'calc(100vh - 48px)',
      }}
    >
      <div className="Editor__block">
        <Header
          leftEl={
            <>
              {/* <Logo /> */}
              <ProjectsButton
                handleClick={onProjectsButtonClick}
                label={templateId ? 'Templates' : 'Projects'}
              />
              {/* <UndoButton isRedo /> */}
            </>
          }
          middleEl={
            <>
              {/* <EditableTitle title={projectTitle} handleChange={onTitleChange} /> */}
            </>
          }
          rightEl={
            <>
              {/* <ZoomButton
                isDropdownOpened={isZoomDropdownOpened}
                zoomValue={zoomValue}
                openZoomDropdown={openZoomDropdown}
                closeZoomDropdown={closeZoomDropdown}
                onZoomOptionSelect={onZoomOptionSelect}
              />
              <ActionButton /> */}
            </>
          }
        />
        <div className="Editor__body">
          <Sidebar
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            groupParams={{ elementsInRow: 2, backgroundCover: false }}
            gridParams={{ elementsInRow: 2, backgroundCover: false }}
          />
          <div className="Editor__canvasContainer">
            <ThreeDScene />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sandbox;
