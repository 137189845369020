import React from 'react';

// components
import ReGenDesignAssistanceContent from './ReGenDesignAssistanceContent';

// types
import { IReGenDesignAssistance } from '../../../types/studio-re-gen';

// styles
import '../../../styles/studio/re-gen.scss';

const ReGenDesignAssistance = ({
  selectedGeneratedProject,
}: IReGenDesignAssistance) => {
  return (
    <div className="design-assistance-container">
      <div className="design-assistance-container--design-container">
        <img
          src={selectedGeneratedProject?.preview}
          alt="design"
          className="design-assistance-container--design-container--image"
        />
      </div>
      <ReGenDesignAssistanceContent />
    </div>
  );
};

export default ReGenDesignAssistance;
