import React from 'react';
import cn from 'classnames';

// types
import { ISelectedProjectContextMenu } from '../../../types/studio.types';

const SelectedProjectContextMenu = ({
  isShown,
  position,
  onDeselectProjects,
  isToolStudio,
  onSetInProgressStatus,
  onSetCompletedStatus,
  onMultipleDeletionModalOpen,
  onStartMovingProjects,
}: ISelectedProjectContextMenu) => {
  if (!isShown) {
    return null;
  }

  return (
    <div
      style={{ top: position.y, left: position.x }}
      className={cn('context-menu', '!px-1', {
        light: !isToolStudio,
      })}
    >
      <button
        className="context-menu--option"
        onClick={onStartMovingProjects}
        id="selected-context-menu-add-to-folder"
        type="button"
      >
        <img src="/static/img/icons/white-plus.svg" alt="plus" />
        <span className="context-menu--option item-text">Add to folder</span>
      </button>
      <button
        className="context-menu--option"
        onClick={onSetCompletedStatus}
        id="selected-context-menu-set-completed"
        type="button"
      >
        <img
          src="/static/img/icons/tick-white-default.svg"
          alt="tick"
          className="ml-1"
        />
        <span className="context-menu--option item-text ml-1">
          Mark as completed
        </span>
      </button>
      <button
        className="context-menu--option"
        onClick={onSetInProgressStatus}
        id="selected-context-menu-set-in-progress"
        type="button"
      >
        <img
          src="/static/img/icons/tick-white-default.svg"
          alt="tick"
          className="ml-1"
        />
        <span className="context-menu--option item-text ml-1">
          Mark as in progress
        </span>
      </button>
      <button
        className="context-menu--option"
        onClick={onDeselectProjects}
        id="selected-context-menu-deselect"
        type="button"
      >
        <img
          src="/static/img/icons/bold-cross-white.svg"
          alt="cross"
          className="ml-1"
        />
        <span className="context-menu--option item-text ml-1">
          Deselect all
        </span>
      </button>
      <button
        className="context-menu--option"
        onClick={onMultipleDeletionModalOpen}
        id="selected-context-menu-delete"
        type="button"
      >
        <img
          src="/static/img/icons/delete-white.svg"
          alt="can"
          className="ml-1"
        />
        <span className="context-menu--option item-text ml-[6px]">
          Delete all selected
        </span>
      </button>
    </div>
  );
};

export default SelectedProjectContextMenu;
