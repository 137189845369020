import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { NAVBAR } from '../../helpers/constants';

import { NavbarButtonType } from './types';

export const navbarAdapter = createEntityAdapter<NavbarButtonType>();

const name = NAVBAR;
const initialState = navbarAdapter.getInitialState();

const setNavbar = navbarAdapter.upsertMany;

const reducers = {
  setNavbar,
};

export default createSlice({ name, initialState, reducers });
