import React, { useState } from 'react';

// types
import {
  IPageFormatsSection,
  IPageFormatOption,
} from '../../../types/studio-format-extender.types';

import { InputChangeEventType } from '../../../types/common.types';

// styles
import '../../../styles/studio/format-extender.scss';

const PageFormatOption = ({
  pageFormat,
  onPageFormatSelect,
}: IPageFormatOption) => {
  return (
    <div className="formats-container--dropdown--formats--option">
      <img
        src={pageFormat.originalLogo}
        alt="format icon"
        className="formats-container--dropdown--formats--option--icon"
      />{' '}
      <p className="formats-container--dropdown--formats--option--name">
        {pageFormat.name}
      </p>{' '}
      <input
        type="checkbox"
        className="formats-container--dropdown--formats--option--checkbox"
        onChange={(event) => onPageFormatSelect(pageFormat, event)}
      />
    </div>
  );
};

const PageFormatsSection = ({
  pageFormats,
  onPageFormatSelect,
}: IPageFormatsSection) => {
  const [query, setQuery] = useState('');

  const onQueryChange = (e: InputChangeEventType) => {
    setQuery(e.target.value);
  };

  const filteredPageFormats = pageFormats.filter((pageFormat) =>
    pageFormat.name.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <div className="formats-container--dropdown">
      <div className="formats-container--dropdown--input-container">
        <input
          type="text"
          className="formats-container--dropdown--input-container--input"
          placeholder="Search"
          value={query}
          onChange={onQueryChange}
        />
        <img
          src="/static/img/icons/search.svg"
          alt="search"
          className="formats-container--dropdown--input-container--icon"
        />
      </div>
      <div className="formats-container--dropdown--formats">
        {filteredPageFormats.length ? (
          filteredPageFormats.map((pageFormat) => (
            <PageFormatOption
              key={pageFormat.id}
              pageFormat={pageFormat}
              onPageFormatSelect={onPageFormatSelect}
            />
          ))
        ) : (
          <p className="formats-container--dropdown--formats--no-options">
            No formats
          </p>
        )}
      </div>
    </div>
  );
};

export default PageFormatsSection;
