import fetch from '../helpers/customFetch';

export const createProject = (data, options = {}) => {
  try {
    return fetch({
      url: '/projects/',
      method: 'POST',
      data,
      ...options,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createShareProjectHash = (data) => {
  try {
    return fetch({
      url: '/projects/share/encode/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createCopyProjectByHash = (data) => {
  try {
    return fetch({
      url: '/projects/share/copy/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjects = (
  folderId,
  ordering,
  status,
  page,
  pageSize,
  search,
) => {
  const queryParams = new URLSearchParams();

  if (folderId) {
    queryParams.set('folder', folderId);
  }

  if (ordering) {
    queryParams.set('ordering', ordering);
  }

  if (status) {
    queryParams.set('status', status);
  }

  if (page) {
    queryParams.set('page', page);
  }

  if (pageSize) {
    queryParams.set('page_size', pageSize);
  }

  if (search) {
    queryParams.set('search', search);
  }

  try {
    return fetch({
      url: `/projects/?${queryParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProject = (project) => {
  try {
    return fetch({
      url: `/projects/${project.id}/`,
      method: 'PATCH',
      data: project,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteProject = (id) => {
  try {
    return fetch({
      url: `/projects/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const duplicateProject = (id) => {
  try {
    return fetch({
      url: `/projects/${id}/duplicate/`,
      method: 'POST',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProjectsOrder = (projects) => {
  try {
    return fetch({
      url: '/projects/update_order/',
      method: 'PATCH',
      data: projects,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateFoldersOrder = (folders) => {
  try {
    return fetch({
      url: '/project-folders/update_order/',
      method: 'PATCH',
      data: folders,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjectsFolders = (pageSize) => {
  const queryParams = new URLSearchParams();

  if (pageSize) {
    queryParams.set('page_size', pageSize);
  }

  try {
    return fetch({
      url: `/project-folders/?${queryParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getProjectsSingleFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const createProjectFolder = (newFolder) => {
  try {
    return fetch({
      url: '/project-folders/',
      method: 'POST',
      data: newFolder,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateProjectFolder = (projectFolder) => {
  try {
    return fetch({
      url: `/project-folders/${projectFolder.id}/`,
      method: 'PATCH',
      data: projectFolder,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const duplicateProjectFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/duplicate/`,
      method: 'POST',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteProjectFolder = (id) => {
  try {
    return fetch({
      url: `/project-folders/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const multipleProjectStatusUpdate = (data, ids) => {
  const queryParams = new URLSearchParams();

  if (ids) {
    queryParams.set('ids', ids);
  }

  try {
    return fetch({
      url: `/projects/multiple_status_update/?${queryParams.toString()}`,
      method: 'PATCH',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const multipleProjectsDelete = (ids) => {
  const queryParams = new URLSearchParams();

  if (ids) {
    queryParams.set('ids', ids);
  }

  try {
    return fetch({
      url: `/projects/multiple_delete/?${queryParams.toString()}`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const moveProjectsToFolder = (folderId, data) => {
  try {
    return fetch({
      url: `/project-folders/${folderId}/add/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getSingleProject = (id) => {
  try {
    return fetch({
      url: `/projects/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};
