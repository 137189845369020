import React from 'react';

// styles
import '../../styles/studio/studio-content.scss';

const StudioEmptyPlaceholder = () => {
  return (
    <div className="empty-placeholder-container">
      <div className="empty-placeholder-container--info">Coming soon ...</div>
    </div>
  );
};

export default StudioEmptyPlaceholder;
