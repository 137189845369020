import React from 'react';

// styles
import '../../../styles/studio/format-extender.scss';

const FormatsExtenderPreloader = () => {
  return (
    <div className="format-extender-preloader">
      <p className="format-extender-preloader--title">creating formats...</p>
      <p className="format-extender-preloader--subtitle">
        creating new design files...please wait and don’t exit or refresh the
        page.
      </p>
    </div>
  );
};

export default FormatsExtenderPreloader;
