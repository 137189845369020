import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

// helpers
import { getToasterOptions } from '../../helpers/toaster';
import { SUGGESTIONS_ARRAY } from '../../helpers/common.data';

// api
import {
  createPromptToDesign,
  generatePromptToDesign,
  getPromptToDesign,
} from '../../api/helpers';

// types
import { InputChangeEventType } from '../../types/common.types';
import { useAppSelector } from '../../helpers/hooks';

type SuggestionType = { id: number; message: string };
type SuggestionsType = SuggestionType[];

const PromptToDesign = () => {
  const [isP2DModalOpened, setIsP2DOpened] = useState(false);
  const [p2dGenerationConfirmModalOpened, setP2dGenerationConfirmModalOpened] =
    useState(false);
  const [suggestions, setSuggestions] = useState<SuggestionsType | []>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<null | number>(
    null,
  );
  const [purposeValue, setPurposeValue] = useState('');
  const [p2dIsGenerating, setIsP2dGenerating] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(1);
  const [generatingStage, setGeneratingStage] = useState('');

  const suggestionsRef = useRef(null);

  const navigate = useNavigate();

  const onP2DModalOpen = () => {
    setIsP2DOpened(true);
  };

  const onP2DModalClose = () => {
    setIsP2DOpened(false);
  };

  const onP2DGenerationConfirmModalOpen = () => {
    setP2dGenerationConfirmModalOpened(true);
  };

  const onP2DGenerationConfirmModalClose = () => {
    setP2dGenerationConfirmModalOpened(false);
  };

  const onGenerateP2D = () => {
    onP2DModalClose();
    onP2DGenerationConfirmModalOpen();
  };

  const onConfirmationReject = () => {
    setPurposeValue('');
    setSelectedSuggestion(null);
    onP2DGenerationConfirmModalClose();
  };

  const onGeneratePromptToDesign = async (id: number) => {
    const response = await getPromptToDesign(id);

    if (response.progress > generatingProgress) {
      setGeneratingProgress(response.progress);
      setGeneratingStage(response.progressStage);
      // eslint-disable-next-line no-use-before-define
      stopGeneratingInterval();
    }

    const generatingProgressInterval = setInterval(() => {
      setGeneratingProgress((prevState) => prevState + 1);
    }, 1000);

    function stopGeneratingInterval() {
      clearInterval(generatingProgressInterval);
    }

    if (response.status == 'failed') {
      onP2DGenerationConfirmModalClose();
      setIsP2dGenerating(false);
      toast.error(
        'Prompt to design process failed. Please try again.',
        getToasterOptions(),
      );
    } else if (response.status === 'created') {
      onP2DGenerationConfirmModalClose();
      setIsP2dGenerating(false);
    } else if (response.status === 'in_progress') {
      setIsP2dGenerating(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await onGeneratePromptToDesign(id);
    } else if (response.status === 'finished') {
      onP2DGenerationConfirmModalClose();

      navigate({
        pathname: `/tool/scene/${response.userProjectId}`,
        search: `?origin_type=p2d&p2dId=${response.id}`,
      });

      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const onConfirmationSubmit = async () => {
    setPurposeValue('');
    setSelectedSuggestion(null);

    try {
      const createP2DResponse = await createPromptToDesign({
        purpose: purposeValue,
        pageSource: window.location.pathname,
      });

      const generatePromptToDesignData = { template: null };
      await generatePromptToDesign(
        createP2DResponse.id,
        generatePromptToDesignData,
      );

      onGeneratePromptToDesign(createP2DResponse.id);
    } catch (error) {
      let errorMessage =
        'Server is currently under heavy load, please try again later';

      if ((error as AxiosError).response?.status === 400) {
        errorMessage = 'Purpose field may not be blanked';
      }

      onP2DGenerationConfirmModalClose();
      toast.error(errorMessage, getToasterOptions());
    }
  };

  const onPurposeFieldChange = (e: InputChangeEventType) => {
    setPurposeValue(e.target.value);
  };

  const onSuggestionSelect = (suggestion: SuggestionType) => {
    setSelectedSuggestion((prevState) =>
      prevState === suggestion.id ? null : suggestion.id,
    );
    setPurposeValue(suggestion.message);
  };

  useEffect(() => {
    const suggestionsToSet = [];

    for (let i = 1; i < 4; i++) {
      const randomNumber = Math.floor(Math.random() * SUGGESTIONS_ARRAY.length);
      suggestionsToSet.push(SUGGESTIONS_ARRAY[randomNumber]);

      SUGGESTIONS_ARRAY.splice(randomNumber, 1);
    }

    setSuggestions(suggestionsToSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userProfile = useAppSelector((state) => state.userProfile);

  return (
    <>
      <button
        type="button"
        onClick={onP2DModalOpen}
        className="Bottom-actions__container__button"
        hidden={!userProfile.permissions.includes('AI_PROMPT_TO_DESIGN_SCENE')}
      >
        <span className="Bottom-actions__container__button__content">
          <img src="/static/img/icons/editor/prompt.svg" alt="help" />
          <span className="Bottom-actions__container__button__content__text">
            Prompt
          </span>
        </span>
      </button>
      <Modal
        isOpen={isP2DModalOpened}
        ariaHideApp={false}
        onRequestClose={onP2DModalClose}
        className="Modal__Content dark !max-w-[670px] !overflow-visible"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="p2d-modal-container">
          <div className="p2d-modal-container--info">
            <p className="p2d-modal-container--info--title">
              what would you like to generate?
            </p>
            <div
              className="p2d-modal-container--info--input-container"
              ref={suggestionsRef}
            >
              <input
                type="text"
                placeholder="Design a {FORMAT} for a {SUBJECT}"
                className="p2d-modal-container--info--input-container--input"
                value={purposeValue}
                onChange={onPurposeFieldChange}
                maxLength={150}
              />
              <div className="p2d-modal-container--info--suggestions-container">
                <p className="p2d-modal-container--info--suggestions-container--heading">
                  try these suggestions:
                </p>
                <div>
                  {suggestions.map((suggestion) => {
                    return (
                      <p
                        key={suggestion?.id}
                        className={cn(
                          'p2d-modal-container--info--suggestions-container--message',
                          {
                            active: suggestion?.id === selectedSuggestion,
                          },
                        )}
                        onClick={() => onSuggestionSelect(suggestion)}
                      >
                        {suggestion?.message}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="p2d-modal-container--btns-container">
            <button
              type="button"
              className="p2d-modal-container--btns-container--back"
              onClick={onP2DModalClose}
            >
              back
            </button>
            <button
              type="button"
              className="p2d-modal-container--btns-container--generate"
              onClick={onGenerateP2D}
            >
              use prompt to design
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={p2dGenerationConfirmModalOpened}
        ariaHideApp={false}
        onRequestClose={onP2DGenerationConfirmModalClose}
        className="Modal__Content dark !max-w-[575px]"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        {p2dIsGenerating ? (
          <>
            <div
              className="p2d-confirmation-modal-preloader-container"
              style={{ width: `${generatingProgress}%` }}
            />
            <p className="p2d-confirmation-modal-preloader-container--stage">
              {generatingStage}
            </p>
          </>
        ) : (
          <div className="p2d-confirmation-modal">
            <p className="p2d-confirmation-modal--heading">
              this will exit and create a new project
            </p>
            <p className="p2d-confirmation-modal--info">
              Do you still wish to proceed?
            </p>
            <div className="p2d-confirmation-modal--btns-container">
              <button
                type="button"
                className="p2d-confirmation-modal--btns-container--stay-here-btn"
                onClick={onConfirmationReject}
              >
                stay here
              </button>
              <button
                type="button"
                className="p2d-confirmation-modal--btns-container--ignore-btn"
                onClick={onConfirmationSubmit}
              >
                ignore my current project
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PromptToDesign;
