import React, { useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import GridItem from './GridItem';
import LoadingState from './LoadingState';
import { IGroup } from '../../../types/studio.types';
import { useAppSelector, useAppDispatch } from '../../../helpers/hooks';
import { actions as sidebarContentActions } from '../../../ducks/sidebarContent';

import '../../../styles/sidebar/gridContent/group.scss';

export default ({
  id: groupId,
  entityId,
  groupParams = { elementsInRow: 2, backgroundCover: true },
}: IGroup) => {
  const dispatch = useAppDispatch();

  const { elementsInRow, backgroundCover } = groupParams;
  const {
    title = '',
    assets = [],
    groups = [],
    loading,
  } = useAppSelector(
    (state) => state.sidebarContent.entities[entityId]?.data.groups[groupId],
  ) || {};

  const isGroupsParent = useMemo(() => !!groups.length, [groups]);

  const itemsArr = useMemo(() => {
    if (loading) {
      return Array(elementsInRow)
        .fill(null)
        .map(() => uuidv4());
    }

    return [...(isGroupsParent ? groups : assets)].slice(0, elementsInRow);
  }, [loading, isGroupsParent, assets, groups, elementsInRow]);

  return (
    <div className="AssetLibrarySection__block">
      <div className="AssetLibrarySection__header">
        <h3 className="AssetLibrarySection__heading">{title}</h3>
        {!loading && (
          <button
            type="button"
            className="AssetLibrarySection__moreButton"
            onClick={() =>
              dispatch(
                sidebarContentActions.setSelectedGroupId({ entityId, groupId }),
              )
            }
          >
            <span>
              <span>{isGroupsParent ? 'More' : assets.length}</span>
              <img
                className="AssetLibrarySection__moreIcon"
                src="/static/img/icons/editor/arrow-next.svg"
                alt="more"
              />
            </span>
          </button>
        )}
      </div>
      <div
        className="AssetLibrarySection__previews"
        style={{ gridTemplateColumns: `repeat(${elementsInRow}, 1fr)` }}
      >
        {itemsArr.map((id) => (
          <GridItem
            key={`grid_item_${id}`}
            id={id}
            isGroup={isGroupsParent}
            style={{ gridRowEnd: 'span 11' }}
            backgroundCover={backgroundCover}
            entityId={entityId}
          />
        ))}
        {loading && (
          <LoadingState
            loading={loading}
            // message="Cannot connect to asset source"
            positionAbsolute
          />
        )}
      </div>
    </div>
  );
};
