import React, { useState, useMemo, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { getToasterOptions } from '../../helpers/toaster';

import {
  IFeedbackForm,
  DropdownOptionLabelType,
  FeedbackComponentType,
  CategoryType,
} from '../../types/bottom-actions.types';
import { darkDropdownStyles } from '../../helpers/common.data';

import '../../styles/bottom-actions/modal.scss';
import '../../styles/elements/toaster.scss';

const formatOptionLabel = ({ label }: DropdownOptionLabelType) => (
  <span style={{ fontWeight: 500 }}>{label}</span>
);

const FeedbackComponent = ({
  createFeedback,
  getPreviewFile,
  getData,
}: FeedbackComponentType) => {
  const [data, setData] = useState<CategoryType[]>([]);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isFeedbackSuccessModalOpen, setIsFeedbackSuccessModalOpen] =
    useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset: resetForm,
  } = useForm<IFeedbackForm>();

  const dropdownOptions = useMemo(
    () =>
      data.map(({ id, name }) => ({
        value: String(id),
        label: name,
      })),
    [data],
  );

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);
  const openFeedbackSuccessModal = () => setIsFeedbackSuccessModalOpen(true);
  const closeFeedbackSuccessModal = () => setIsFeedbackSuccessModalOpen(false);

  const feedbackModalOnSubmit: SubmitHandler<IFeedbackForm> = async (
    formValues,
  ) => {
    const formData = new FormData();

    formData.append('category', formValues.dropdown);
    formData.append('description', formValues.description);

    if (formValues.attachScreenshot) {
      formData.append('screenshot', await getPreviewFile());
    }

    try {
      await createFeedback(formData);

      closeFeedbackModal();
      openFeedbackSuccessModal();
      resetForm();
    } catch (error) {
      toast.error('Ooooops, error occurred', getToasterOptions());
    }
  };

  useEffect(() => {
    const fetchData = async () => setData(await getData());

    fetchData();
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={openFeedbackModal}
        className="Bottom-actions__container__button"
      >
        <span className="Bottom-actions__container__button__content">
          <img src="/static/img/icons/editor/feedback.svg" alt="help" />
          <span className="Bottom-actions__container__button__content__text">
            Feedback
          </span>
        </span>
      </button>
      <Modal
        isOpen={isFeedbackModalOpen}
        ariaHideApp={false}
        onRequestClose={closeFeedbackModal}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <form className="form" onSubmit={handleSubmit(feedbackModalOnSubmit)}>
          <h5 className="Modal__Content__title">Give us some Feedback!</h5>
          <p className="text-sm font-mono Modal__Content__select-label">
            What category does it belong to?
          </p>
          <Controller
            control={control}
            name="dropdown"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Suggest a feature"
                styles={darkDropdownStyles()}
                formatOptionLabel={formatOptionLabel}
                isSearchable={false}
                options={dropdownOptions}
                value={dropdownOptions.find((c) => c.value === value)}
                onChange={(val) => onChange(val?.value)}
              />
            )}
          />
          {errors.dropdown && (
            <span className="field-error">Dropdown option is required</span>
          )}
          <label className="Modal__Content__description-container">
            Describe your feedback
            <textarea
              id="description"
              {...register('description', { required: true, maxLength: 200 })}
              placeholder="How can we improve our app?"
              className="Modal__Content__description-container__field"
            />
            {errors.description && (
              <span className="field-error">
                Is required. Max length is 200 characters
              </span>
            )}
          </label>

          <label className="Modal__Content__include-screenshot">
            <input {...register('attachScreenshot')} type="checkbox" />
            include a screenshot of your project
          </label>

          <div className="Modal__Content__submit-btn-container">
            <button
              type="submit"
              className="Modal__Content__submit-btn-container__btn"
            >
              send
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={isFeedbackSuccessModalOpen}
        ariaHideApp={false}
        className="Modal__Content dark"
        onRequestClose={closeFeedbackSuccessModal}
      >
        <div className="form">
          <h5 className="Modal__Content__title">feedback has been sent!</h5>
          <p className="Modal__Content__thanks-message">
            Thanks! We appreciate the feedback. A member of our team will look
            into it!
          </p>
          <div className="Modal__Content__submit-btn-container">
            <button
              className="Modal__Content__submit-btn-container__btn"
              type="submit"
              onClick={closeFeedbackSuccessModal}
            >
              ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

FeedbackComponent.defaultProps = {
  getPreviewFile: () => {},
  getData: () => {},
};

export default FeedbackComponent;
