import React from 'react';

const ReGenPreloader = () => {
  return (
    <div className="re-gen-welcome-container">
      <p className="re-gen-welcome-container--title">designs</p>
      <p className="re-gen-welcome-container--description">
        creating new design files...please wait and don’t exit or refresh the
        page.
      </p>
    </div>
  );
};

export default ReGenPreloader;
