import React from 'react';

// types
import { ISelectedPageFormats } from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const SelectedPageFormats = ({ selectedPageFormats }: ISelectedPageFormats) => {
  return (
    <div className="selected-formats-container">
      {selectedPageFormats.map((selectedPageFormat) => {
        return (
          <div
            key={selectedPageFormat.id}
            className="selected-formats-container--item"
          >
            <img
              src={selectedPageFormat.originalLogo}
              alt="format icon"
              className="selected-formats-container--item--icon"
            />
            <p className="selected-formats-container--item--name">
              {selectedPageFormat.name}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default SelectedPageFormats;
