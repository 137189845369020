import chroma from 'chroma-js';

const setLightness = (color, value = '0') => chroma(color).set('hsl.l', value);

const isDark = (color) => chroma(color).luminance() < 0.5;

const getContrastColor = (color) =>
  isDark(color) ? chroma('white') : chroma('black');

const transfer = (key, from, to) => chroma(to).set(key, chroma(from).get(key));

const transferCL = (from, to) => {
  const ltran = transfer('lch.l', from, to).toString();

  return transfer('lch.c', from, ltran);
};

function generateColorAbstractionTokensAccent(color) {
  return {
    '--ubq-foreground-accent': getContrastColor(color).alpha(1.0).css('hsl'),
    '--ubq-interactive-accent-default': chroma(color).css('hsl'),
    '--ubq-interactive-accent-hover': setLightness(color, '+0.05').css('hsl'),
    '--ubq-interactive-accent-pressed': setLightness(color, '-0.05').css('hsl'),
    '--ubq-focus-default': transferCL(color, '#3355FF').css('hsl'),
    '--ubq-notice-warning': transferCL(color, '#FFBB33').css('hsl'),
    '--ubq-notice-error': transferCL(color, '#DC608E').css('hsl'),
    '--ubq-notice-success': transferCL(color, '#09B48B').css('hsl'),
  };
}

export const generateCustomTheme = (
  backgroundColor,
  activeColor,
  accentColor,
) => ({
  ...generateColorAbstractionTokensAccent(accentColor),
});

export const generateCustomThemeStyle = (customThemeProperties) => `
      .ubq-public{
      ${Object.entries(customThemeProperties)
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n')}
      }
`;
