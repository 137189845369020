const fetchImage = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();

  return blob;
};

export const downloadImage = async (url) => {
  const imageBlob = await fetchImage(url);
  const imageBase64 = URL.createObjectURL(imageBlob);

  const a = document.createElement('a');
  a.style.setProperty('display', 'none');
  document.body.appendChild(a);
  a.download = url.replace(/^.*[\\\/]/, '');
  a.href = imageBase64;
  a.click();
  a.remove();
};
