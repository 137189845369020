import React from 'react';

import { SidebarGridContentType } from '../../ducks/sidebarContent/types';
import { ITopbar } from '../../ducks/topbar/types';

import StudioProjectsIcon from '../../icons/StudioProjectsIcon';
import ReGenIcon from '../../icons/ReGenIcon';
import StudioFormatExtenderIcon from '../../icons/StudioFormatExtenderIcon';
import StudioBrandRoomIcon from '../../icons/StudioBrandRoomIcon';
import StudioGenerativeBriefIcon from '../../icons/StudioGenerativeBriefIcon';
import GenreAssetsIcon from '../../icons/GenreAssetsIcon';
import { StudioSidebarType } from '../../types/studio.types';

export const sidebarContentData: SidebarGridContentType[] = [
  {
    id: 12,
    type: 'gridContent',
    rootGroupId: 1,
    selectedGroupId: null,
    data: {
      groups: {
        1: {
          title: 'Models',
          groups: [2, 3, 4, 5, 6],
          id: 1,
          parentId: null,
          loading: false,
        },
        2: {
          title: 'Model 1',
          assets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
          id: 2,
          parentId: 1,
          loading: false,
        },
        3: {
          title: 'Model 2',
          assets: [
            17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
          ],
          id: 3,
          parentId: 1,
          loading: false,
        },
        4: {
          title: 'Model 3',
          assets: [
            33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
          ],
          id: 4,
          parentId: 1,
          loading: false,
        },
        5: {
          title: 'Model 4',
          assets: [
            49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
          ],
          id: 5,
          parentId: 1,
          loading: false,
        },
        6: {
          title: 'Model 5',
          assets: [
            65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
          ],
          id: 6,
          parentId: 1,
          loading: false,
        },
      },
      assets: {
        1: {
          url: '../../static/img/models/16_1/0001.png',
          id: 1,
          parentId: 2,
          actionType: 'studio',
        },
        2: {
          url: '../../static/img/models/16_1/0002.png',
          id: 2,
          parentId: 2,
          actionType: 'studio',
        },
        3: {
          url: '../../static/img/models/16_1/0003.png',
          id: 3,
          parentId: 2,
          actionType: 'studio',
        },
        4: {
          url: '../../static/img/models/16_1/0004.png',
          id: 4,
          parentId: 2,
          actionType: 'studio',
        },
        5: {
          url: '../../static/img/models/16_1/0005.png',
          id: 5,
          parentId: 2,
          actionType: 'studio',
        },
        6: {
          url: '../../static/img/models/16_1/0006.png',
          id: 6,
          parentId: 2,
          actionType: 'studio',
        },
        7: {
          url: '../../static/img/models/16_1/0007.png',
          id: 7,
          parentId: 2,
          actionType: 'studio',
        },
        8: {
          url: '../../static/img/models/16_1/0008.png',
          id: 8,
          parentId: 2,
          actionType: 'studio',
        },
        9: {
          url: '../../static/img/models/16_1/0009.png',
          id: 9,
          parentId: 2,
          actionType: 'studio',
        },
        10: {
          url: '../../static/img/models/16_1/0010.png',
          id: 10,
          parentId: 2,
          actionType: 'studio',
        },
        11: {
          url: '../../static/img/models/16_1/0011.png',
          id: 11,
          parentId: 2,
          actionType: 'studio',
        },
        12: {
          url: '../../static/img/models/16_1/0012.png',
          id: 12,
          parentId: 2,
          actionType: 'studio',
        },
        13: {
          url: '../../static/img/models/16_1/0013.png',
          id: 13,
          parentId: 2,
          actionType: 'studio',
        },
        14: {
          url: '../../static/img/models/16_1/0014.png',
          id: 14,
          parentId: 2,
          actionType: 'studio',
        },
        15: {
          url: '../../static/img/models/16_1/0015.png',
          id: 15,
          parentId: 2,
          actionType: 'studio',
        },
        16: {
          url: '../../static/img/models/16_1/0016.png',
          id: 16,
          parentId: 2,
          actionType: 'studio',
        },

        17: {
          url: '../../static/img/models/16_2/0001.png',
          id: 17,
          parentId: 3,
          actionType: 'studio',
        },
        18: {
          url: '../../static/img/models/16_2/0002.png',
          id: 18,
          parentId: 3,
          actionType: 'studio',
        },
        19: {
          url: '../../static/img/models/16_2/0003.png',
          id: 19,
          parentId: 3,
          actionType: 'studio',
        },
        20: {
          url: '../../static/img/models/16_2/0004.png',
          id: 20,
          parentId: 3,
          actionType: 'studio',
        },
        21: {
          url: '../../static/img/models/16_2/0005.png',
          id: 21,
          parentId: 3,
          actionType: 'studio',
        },
        22: {
          url: '../../static/img/models/16_2/0006.png',
          id: 22,
          parentId: 3,
          actionType: 'studio',
        },
        23: {
          url: '../../static/img/models/16_2/0007.png',
          id: 23,
          parentId: 3,
          actionType: 'studio',
        },
        24: {
          url: '../../static/img/models/16_2/0008.png',
          id: 24,
          parentId: 3,
          actionType: 'studio',
        },
        25: {
          url: '../../static/img/models/16_2/0009.png',
          id: 25,
          parentId: 3,
          actionType: 'studio',
        },
        26: {
          url: '../../static/img/models/16_2/0010.png',
          id: 26,
          parentId: 3,
          actionType: 'studio',
        },
        27: {
          url: '../../static/img/models/16_2/0011.png',
          id: 27,
          parentId: 3,
          actionType: 'studio',
        },
        28: {
          url: '../../static/img/models/16_2/0012.png',
          id: 28,
          parentId: 3,
          actionType: 'studio',
        },
        29: {
          url: '../../static/img/models/16_2/0013.png',
          id: 29,
          parentId: 3,
          actionType: 'studio',
        },
        30: {
          url: '../../static/img/models/16_2/0014.png',
          id: 30,
          parentId: 3,
          actionType: 'studio',
        },
        31: {
          url: '../../static/img/models/16_2/0015.png',
          id: 31,
          parentId: 3,
          actionType: 'studio',
        },
        32: {
          url: '../../static/img/models/16_2/0016.png',
          id: 32,
          parentId: 3,
          actionType: 'studio',
        },
        33: {
          url: '../../static/img/models/16_3/0001.png',
          id: 33,
          parentId: 4,
          actionType: 'studio',
        },

        34: {
          url: '../../static/img/models/16_3/0002.png',
          id: 34,
          parentId: 4,
          actionType: 'studio',
        },
        35: {
          url: '../../static/img/models/16_3/0003.png',
          id: 35,
          parentId: 4,
          actionType: 'studio',
        },
        36: {
          url: '../../static/img/models/16_3/0004.png',
          id: 36,
          parentId: 4,
          actionType: 'studio',
        },
        37: {
          url: '../../static/img/models/16_3/0005.png',
          id: 37,
          parentId: 4,
          actionType: 'studio',
        },
        38: {
          url: '../../static/img/models/16_3/0006.png',
          id: 38,
          parentId: 4,
          actionType: 'studio',
        },
        39: {
          url: '../../static/img/models/16_3/0007.png',
          id: 39,
          parentId: 4,
          actionType: 'studio',
        },
        40: {
          url: '../../static/img/models/16_3/0008.png',
          id: 40,
          parentId: 4,
          actionType: 'studio',
        },
        41: {
          url: '../../static/img/models/16_3/0009.png',
          id: 41,
          parentId: 4,
          actionType: 'studio',
        },
        42: {
          url: '../../static/img/models/16_3/0010.png',
          id: 42,
          parentId: 4,
          actionType: 'studio',
        },
        43: {
          url: '../../static/img/models/16_3/0011.png',
          id: 43,
          parentId: 4,
          actionType: 'studio',
        },
        44: {
          url: '../../static/img/models/16_3/0012.png',
          id: 44,
          parentId: 4,
          actionType: 'studio',
        },
        45: {
          url: '../../static/img/models/16_3/0013.png',
          id: 45,
          parentId: 4,
          actionType: 'studio',
        },
        46: {
          url: '../../static/img/models/16_3/0014.png',
          id: 46,
          parentId: 4,
          actionType: 'studio',
        },
        47: {
          url: '../../static/img/models/16_3/0015.png',
          id: 47,
          parentId: 4,
          actionType: 'studio',
        },
        48: {
          url: '../../static/img/models/16_3/0016.png',
          id: 48,
          parentId: 4,
          actionType: 'studio',
        },
        49: {
          url: '../../static/img/models/16_4/0001.png',
          id: 49,
          parentId: 5,
          actionType: 'studio',
        },

        50: {
          url: '../../static/img/models/16_4/0002.png',
          id: 50,
          parentId: 5,
          actionType: 'studio',
        },
        51: {
          url: '../../static/img/models/16_4/0003.png',
          id: 51,
          parentId: 5,
          actionType: 'studio',
        },
        52: {
          url: '../../static/img/models/16_4/0004.png',
          id: 52,
          parentId: 5,
          actionType: 'studio',
        },
        53: {
          url: '../../static/img/models/16_4/0005.png',
          id: 53,
          parentId: 5,
          actionType: 'studio',
        },
        54: {
          url: '../../static/img/models/16_4/0006.png',
          id: 54,
          parentId: 5,
          actionType: 'studio',
        },
        55: {
          url: '../../static/img/models/16_4/0007.png',
          id: 55,
          parentId: 5,
          actionType: 'studio',
        },
        56: {
          url: '../../static/img/models/16_4/0008.png',
          id: 56,
          parentId: 5,
          actionType: 'studio',
        },
        57: {
          url: '../../static/img/models/16_4/0009.png',
          id: 57,
          parentId: 5,
          actionType: 'studio',
        },
        58: {
          url: '../../static/img/models/16_4/0010.png',
          id: 58,
          parentId: 5,
          actionType: 'studio',
        },
        59: {
          url: '../../static/img/models/16_4/0011.png',
          id: 59,
          parentId: 5,
          actionType: 'studio',
        },
        60: {
          url: '../../static/img/models/16_4/0012.png',
          id: 60,
          parentId: 5,
          actionType: 'studio',
        },
        61: {
          url: '../../static/img/models/16_4/0013.png',
          id: 61,
          parentId: 5,
          actionType: 'studio',
        },
        62: {
          url: '../../static/img/models/16_4/0014.png',
          id: 62,
          parentId: 5,
          actionType: 'studio',
        },
        63: {
          url: '../../static/img/models/16_4/0015.png',
          id: 63,
          parentId: 5,
          actionType: 'studio',
        },
        64: {
          url: '../../static/img/models/16_4/0016.png',
          id: 64,
          parentId: 5,
          actionType: 'studio',
        },
        65: {
          url: '../../static/img/models/16_5/0001.png',
          id: 65,
          parentId: 6,
          actionType: 'studio',
        },

        66: {
          url: '../../static/img/models/16_5/0002.png',
          id: 66,
          parentId: 6,
          actionType: 'studio',
        },
        67: {
          url: '../../static/img/models/16_5/0003.png',
          id: 67,
          parentId: 6,
          actionType: 'studio',
        },
        68: {
          url: '../../static/img/models/16_5/0004.png',
          id: 68,
          parentId: 6,
          actionType: 'studio',
        },
        69: {
          url: '../../static/img/models/16_5/0005.png',
          id: 69,
          parentId: 6,
          actionType: 'studio',
        },
        70: {
          url: '../../static/img/models/16_5/0006.png',
          id: 70,
          parentId: 6,
          actionType: 'studio',
        },
        71: {
          url: '../../static/img/models/16_5/0007.png',
          id: 71,
          parentId: 6,
          actionType: 'studio',
        },
        72: {
          url: '../../static/img/models/16_5/0008.png',
          id: 72,
          parentId: 6,
          actionType: 'studio',
        },
        73: {
          url: '../../static/img/models/16_5/0009.png',
          id: 73,
          parentId: 6,
          actionType: 'studio',
        },
        74: {
          url: '../../static/img/models/16_5/0010.png',
          id: 74,
          parentId: 6,
          actionType: 'studio',
        },
        75: {
          url: '../../static/img/models/16_5/0011.png',
          id: 75,
          parentId: 6,
          actionType: 'studio',
        },
        76: {
          url: '../../static/img/models/16_5/0012.png',
          id: 76,
          parentId: 6,
          actionType: 'studio',
        },
        77: {
          url: '../../static/img/models/16_5/0013.png',
          id: 77,
          parentId: 6,
          actionType: 'studio',
        },
        78: {
          url: '../../static/img/models/16_5/0014.png',
          id: 78,
          parentId: 6,
          actionType: 'studio',
        },
        79: {
          url: '../../static/img/models/16_5/0015.png',
          id: 79,
          parentId: 6,
          actionType: 'studio',
        },
        80: {
          url: '../../static/img/models/16_5/0016.png',
          id: 80,
          parentId: 6,
          actionType: 'studio',
        },
      },
    },
    breadcrumbs: [],
  },
];

export const getNavbarData = (userPermissions: {
  [key: string]: boolean;
}): Array<StudioSidebarType> => {
  const sidebarData: Array<StudioSidebarType> = [
    {
      id: 0,
      title: 'projects',
      icon: (fill: string) => <StudioProjectsIcon fill={fill} />,
      containerStyle: {
        marginBottom: '20px',
      },
    },
  ];

  if (userPermissions.hasStudioRegen) {
    sidebarData.push({
      id: 1,
      title: 're-gen',
      icon: (fill) => <ReGenIcon fill={fill} />,
    });
  }

  if (userPermissions.hasStudioFormatExtender) {
    sidebarData.push({
      id: 2,
      title: 'format extender',
      icon: (fill) => <StudioFormatExtenderIcon fill={fill} />,
    });
  }

  if (userPermissions.hasStudioBrandRoom) {
    sidebarData.push({
      id: 3,
      title: 'brand room',
      icon: (fill) => <StudioBrandRoomIcon fill={fill} />,
    });
  }

  if (userPermissions.hasStudioGenerativeBrief) {
    sidebarData.push({
      id: 4,
      title: 'generative brief',
      icon: (fill) => <StudioGenerativeBriefIcon fill={fill} />,
    });
  }

  if (userPermissions.hasStudioLayers) {
    sidebarData.push({
      id: 5,
      title: 'layers',
      icon: (fill) => <GenreAssetsIcon fill={fill} />,
    });
  }

  if (userPermissions.hasStudioPromptToDesign) {
    sidebarData.push({
      id: 6,
      title: 'p2d',
      icon: (fill) => <GenreAssetsIcon fill={fill} />,
    });
  }

  return sidebarData;
};

export const topbarData: Array<ITopbar> = [
  {
    id: 1,
    type: 'avatar',
  },
  {
    id: 2,
    type: 'body',
  },
  {
    id: 3,
    type: 'pose',
  },
  {
    id: 4,
    type: 'lighting',
  },
];
