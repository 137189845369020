import React from 'react';
import cn from 'classnames';

import MenuButton from './MenuButton';
import { ISidebarMenu } from '../../types/studio.types';

import '../../styles/sidebar/menu.scss';

export default ({
  data = [],
  selectedId = null,
  handleButtonClick = () => {},
}: ISidebarMenu) => (
  <div
    className={cn('Dock__block', {
      Dock__hasSelected: !Number.isInteger(selectedId),
    })}
  >
    <div className="Dock__group">
      {data.map((id) => (
        <MenuButton
          id={id}
          selected={selectedId === id}
          key={`dock-menu-btn-${id}`}
          handleClick={() => handleButtonClick(id)}
        />
      ))}
    </div>
  </div>
);
