import React, { useMemo } from 'react';
import cn from 'classnames';

import { useAppSelector, useAppDispatch } from '../../../helpers/hooks';
import { actions as sidebarContentActions } from '../../../ducks/sidebarContent';
import { actions as studioActions } from '../../../ducks/scene';
import { actions as sandboxActions } from '../../../ducks/3dScene';
import { SidebarGridContentAssetType } from '../../../ducks/sidebarContent/types';
import { IGridItem } from '../../../types/studio.types';

import '../../../styles/sidebar/gridContent/grid-item.scss';

export default ({
  id,
  entityId,
  isGroup,
  style = {},
  backgroundCover,
}: IGridItem) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state) =>
      state.sidebarContent.entities[entityId]?.data[
        isGroup ? 'groups' : 'assets'
      ][id as number],
  );

  const backgroundImage = useMemo(
    () =>
      data
        ? isGroup
          ? '../../static/img/empty.jpeg'
          : (data as SidebarGridContentAssetType).url
        : null,
    [data, isGroup],
  );

  const btnStyle = {
    '--internal-backgroundImage': `url(${backgroundImage})`,
  } as React.CSSProperties;

  const onClick = useMemo(() => {
    if (isGroup)
      return () => {
        dispatch(
          sidebarContentActions.setSelectedGroupId({
            entityId,
            groupId: data?.parentId || null,
          }),
        );

        setTimeout(
          () =>
            dispatch(
              sidebarContentActions.setSelectedGroupId({
                entityId,
                groupId: data?.id || null,
              }),
            ),
          0,
        );
      };

    // {
    //   src: 'https://modelviewer.dev/shared-assets/models/NeilArmstrong.glb',
    //   alt: "Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum",
    //   envImg:
    //     'https://modelviewer.dev/shared-assets/environments/moon_1k.hdr',
    //   poster:
    //     'https://modelviewer.dev/shared-assets/models/NeilArmstrong.webp',
    // }

    switch ((data as SidebarGridContentAssetType).actionType) {
      case 'studio':
        return () => dispatch(studioActions.setAssetUrl(backgroundImage));
      case 'sandbox': {
        const {
          additionalData = {
            src: null,
            alt: null,
            envImg: null,
            poster: null,
          },
        } = data as SidebarGridContentAssetType;

        return () => dispatch(sandboxActions.set3dData(additionalData));
      }
      default:
        return () => console.log(data);
    }
  }, [data, entityId, isGroup, dispatch, backgroundImage]);

  return (
    <div className="AssetResultContainer__block" style={style}>
      <button
        style={btnStyle}
        className={cn('Card__block', {
          Card__backgroundImageContain: !backgroundCover,
          Card__backgroundImageCover: backgroundCover,
        })}
        type="button"
        onClick={onClick}
        disabled={!data}
        aria-label="button"
      />
    </div>
  );
};
