import React from 'react';

const StudioBrandRoomIcon = ({ fill = 'white' }) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71054 9.49993H11.9737V5.19225H5.71054V9.49993ZM1.33199 11.5263C0.959789 11.5263 0.644738 11.3973 0.386843 11.1394C0.128948 10.8815 0 10.5665 0 10.1943V1.80563C0 1.43342 0.128948 1.11837 0.386843 0.860476C0.644738 0.602581 0.959789 0.473633 1.33199 0.473633H12.668C13.0402 0.473633 13.3553 0.602581 13.6132 0.860476C13.8711 1.11837 14 1.43342 14 1.80563V10.1943C14 10.5665 13.8711 10.8815 13.6132 11.1394C13.3553 11.3973 13.0402 11.5263 12.668 11.5263H1.33199ZM1.33199 10.421H12.668C12.7247 10.421 12.7767 10.3974 12.8239 10.3502C12.8711 10.3029 12.8948 10.2509 12.8948 10.1943V1.80563C12.8948 1.74894 12.8711 1.69697 12.8239 1.64973C12.7767 1.6025 12.7247 1.57888 12.668 1.57888H1.33199C1.27531 1.57888 1.22334 1.6025 1.1761 1.64973C1.12886 1.69697 1.10525 1.74894 1.10525 1.80563V10.1943C1.10525 10.2509 1.12886 10.3029 1.1761 10.3502C1.22334 10.3974 1.27531 10.421 1.33199 10.421Z"
        fill={fill}
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default StudioBrandRoomIcon;
