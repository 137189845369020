import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { STUDIO } from '../../helpers/constants';

import { State } from './types';

const name = STUDIO;
const initialState: State = {
  assetUrl: null,
};

const setAssetUrl: CaseReducer<State, PayloadAction<string | null>> = (
  state,
  { payload },
) => {
  state.assetUrl = payload;
};

const reducers = {
  setAssetUrl,
};

export default createSlice({ name, initialState, reducers });
