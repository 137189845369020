import JSZip from 'jszip';

const zip = JSZip();

const download = () => {
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `${Date.now()}.zip`;
    link.click();
    window.URL.revokeObjectURL(url);
  });
};

const generateZip = (arr) => {
  if (arr.length) {
    arr.forEach((element) => {
      zip.file(element.name, element, {
        binary: true,
      });
    });

    download();
  }
};

export default generateZip;
