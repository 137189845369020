import React from 'react';

const StudioFormatExtenderIcon = ({ fill = 'white' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14V12.7647H1.64706V1.4887C1.64706 1.0727 1.79118 0.72059 2.07942 0.432354C2.36765 0.144118 2.71977 0 3.13576 0H10.8642C11.2802 0 11.6323 0.144118 11.9206 0.432354C12.2088 0.72059 12.3529 1.0727 12.3529 1.4887V12.7647H14V14H0ZM2.88234 12.7647H11.1177V1.4887C11.1177 1.42534 11.0913 1.36726 11.0385 1.31446C10.9857 1.26167 10.9276 1.23528 10.8642 1.23528H3.13576C3.0724 1.23528 3.01433 1.26167 2.96152 1.31446C2.90874 1.36726 2.88234 1.42534 2.88234 1.4887V12.7647ZM5.2579 7.7285C5.45956 7.7285 5.63139 7.65749 5.77339 7.51549C5.91541 7.37349 5.98642 7.20165 5.98642 7C5.98642 6.79834 5.91541 6.62651 5.77339 6.48451C5.63139 6.3425 5.45956 6.2715 5.2579 6.2715C5.05624 6.2715 4.88442 6.3425 4.74241 6.48451C4.60041 6.62651 4.5294 6.79834 4.5294 7C4.5294 7.20165 4.60041 7.37349 4.74241 7.51549C4.88442 7.65749 5.05624 7.7285 5.2579 7.7285Z"
        fill={fill}
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default StudioFormatExtenderIcon;
