import React, { useState, useRef } from 'react';

// hooks
import useClickOutside from '../../hooks/useClickOutside';
import { useAppSelector } from '../../helpers/hooks';

// types
import { IStudioHeader } from '../../types/studio.types';

// styles
import '../../styles/studio/studio-header.scss';

const StudioHeader = ({ userCreditsAmount }: IStudioHeader) => {
  const [isProfileDropdownOpened, setIsProfileDropdownOpened] = useState(false);
  const dropdownRef = useRef(null);

  const openProfileDropdown = () => {
    setIsProfileDropdownOpened(true);
  };

  const closeProfileDropdown = () => {
    setIsProfileDropdownOpened(false);
  };

  const userProfile = useAppSelector((state) => state.userProfile);

  useClickOutside(dropdownRef, closeProfileDropdown);

  return (
    <header className="studio-header-container">
      <a href="/" className="studio-header-container--logo">
        <img
          src="/static/img/white-text-logo.svg"
          alt="studio logo"
          height={24}
          width={101}
        />
      </a>
      <p className="studio-header-container--title">/studio</p>
      <div className="studio-header-container--right-section">
        <p className="studio-header-container--credits">
          credits: {userCreditsAmount}
        </p>
        <div className="studio-header-container--profile-container">
          <img
            src="/static/img/icons/account-white.svg"
            alt="profile"
            onClick={openProfileDropdown}
            className="studio-header-container--profile-container--icon"
          />
          {isProfileDropdownOpened && (
            <div
              className="studio-header-container--profile-container--dropdown"
              ref={dropdownRef}
            >
              <div className="studio-header-container--profile-container--dropdown--info">
                <img
                  src={
                    userProfile.profilePicture || '/static/img/daft-punk.svg'
                  }
                  alt="profile img"
                  className="studio-header-container--profile-container--dropdown--info--image"
                />
                <div className="studio-header-container--profile-container--dropdown--info--data-container">
                  <p className="studio-header-container--profile-container--dropdown--info--data-container--name">
                    {`${userProfile.firstName} ${userProfile.lastName}`}
                  </p>
                  <p className="studio-header-container--profile-container--dropdown--info--data-container--email">
                    {userProfile.email}
                  </p>
                  <p className="studio-header-container--profile-container--dropdown--info--data-container--credits">
                    CREDITS {userCreditsAmount}
                  </p>
                </div>
              </div>
              <div className="studio-header-container--profile-container--dropdown--links">
                <a
                  href="/projects"
                  className="studio-header-container--profile-container--dropdown--links--element"
                >
                  <img
                    src="/static/img/icons/folder-white.svg"
                    alt="Folder icon"
                  />
                  <span className="studio-header-container--profile-container--dropdown--links--element--text">
                    Go to Projects
                  </span>
                </a>
                <a
                  href="/account-settings/"
                  className="studio-header-container--profile-container--dropdown--links--element"
                >
                  <img
                    src="/static/img/icons/settings-white.svg"
                    alt="Settings icon"
                  />
                  <span className="studio-header-container--profile-container--dropdown--links--element--text">
                    Go to Account Settings
                  </span>
                </a>
                <a
                  href="/help/faq"
                  className="studio-header-container--profile-container--dropdown--links--element"
                >
                  <img
                    src="/static/img/icons/studio-support.svg"
                    alt="Support icon"
                  />
                  <span className="studio-header-container--profile-container--dropdown--links--element--text">
                    Go to Support
                  </span>
                </a>
                <a
                  href="/log-out"
                  className="studio-header-container--profile-container--dropdown--links--element"
                >
                  <img src="/static/img/icons/exit-white.svg" alt="Exit icon" />
                  <span className="studio-header-container--profile-container--dropdown--links--element--text">
                    Sign Out
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default StudioHeader;
