import React, { useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';

import GridItem from './GridItem';
import LoadingState from './LoadingState';
import { useAppSelector } from '../../../helpers/hooks';
import { IGrid } from '../../../types/studio.types';

import '../../../styles/sidebar/gridContent/grid.scss';

export default ({
  entityId,
  gridParams = { elementsInRow: 2, backgroundCover: true },
}: IGrid) => {
  const { elementsInRow, backgroundCover } = gridParams;

  const style = {
    '--editor_elements-in-row': elementsInRow,
  } as React.CSSProperties;

  const selectedGroupId = useAppSelector(
    (state) => state.sidebarContent.entities[entityId]?.selectedGroupId || null,
  );

  const { assets = [], loading } =
    useAppSelector(
      (state) =>
        state.sidebarContent.entities[entityId]?.data.groups[
          selectedGroupId as number
        ],
    ) || {};

  const itemsArr = useMemo(
    () =>
      loading && !assets.length
        ? Array(elementsInRow * 2)
            .fill(null)
            .map(() => uuidv4())
        : assets,
    [assets, loading, elementsInRow],
  );

  return (
    <div className="AssetLibraryGrid__block">
      <div style={style} className="AssetLibraryGrid__inner">
        {selectedGroupId
          ? itemsArr.map((id) => (
              <GridItem
                key={`grid-item_${id}`}
                entityId={entityId}
                id={id}
                backgroundCover={backgroundCover}
              />
            ))
          : null}
        {!assets.length && (
          <>
            <div className="AssetLibraryGridSkeleton__gradient" />
            <LoadingState loading positionAbsolute />
          </>
        )}
      </div>
      {assets.length && loading ? <LoadingState loading /> : null}
    </div>
  );
};
