import React from 'react';

// components
import ReGenWelcome from './ReGenWelcome';
import ReGenFirstStep from './ReGenFirstStep';
import ReGenSecondStep from './ReGenSecondStep';
import ReGenPreloader from './ReGenPreloader';
import ReGenThirdStep from './ReGenThirdStep';
import ReGenFifthStep from './ReGenFifthStep';
import ReGenDesignAssistance from './ReGenDesignAssistance';

// types
import { IReGenContent } from '../../../types/studio-re-gen';

const ReGenContent = ({
  step,
  onReGenStart,
  onMoveToSecondStep,
  onMoveToPreloader,
  onMoveToThirdStep,
  onMoveToFourthStep,
  onMoveToFifthStep,
  handleSelectTemplate,
  selectedTemplate,
  reGen,
  onReGenCreate,
  onReGenAgain,
  selectedGeneratedProject,
}: IReGenContent) => {
  switch (step) {
    case 0.5: {
      return <ReGenWelcome onReGenStart={onReGenStart} />;
    }

    case 1: {
      return (
        <ReGenFirstStep
          onMoveToSecondStep={onMoveToSecondStep}
          handleSelectTemplate={handleSelectTemplate}
          selectedTemplate={selectedTemplate}
        />
      );
    }

    case 2: {
      return (
        <ReGenSecondStep
          onMoveToPreloader={onMoveToPreloader}
          onMoveToThirdStep={onMoveToThirdStep}
          selectedTemplate={selectedTemplate}
          onReGenCreate={onReGenCreate}
          onReGenStart={onReGenStart}
          handleSelectTemplate={handleSelectTemplate}
        />
      );
    }

    case 2.5: {
      return <ReGenPreloader />;
    }

    case 3: {
      return (
        <ReGenThirdStep
          onMoveToFifthStep={onMoveToFifthStep}
          onMoveToFourthStep={onMoveToFourthStep}
          reGen={reGen}
        />
      );
    }

    case 4: {
      return (
        <ReGenDesignAssistance
          selectedGeneratedProject={selectedGeneratedProject}
        />
      );
    }

    case 5: {
      return <ReGenFifthStep onReGenAgain={onReGenAgain} reGen={reGen} />;
    }

    default: {
      return null;
    }
  }
};

export default ReGenContent;
