import React from 'react';

// styles
import '../../../styles/studio/genre-assets.scss';

const GenreAssetsTopbar = () => {
  return (
    <div className="genre-assets-topbar">
      <p className="genre-assets-topbar--title">layers</p>
    </div>
  );
};

export default GenreAssetsTopbar;
