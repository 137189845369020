import React from 'react';

// components
import FormatExtenderContentStep1 from './FormatExtenderContentStep1';
import FormatExtenderContentStep2 from './FormatExtenderContentStep2';
import FormatExtenderContentStep3 from './FormatExtenderContentStep3';

// types
import { IFormatExtenderContent } from '../../../types/studio-format-extender.types';
import FormatsExtenderPreloader from './FormatsExtenderPreloader';

const FormatExtenderContent = ({
  step,
  handleSelectProject,
  selectedProject,
  onProceedFirstStep,
  onProceedSecondStep,
  onSaveGeneratedPageFormats,
  generatedPageFormatsInfo,
  onGeneratedProjectsCancel,
  triggerPreloader,
  onGeneratedProjectsSave,
  setFirstFormatExtenderStep,
}: IFormatExtenderContent) => {
  switch (step) {
    case 1: {
      return (
        <FormatExtenderContentStep1
          selectedProject={selectedProject}
          handleSelectProject={handleSelectProject}
          onProceedFirstStep={onProceedFirstStep}
        />
      );
    }

    case 2: {
      return (
        <FormatExtenderContentStep2
          onProceedSecondStep={onProceedSecondStep}
          selectedProject={selectedProject}
          onSaveGeneratedPageFormats={onSaveGeneratedPageFormats}
          triggerPreloader={triggerPreloader}
          setFirstFormatExtenderStep={setFirstFormatExtenderStep}
        />
      );
    }

    case 3: {
      return (
        <FormatExtenderContentStep3
          generatedPageFormatsInfo={generatedPageFormatsInfo}
          onGeneratedProjectsCancel={onGeneratedProjectsCancel}
          onGeneratedProjectsSave={onGeneratedProjectsSave}
        />
      );
    }

    case 2.5: {
      return <FormatsExtenderPreloader />;
    }

    default: {
      return null;
    }
  }
};

export default FormatExtenderContent;
