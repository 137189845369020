import React, { useState, useEffect } from 'react';

// components
import ReGenTopbar from './ReGenTopbar';
import ReGenContent from './ReGenContent';

// api
import { finishReGen, getReGen } from '../../../api/helpers';

// types
import { TemplateType } from '../../../types/studio.types';
import { GeneratedProjectType, ReGenType } from '../../../types/studio-re-gen';

const ReGen = () => {
  const [step, setStep] = useState<number | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<null | TemplateType>(
    null,
  );
  const [reGen, setReGen] = useState<ReGenType | null>(null);
  const [selectedGeneratedProject, setSelectedGeneratedProject] =
    useState<GeneratedProjectType | null>(null);

  const onReGenStart = () => {
    setStep(1);
  };

  const onMoveToSecondStep = () => {
    setStep(2);
  };

  const onMoveToPreloader = () => {
    setStep(2.5);
  };

  const onMoveToThirdStep = () => {
    setStep(3);
  };

  const onMoveToFourthStep = (selectedProject: GeneratedProjectType) => {
    setSelectedGeneratedProject(selectedProject);
    setStep(4);
  };

  const onMoveToFifthStep = () => {
    finishReGen().then(() => {
      setStep(5);
    });
  };

  const handleSelectTemplate = (template: TemplateType | null) => {
    setSelectedTemplate((prevState) =>
      template?.id !== prevState?.id ? template : null,
    );
  };

  const onReGenCreate = (innerReGen: ReGenType) => {
    setReGen(innerReGen);
  };

  const onReGenAgain = () => {
    setSelectedTemplate(null);
    setStep(1);
  };

  useEffect(() => {
    getReGen().then((resp) => {
      if (resp.id) {
        setReGen(resp);
        onMoveToThirdStep();
      } else {
        setStep(0.5);
      }
    });
  }, []);

  return (
    <div>
      <ReGenTopbar step={step} />
      <ReGenContent
        step={step}
        onReGenStart={onReGenStart}
        onMoveToSecondStep={onMoveToSecondStep}
        onMoveToPreloader={onMoveToPreloader}
        onMoveToThirdStep={onMoveToThirdStep}
        onMoveToFourthStep={onMoveToFourthStep}
        onMoveToFifthStep={onMoveToFifthStep}
        handleSelectTemplate={handleSelectTemplate}
        selectedTemplate={selectedTemplate}
        reGen={reGen}
        onReGenCreate={onReGenCreate}
        onReGenAgain={onReGenAgain}
        selectedGeneratedProject={selectedGeneratedProject}
      />
    </div>
  );
};

export default ReGen;
