import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  CaseReducer,
} from '@reduxjs/toolkit';

import { gridContentVar } from '../../types/common.types';
import { SIDEBAR_CONTENT } from '../../helpers/constants';
import { SidebarGridContentType } from './types';

const sidebarContentAdapter = createEntityAdapter<SidebarGridContentType>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type State = any; // TODO use a proper type here

const name = SIDEBAR_CONTENT;
const initialState = sidebarContentAdapter.getInitialState();

const setSidebarContent = sidebarContentAdapter.upsertMany;
const setSelectedGroupId: CaseReducer<
  State,
  PayloadAction<{ entityId: number; groupId: number | null }>
> = (state, { payload: { entityId, groupId } }) => {
  const entity = state.entities[entityId];

  if (entity.type === gridContentVar) {
    entity.selectedGroupId = groupId;
  }
};

const updateBreadcrumbs: CaseReducer<
  State,
  PayloadAction<{ entityId: number; groupId: number | null }>
> = (state, { payload: { entityId, groupId } }) => {
  if (groupId === null) return state;

  const entity = state.entities[entityId];

  const breadcrumbIndex = entity.breadcrumbs.findIndex(
    (id: number) => id === groupId,
  );

  entity.breadcrumbs =
    breadcrumbIndex >= 0
      ? entity.breadcrumbs.slice(0, breadcrumbIndex + 1)
      : [...entity.breadcrumbs, groupId];
};

const reducers = {
  setSidebarContent,
  setSelectedGroupId,
  updateBreadcrumbs,
};

export default createSlice({ name, initialState, reducers });
