import React from 'react';
import cn from 'classnames';

import { useAppSelector } from '../../helpers/hooks';
import { ISidebarMenuButton } from '../../types/studio.types';

import '../../styles/sidebar/menu-button.scss';

export default ({
  id,
  selected = false,
  handleClick = () => {},
}: ISidebarMenuButton) => {
  const {
    title = 'Default',
    icon,
    containerStyle = {},
  } = useAppSelector((state) => state.navbar.entities[id]) || {};

  return (
    <button
      type="button"
      className={cn('Dock__button', {
        selected,
      })}
      style={containerStyle}
      onClick={handleClick}
    >
      <span>
        <div className="Dock__buttonLabel">
          <img src={icon} alt={title} />
          <span>{title}</span>
        </div>
      </span>
    </button>
  );
};
