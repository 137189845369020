import React, { useState } from 'react';
import cn from 'classnames';

// components
import DesignAssistanceOptionContent from './DesignAssistanceOptionContent';

// types
import { IDesignAssistanceOption } from '../../../types/studio-re-gen';

// styles
import '../../../styles/studio/re-gen.scss';

const designAssistanceOptions = [
  { id: 1, title: 'create more formats' },
  { id: 2, title: 'create new variants' },
  { id: 3, title: 'something custom' },
];

const DesignAssistanceOption = ({
  assistanceOption,
  selectedOption,
  handleSelectAssistanceOption,
}: IDesignAssistanceOption) => {
  return (
    <div
      className={cn(
        'design-assistance-container--content--options-container--option',
        {
          active: assistanceOption.id === selectedOption,
        },
      )}
      onClick={() => handleSelectAssistanceOption(assistanceOption.id)}
    >
      {assistanceOption.title}
    </div>
  );
};

const ReGenDesignAssistanceContent = () => {
  const [selectedOption, setSelectedOption] = useState(1);

  const handleSelectAssistanceOption = (id: number) => {
    setSelectedOption(id);
  };

  return (
    <div className="design-assistance-container--content">
      <p className="design-assistance-container--content--title">
        Choose one of the following:
      </p>
      <div className="design-assistance-container--content--options-container">
        {designAssistanceOptions.map((assistanceOption) => (
          <DesignAssistanceOption
            key={assistanceOption.id}
            assistanceOption={assistanceOption}
            selectedOption={selectedOption}
            handleSelectAssistanceOption={handleSelectAssistanceOption}
          />
        ))}
      </div>
      <div>
        <DesignAssistanceOptionContent assistanceOption={selectedOption} />
      </div>
    </div>
  );
};

export default ReGenDesignAssistanceContent;
