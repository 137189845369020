import fetch from '../helpers/customFetch';

const getDesignerHelpCategories = () => {
  try {
    return fetch({
      url: `/modals/request-designer-help/categories/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const createFeedback = (data) => {
  try {
    return fetch({
      url: '/modals/feedback/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const getFeedbackCategories = () => {
  try {
    return fetch({
      url: `/modals/feedback/categories/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const requestDesignerHelp = (data) => {
  try {
    return fetch({
      url: '/modals/request-designer-help/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export {
  getDesignerHelpCategories,
  requestDesignerHelp,
  getFeedbackCategories,
  createFeedback,
};
