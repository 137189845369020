import React, { useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { usePrevious, useAppSelector } from '../../helpers/hooks';
import CloseButton from './CloseButton';
import {
  AssetType,
  ISidebarContent,
  IContentWrapper,
} from '../../types/studio.types';
import GridContent from './GridContent';
import { gridContentVar } from '../../types/common.types';

import '../../styles/sidebar/content.scss';

const isIndexValue = (val: EntityId | null | undefined) =>
  Number.isInteger(val);

const ContentWrapper = ({
  id,
  groupParams = { elementsInRow: 2, backgroundCover: false },
  gridParams = { elementsInRow: 2, backgroundCover: true },
}: IContentWrapper) => {
  const contentType = useAppSelector((state) =>
    id ? state.sidebarContent.entities[id]?.type : null,
  );

  switch (contentType) {
    case gridContentVar:
      return (
        <GridContent
          id={id as number}
          groupParams={groupParams}
          gridParams={gridParams}
        />
      );
    default:
      return null;
  }
};

export default ({
  selectedId = null,
  handleCloseButtonClick = () => {},
  groupParams = { elementsInRow: 2, backgroundCover: false },
  gridParams = { elementsInRow: 2, backgroundCover: true },
}: ISidebarContent) => {
  const prevSelectedId = usePrevious(selectedId);
  const [motionCss, setMotionCss] = useState({});
  const [asset, setAsset] = useState<AssetType | null>(null);
  const { entities } = useAppSelector((state) => state.navbar);

  useEffect(() => {
    // selected menu item is changing (sidebar content is opened)
    if (isIndexValue(prevSelectedId) && isIndexValue(selectedId)) {
      const moveDown = (prevSelectedId ?? 0) < (selectedId ?? 0);
      const anim1 = {
        opacity: 1,
        transform: `translateX(0%) translateY(${
          moveDown ? '-' : ''
        }25%) translateZ(0px)`,
        transition: 'transform .3s ease, opacity .2s ease',
      };
      const anim2 = {
        opacity: 0,
        transform: `translateX(0%) translateY(${
          moveDown ? '' : '-'
        }25%) translateZ(0px)`,
      };
      const anim3 = {
        opacity: 1,
        transform: 'translateX(0%) translateY(0) translateZ(0px)',
        transition: 'transform .3s ease, opacity .2s ease',
      };

      setMotionCss(anim1);
      setTimeout(() => {
        const innerAsset =
          (selectedId ? entities[selectedId]?.asset : null) || null;

        setMotionCss(anim2);
        setAsset(innerAsset);

        setTimeout(() => setMotionCss(anim3), 100);
      }, 0);
    }
    // open sidebar content
    else if (isIndexValue(selectedId)) {
      const innerAsset =
        (selectedId ? entities[selectedId]?.asset : null) || null;

      setAsset(innerAsset);
    }
    // close sidebar content
    else {
      setTimeout(() => {
        if (isIndexValue(prevSelectedId)) return; // fast menu taps bugfix
        setAsset(null);
      }, 300);
    }
  }, [selectedId, entities]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="PanelPortal__block">
      <div
        className="Panel__block"
        style={{
          transform: `translateX(${
            isIndexValue(selectedId) ? '0' : '-100'
          }%) translateZ(0px)`,
        }}
      >
        <section className="InspectorHeader__block">
          <h2 className="InspectorHeader__heading">
            {asset?.title || 'Library'}
          </h2>
          <CloseButton handleClick={handleCloseButtonClick} />
        </section>
        <div className="AssetLibraryPanel__block">
          <div className="AssetLibrarySearch__block" />
          <div className="AssetLibraryPanel__content">
            <div
              className="AssetLibraryContent__motionWrapper"
              style={motionCss}
            >
              <ContentWrapper
                id={asset?.contentId || null}
                groupParams={groupParams}
                gridParams={gridParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
