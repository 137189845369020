import { NavbarButtonType } from '../../ducks/navbar/types';
import { SidebarGridContentType } from '../../ducks/sidebarContent/types';

export const sidebarContentData: SidebarGridContentType[] = [
  {
    id: 1,
    type: 'gridContent',
    rootGroupId: 1,
    selectedGroupId: null,
    data: {
      groups: {
        1: {
          title: 'Models',
          assets: [1, 2, 3, 4, 5],
          id: 1,
          parentId: null,
          loading: false,
        },
      },
      assets: {
        1: {
          url: 'https://modelviewer.dev/shared-assets/models/NeilArmstrong.webp',
          id: 1,
          parentId: 2,
          actionType: 'sandbox',
          additionalData: {
            src: 'https://modelviewer.dev/shared-assets/models/NeilArmstrong.glb',
            alt: "Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum",
            envImg:
              'https://modelviewer.dev/shared-assets/environments/moon_1k.hdr',
            poster:
              'https://modelviewer.dev/shared-assets/models/NeilArmstrong.webp',
          },
        },
        2: {
          url: 'https://modelviewer.dev/assets/ShopifyModels/GeoPlanter.webp',
          id: 2,
          parentId: 2,
          actionType: 'sandbox',
          additionalData: {
            src: 'https://modelviewer.dev/assets/ShopifyModels/GeoPlanter.glb',
            alt: '',
            envImg: '',
            poster:
              'https://modelviewer.dev/assets/ShopifyModels/GeoPlanter.webp',
          },
        },
        3: {
          url: 'https://modelviewer.dev/assets/ShopifyModels/Canoe.webp',
          id: 3,
          parentId: 2,
          actionType: 'sandbox',
          additionalData: {
            src: 'https://modelviewer.dev/assets/ShopifyModels/Canoe.glb',
            alt: '',
            envImg: '',
            poster: 'https://modelviewer.dev/assets/ShopifyModels/Canoe.webp',
          },
        },
        4: {
          url: '/static/3dModels/RPM1_poster.webp',
          id: 4,
          parentId: 2,
          actionType: 'sandbox',
          additionalData: {
            src: '/static/3dModels/RPM1.glb',
            alt: 'RPM TEST MODEL',
            envImg:
              'https://modelviewer.dev/shared-assets/environments/moon_1k.hdr',
            poster: '/static/3dModels/RPM1_poster.webp',
          },
        },
        5: {
          url: '/static/3dModels/RPM2_poster.webp',
          id: 5,
          parentId: 2,
          actionType: 'sandbox',
          additionalData: {
            src: '/static/3dModels/RPM2.glb',
            alt: 'RPM TEST MODEL',
            envImg:
              'https://modelviewer.dev/shared-assets/environments/moon_1k.hdr',
            poster: '/static/3dModels/RPM2_poster.webp',
          },
        },
      },
    },
    breadcrumbs: [],
  },
];

export const navbarData: Array<NavbarButtonType> = [
  // {
  //   id: 1,
  //   title: 'Templates',
  //   icon: '/static/img/icons/editor/templates.svg',
  //   containerStyle: {
  //     marginBottom: '20px',
  //   },
  //   asset: {
  //     title: 'Templates',
  //     contentId: null,
  //   },
  // },
  {
    id: 2,
    title: 'Model',
    icon: '/static/img/icons/editor/sandbox-model.svg',
    asset: {
      title: 'Pick a model to start',
      contentId: 1,
    },
  },
  // {
  //   id: 3,
  //   title: 'Texture',
  //   icon: '/static/img/icons/editor/sandbox-texture.svg',
  //   asset: {
  //     title: 'Textures',
  //     contentId: null,
  //   },
  // },
  // {
  //   id: 4,
  //   title: 'Properties',
  //   icon: '/static/img/icons/editor/sandbox-properties.svg',
  //   asset: {
  //     title: 'Materials',
  //     contentId: null,
  //   },
  // },
  // {
  //   id: 5,
  //   title: 'Scene',
  //   icon: '/static/img/icons/editor/sandbox-scene.svg',
  //   asset: {
  //     title: 'Light your scene',
  //     contentId: null,
  //   },
  // },
  // {
  //   id: 6,
  //   title: 'Canvas',
  //   icon: '/static/img/icons/editor/canvas.svg',
  //   asset: {
  //     title: 'Apply to a Design',
  //     contentId: null,
  //   },
  // },
];
