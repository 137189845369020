import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import cn from 'classnames';

// components
import ProjectsHeader from './ProjectsHeader';

// helpers
import { getToasterOptions } from '../../helpers/toaster';

// api
import {
  getAllUploadedImages,
  createUploadedImage,
  deleteUploadedImageById,
  updateUploadedImage,
} from '../../api/userUploadedImages';

// types
import { IProjectsTabContent, UploadedImage } from '../../types/studio.types';

const ProjectsBrandRoomAssets = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[] | null>(
    null,
  );
  const [selectedImage, setSelectedImage] = useState<UploadedImage | null>(
    null,
  );
  const [isSelectedImageModalOpen, setIsSelectedImageModalOpen] =
    useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isReplaceAsset, setIsReplaceAsset] = useState(false);

  const openSelectedImageModalOpen = () => {
    setIsSelectedImageModalOpen(true);
  };

  const closeSelectedImageModalOpen = () => {
    setIsSelectedImageModalOpen(false);
  };

  const openDeleteImageModalOpen = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteImageModalOpen = () => {
    setIsDeleteModalVisible(false);
  };

  const openUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalVisible(false);
  };

  const onSelectImage = (image: UploadedImage | null) => {
    setSelectedImage(image);
    openSelectedImageModalOpen();
  };

  const onOpenReplaceModal = () => {
    closeSelectedImageModalOpen();
    setIsReplaceAsset(true);
    openUploadModal();
  };

  const onDeleteImage = (imageId: number) => {
    toast.info('Deleting image', getToasterOptions());

    deleteUploadedImageById(imageId).then(() => {
      toast.success('Image deleted', getToasterOptions());
      closeDeleteImageModalOpen();
      closeSelectedImageModalOpen();
      setUploadedImages((prevState) =>
        (prevState as UploadedImage[]).filter(
          (innerImage) => innerImage.id !== imageId,
        ),
      );
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUploadImage = (file: any) => {
    const formData = new FormData();

    formData.append('resource', file[0]);

    const uploadedImageCb = isReplaceAsset
      ? updateUploadedImage(selectedImage?.id, formData)
      : createUploadedImage(formData);

    toast.info('Uploading logo');

    uploadedImageCb
      .then((resp) => {
        setUploadedImages((prevState) =>
          prevState ? [resp, ...prevState] : [resp],
        );
        toast.success('Data saved', getToasterOptions());
        closeUploadModal();
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      })
      .finally(() => {
        setIsReplaceAsset(false);
      });
  };

  const omImageDownload = async () => {
    try {
      const response = await fetch((selectedImage as UploadedImage).preview);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute(
        'download',
        (selectedImage as UploadedImage).name || 'download.webp',
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Download failed:', error);
    }
  };

  useEffect(() => {
    getAllUploadedImages('', 1, 100).then((resp) => {
      setUploadedImages(resp.results);
    });
  }, []);

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Brand Room"
        isNewDesignBtnVisible={false}
      />
      <Modal
        isOpen={isUploadModalVisible}
        ariaHideApp={false}
        onRequestClose={closeUploadModal}
        className={`Modal__Content brand-room-modal !max-w-[574px] dark:!bg-[#000] ${
          isDarkMode ? 'dark' : ''
        }`}
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
          {isReplaceAsset ? 'replace asset' : 'upload new asset'}
        </p>

        <Dropzone onDrop={(acceptedFiles) => onUploadImage(acceptedFiles)}>
          {({
            getRootProps: getUploadReplacementRootProps,
            getInputProps: getUploadReplacementInputProps,
          }) => (
            <>
              <div
                className="brand-room-modal--content--image-dropzone mt-4"
                {...getUploadReplacementRootProps()}
              >
                <input {...getUploadReplacementInputProps()} />
                <div className="flex flex-col">
                  <button
                    type="button"
                    className="brand-room-modal--content--image-dropzone--btn"
                  >
                    choose a file or drag it here
                  </button>
                  <p className="brand-room-modal--content--image-dropzone--hint">
                    Accepts .gif, .jpg, and .png
                  </p>
                </div>
              </div>
              <div className="flex justify-end mt-[16px]">
                <button
                  className="brand-room-modal--content--btns-container--outlined-btn"
                  type="button"
                  onClick={closeUploadModal}
                >
                  go back
                </button>
              </div>
            </>
          )}
        </Dropzone>
      </Modal>
      <button
        className="brand-room-assets-library-container--modal-content--btns-container--filled-btn !mx-[20px] !mb-[16px] !normal-case"
        type="button"
        onClick={openUploadModal}
      >
        Upload Asset
      </button>
      <div className="brand-room-assets-library-container">
        {uploadedImages?.map((uploadedImage) => {
          return (
            <div
              className="brand-room-assets-library-container--image-container"
              key={uploadedImage.id}
              onClick={() => onSelectImage(uploadedImage)}
            >
              <img
                src={uploadedImage.preview}
                alt="uploaded"
                className="brand-room-assets-library-container--image-container--image"
              />
            </div>
          );
        })}
        <Modal
          isOpen={isSelectedImageModalOpen}
          ariaHideApp={false}
          onRequestClose={closeSelectedImageModalOpen}
          className={`Modal__Content brand-room-modal !max-w-[380px] dark:!bg-[#000] ${
            isDarkMode ? 'dark' : ''
          }`}
          parentSelector={() => document.querySelector('#root') as HTMLElement}
        >
          <div className="brand-room-assets-library-container--modal-content">
            <img
              src={selectedImage?.preview}
              alt="logo"
              className="brand-room-assets-library-container--modal-content--img"
            />
            <div className="brand-room-assets-library-container--modal-content--btns-container">
              <button
                className="brand-room-assets-library-container--modal-content--btns-container--outlined-btn"
                type="button"
                onClick={openDeleteImageModalOpen}
              >
                delete
              </button>
              <Modal
                isOpen={isDeleteModalVisible}
                ariaHideApp={false}
                onRequestClose={closeDeleteImageModalOpen}
                className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                  isDarkMode ? 'dark' : ''
                }`}
                parentSelector={() =>
                  document.querySelector('#root') as HTMLElement
                }
              >
                <div className="brand-room-modal--content">
                  <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                    confirm deletion
                  </p>

                  <p className="brand-room-modal--content--hint dark:!text-[#eeeeee]">
                    Are you sure you want to delete these assets permanently?
                  </p>

                  <div className="brand-room-modal--content--btns-container">
                    <div className="flex gap-2">
                      <button
                        className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                        type="button"
                        onClick={closeDeleteImageModalOpen}
                      >
                        go back
                      </button>
                      <button
                        className={cn(
                          'brand-room-modal--content--btns-container--submit',
                          {
                            'dark-submit': isDarkMode,
                          },
                        )}
                        type="button"
                        onClick={() =>
                          onDeleteImage(selectedImage?.id as number)
                        }
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>

              <button
                className="brand-room-assets-library-container--modal-content--btns-container--outlined-btn"
                type="button"
                onClick={onOpenReplaceModal}
              >
                replace
              </button>
              <button
                className={cn(
                  'brand-room-assets-library-container--modal-content--btns-container--filled-btn',
                  {
                    'dark-mode': isDarkMode,
                  },
                )}
                type="button"
                onClick={omImageDownload}
              >
                download
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ProjectsBrandRoomAssets;
