import React, { useCallback } from 'react';

import { useAppSelector, useAppDispatch } from '../../../helpers/hooks';
import { actions as sidebarContentActions } from '../../../ducks/sidebarContent';
import { IBreadcrumb } from '../../../types/studio.types';

import '../../../styles/sidebar/gridContent/breadcrumb.scss';

export default ({ isLast, groupId, entityId }: IBreadcrumb) => {
  const dispatch = useAppDispatch();
  const { title = '' } =
    useAppSelector(
      (state) => state.sidebarContent.entities[entityId]?.data.groups[groupId],
    ) || {};

  const onClick = useCallback(
    () =>
      dispatch(sidebarContentActions.setSelectedGroupId({ entityId, groupId })),
    [groupId, entityId, dispatch],
  );

  if (isLast)
    return <div className="AssetLibraryBreadcrumb__label">{title}</div>;

  return (
    <>
      <button
        type="button"
        className="AssetLibraryBreadcrumb__button"
        onClick={onClick}
      >
        <span>
          <span>{title}</span>
        </span>
      </button>
      <div className="AssetLibraryBreadcrumb__divider">/</div>
    </>
  );
};
