import React, { useState } from 'react';

// components
import ProjectsTopbar from '../Projects/ProjectsTopbar';
import ProjectsContent from '../../shared/ProjectsContent';

const StudioProjects = () => {
  const [currentProjectsPage, setCurrentProjectsPage] = useState(1);

  const setNextPage = () => {
    setCurrentProjectsPage((prevState) => prevState + 1);
  };

  return (
    <div>
      <ProjectsTopbar />
      <ProjectsContent
        isToolStudio
        setNextPage={setNextPage}
        currentProjectsPage={currentProjectsPage}
      />
    </div>
  );
};

export default StudioProjects;
