import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { SANDBOX } from '../../helpers/constants';

import { State } from './types';

const name = SANDBOX;
const initialState: State = {
  src: null,
  alt: null,
  envImg: null,
  poster: null,
};

const set3dData: CaseReducer<State, PayloadAction<State>> = (
  state,
  { payload },
) => {
  return Object.assign(state, payload);
};

const reducers = {
  set3dData,
};

export default createSlice({ name, initialState, reducers });
