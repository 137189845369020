import React, { useState } from 'react';
import cn from 'classnames';

// components
import Modal from 'react-modal';

// api
import { insertQRCode } from '../../api/helpers';

// types
import { InputChangeEventType } from '../../types/common.types';
import { QRCodeComponentType } from '../../types/bottom-actions.types';

// styles
import '../../styles/bottom-actions/modal.scss';

const QRCode = ({
  onQRCodeModalClose,
  isQRCodeModalOpen,
  cesdk,
}: QRCodeComponentType) => {
  const [inputValue, setInputValue] = useState('');

  const onInputValueChange = (e: InputChangeEventType) => {
    setInputValue(e.target.value);
  };

  const onSubmitImageLink = async () => {
    const savedScene = await cesdk.save();

    insertQRCode({ url: inputValue, sceneBytes: savedScene }).then((resp) => {
      onQRCodeModalClose();
      cesdk.loadFromString(resp.updatedScene);
    });
  };

  return (
    <>
      <Modal
        isOpen={isQRCodeModalOpen}
        ariaHideApp={false}
        onRequestClose={onQRCodeModalClose}
        className="Modal__Content dark"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <h5 className="Modal__Content__title">Generate QR code</h5>
        <p className="text-sm font-mono mt-2 Modal__Content__select-label">
          Insert image link to generate QR code
        </p>

        <input
          placeholder="Insert link"
          className="Modal__Content__description-container__dark-field my-4"
          onChange={onInputValueChange}
        />
        <div className="flex justify-center">
          <button
            className={cn('Modal__Content__submit-btn-container__btn', {
              disabled: !inputValue,
            })}
            type="button"
            onClick={onSubmitImageLink}
            disabled={!inputValue}
          >
            submit
          </button>
        </div>
      </Modal>
    </>
  );
};

export default QRCode;
