import { DropdownStylesType } from '../types/bottom-actions.types';

export const darkDropdownStyles = (
  controlStyling = {},
  singleValueStyling = {},
  menuStyling = {},
  placeholderStyling = {},
  optionStyling = {},
): DropdownStylesType => ({
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    ...controlStyling,
    background: '#363636',
    border: '0.5px solid #242424',
    width: '100%',
    color: '#EEEEEE',
    boxShadow: 'none',
    ':hover': {
      border: '0.5px solid #242424',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    ...singleValueStyling,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#EEEEEE',
  }),
  menu: () => ({
    ...menuStyling,
    background: '#363636',
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    ...placeholderStyling,
    color: '#ffffff',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ...optionStyling,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: '100%',
    color: '#EEEEEE',
    background: '#363636',
    textOverflow: 'ellipsis',
    maxWidth: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      background: isSelected ? '#363636' : '#ffffff',
      color: isSelected ? '#EEEEEE' : '#000000',
      cursor: isSelected ? 'default' : 'pointer',
    },

    ...(isSelected
      ? {
          ':after': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            margin: 'auto',
            background:
              'center no-repeat url(/static/img/icons/simple-white-tick.svg)',
            content: '" "',
            display: 'block',
            height: 14,
            width: 14,
          },
        }
      : {}),
  }),
});

export const SUGGESTIONS_ARRAY = [
  {
    id: 0,
    message: 'Design a wedding card for dinosaurs in formal wear',
  },
  {
    id: 1,
    message: 'Design an Instagram post for a missing dog like a pokemon',
  },
  {
    id: 2,
    message:
      'Design a Youtube Channel Banner for my beauty and skincare influencer brand',
  },
  {
    id: 3,
    message: 'Design an Instagram post for an online marketing course',
  },
  {
    id: 4,
    message: 'Design a Twitter Ad Card for a crypto-currency course',
  },
  {
    id: 5,
    message: 'Design an Instagram Story for an online tutoring business',
  },
  {
    id: 6,
    message: 'Design an Instagram Post Landscape for a new sportswear line',
  },
  {
    id: 7,
    message: 'Design a birthday card for an online gaming friend',
  },
  {
    id: 8,
    message:
      'Design an Instagram post for celebrating 100,000 video views on Youtube',
  },
  {
    id: 9,
    message:
      'Design an Instagram post for celebrating a new feature for a SaaS product',
  },
];

export const robotoTypeface = {
  name: 'Roboto',
  fonts: [
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Black.ttf',
      subFamily: 'Black',
      weight: 'heavy',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-BlackItalic.ttf',
      subFamily: 'Black Italic',
      weight: 'heavy',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Bold.ttf',
      subFamily: 'Bold',
      weight: 'bold',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-BoldItalic.ttf',
      subFamily: 'Bold Italic',
      weight: 'bold',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Italic.ttf',
      subFamily: 'Italic',
      weight: 'normal',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Light.ttf',
      subFamily: 'Light',
      weight: 'light',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-LightItalic.ttf',
      subFamily: 'Light Italic',
      weight: 'light',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Medium.ttf',
      subFamily: 'Medium',
      weight: 'medium',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-MediumItalic.ttf',
      subFamily: 'Medium Italic',
      weight: 'medium',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Regular.ttf',
      subFamily: 'Regular',
      weight: 'normal',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Thin.ttf',
      subFamily: 'Thin',
      weight: 'thin',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-ThinItalic.ttf',
      subFamily: 'Thin Italic',
      weight: 'thin',
      style: 'italic',
    },
  ],
};

export const imageReplacementTypes = [
  {
    id: 0,
    label: 'Default',
    value: 'default',
  },
  {
    id: 1,
    label: 'Use generated images',
    value: 'generated_images',
  },
  {
    id: 2,
    label: 'Use internal images',
    value: 'internal_images',
  },
  {
    id: 3,
    label: 'Use external images',
    value: 'external_images',
  },
  {
    id: 4,
    label: 'By subject relevance',
    value: 'by_subject_relevance',
  },
];
