import React, { useState, useEffect, useCallback, useRef } from 'react';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import cn from 'classnames';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

// components
import ProjectCard from './ProjectCard';

// helpers
import { STUDIO_PROJECTS_CARD } from '../../../helpers/constants';
import generateZip from '../../../helpers/editor/generateZip';
import { getToasterOptions } from '../../../helpers/toaster';
import { convertImageUrlToFile } from '../../../helpers/common.helpers';

// hooks
import useClickOutside from '../../../hooks/useClickOutside';

// api
import { duplicateProject, getProjects } from '../../../api/projects';

// types
import {
  ISingleFolder,
  IStudioProject,
  IStudioProjectCard,
  ProjectsFolderType,
} from '../../../types/studio.types';

// styles
import '../../../styles/studio/studio-content.scss';

const SingleFolder = ({
  onBreadcrumbClick,
  selectedFolder,
  onProjectMoveToHome,
  onFolderRename,
  isToolStudio,
  isDarkMode,
}: ISingleFolder) => {
  const [projects, setProjects] = useState<IStudioProject[]>([]);
  const [isProjectDragging, setIsProjectDragging] = useState(false);
  const [isEditingFolderName, setIsEditingFolderName] = useState(false);
  const [folderName, setFolderName] = useState(selectedFolder?.name);
  const [rightClickedProject, setRightClickedProject] = useState<null | number>(
    null,
  );
  const [isProjectDuplicateLoading, setIsProjectDuplicateLoading] =
    useState(false);
  const [projectsLength, setProjectsLength] = useState(0);
  const [projectsHasNext, setProjectsHasNext] = useState(false);

  const inputRef = useRef(null);

  const [{ isOverCurrent }, homeDrop] = useDrop(
    () => ({
      accept: STUDIO_PROJECTS_CARD,
      drop(_item: IStudioProjectCard, monitor) {
        const didDrop = monitor.didDrop();

        if (didDrop) {
          return;
        }

        onProjectMoveToHome(_item.id, selectedFolder?.id as number);
        setProjects((prevState) =>
          prevState.filter((project) => project.id !== _item.id),
        );
        setIsProjectDragging(false);
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [],
  );

  const retrieveProjects = (isScrolling: boolean) => {
    let page = 1;

    if (isScrolling) {
      page++;
    }

    getProjects(selectedFolder?.id as number, null, null, page)
      .then((projectsData) => {
        setProjects((prevState) =>
          isScrolling
            ? [...prevState, ...projectsData.results]
            : projectsData.results,
        );
        setProjectsLength(projectsData.count);
        setProjectsHasNext(!!projectsData.next);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with projects',
          getToasterOptions(),
        );
      });
  };

  useEffect(() => {
    retrieveProjects(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setProjects((prevProjects) =>
      update(prevProjects, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevProjects[dragIndex]],
        ],
      }),
    );
  }, []);

  const onDeleteProject = (id: number) => {
    setProjects((prevState) =>
      prevState.filter((project) => project.id !== id),
    );
  };

  const onProjectDuplicate = (id: number) => {
    setIsProjectDuplicateLoading(true);

    duplicateProject(id)
      .then((data) => {
        setProjects((prevState) => [...prevState, data]);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with project duplication',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setIsProjectDuplicateLoading(false);
      });
  };

  const onProjectRename = (updatedProject: IStudioProject) => {
    setProjects((prevState) =>
      prevState.map((project) => {
        if (updatedProject.id === project.id) {
          return updatedProject;
        }

        return project;
      }),
    );
  };

  const checkIsProjectCardDragging = (isDragging: boolean) => {
    setIsProjectDragging(isDragging);
  };

  const onDownloadAll = async () => {
    const projectsImages = projects.map(async (project, index) => {
      const file = await convertImageUrlToFile(project.preview, index);

      return file;
    });

    const files = await Promise.all(projectsImages);

    generateZip(files);
  };

  const onFolderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  const onStartEditingFolderName = () => {
    setIsEditingFolderName(true);
  };

  const onFinishEditingFolderName = async () => {
    const updatedFolder = { ...selectedFolder, name: folderName };

    await onFolderRename(updatedFolder as ProjectsFolderType);
    setIsEditingFolderName(false);
  };

  const onSelectRightClickedProject = (id: number | null) => {
    setRightClickedProject(id);
  };

  useClickOutside(inputRef, onFinishEditingFolderName);

  return (
    <div
      className={cn('single-folder-container', {
        'w-full': !isToolStudio,
      })}
    >
      <div
        ref={homeDrop}
        className={cn('single-folder-container--breadcrumb-container', {
          'drag-active': isProjectDragging,
          'drag-hover': isOverCurrent,
          light: !isToolStudio && !isDarkMode,
        })}
        onClick={onBreadcrumbClick}
      >
        <p className="single-folder-container--breadcrumb-container--breadcrumb">
          <span
            className={cn(
              'single-folder-container--breadcrumb-container--breadcrumb--arrow',
              {
                dark: !isToolStudio && !isDarkMode,
              },
            )}
          />
          <span className="single-folder-container--breadcrumb-container--breadcrumb--title">
            home
          </span>
        </p>
      </div>

      <div>
        <div className="single-folder-container--folder-info">
          {!isEditingFolderName ? (
            <span
              className={cn('single-folder-container--folder-info--name', {
                dark: !isToolStudio && !isDarkMode,
              })}
            >
              {folderName}
              <span>
                {isToolStudio || isDarkMode ? (
                  <img
                    src="/static/img/icons/edit-white.svg"
                    alt="edit"
                    className="single-folder-container--folder-info--name--edit-icon"
                    onClick={onStartEditingFolderName}
                  />
                ) : (
                  <img
                    src="/static/img/icons/edit-dark.svg"
                    alt="edit"
                    className="single-folder-container--folder-info--name--edit-icon"
                    onClick={onStartEditingFolderName}
                  />
                )}
              </span>
            </span>
          ) : (
            <input
              type="text"
              value={folderName}
              onChange={onFolderNameChange}
              className={cn(
                'single-folder-container--folder-info--name-input',
                {
                  dark: !isToolStudio && !isDarkMode,
                },
              )}
              ref={inputRef}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              maxLength={30}
            />
          )}

          <span
            className="single-folder-container--folder-info--download"
            onClick={onDownloadAll}
          >
            <span
              className={cn(
                'single-folder-container--folder-info--download--label',
                {
                  dark: !isToolStudio && !isDarkMode,
                },
              )}
            >
              download all
            </span>
            <div
              className={cn(
                'single-folder-container--folder-info--download--arrow',
                {
                  dark: !isToolStudio && !isDarkMode,
                },
              )}
            />
          </span>
        </div>
        {!projects.length ? (
          <div
            className={cn(
              'single-folder-container--folder-container--empty-projects',
              {
                dark: !isToolStudio && !isDarkMode,
              },
            )}
          >
            No projects
          </div>
        ) : (
          <div
            className="studio-content projects"
            id="scrollableDiv"
            style={{
              height: 800,
              overflow: 'auto',
              display: 'flex',
              width: '100%',
            }}
          >
            <InfiniteScroll
              key={projects.length.toString()}
              dataLength={projectsLength}
              next={() => retrieveProjects(true)}
              hasMore={projectsHasNext as unknown as boolean}
              loader={
                <h4 className="text-[#000000] dark:text-[color:#ffffff]">
                  Loading...
                </h4>
              }
              scrollableTarget="scrollableDiv"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
              }}
            >
              {projects.map((project: IStudioProject, index: number) => (
                <ProjectCard
                  key={project.id}
                  index={index}
                  id={project.id}
                  project={project}
                  moveCard={moveCard}
                  projects={projects}
                  onDeleteProject={onDeleteProject}
                  onProjectDuplicate={onProjectDuplicate}
                  onProjectRename={onProjectRename}
                  checkIsProjectCardDragging={checkIsProjectCardDragging}
                  rightClickedProject={rightClickedProject}
                  onSelectRightClickedProject={onSelectRightClickedProject}
                  isProjectDuplicateLoading={isProjectDuplicateLoading}
                  isToolStudio={isToolStudio}
                  isDarkMode={isDarkMode}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleFolder;
