import React, { ReactElement } from 'react';

// types
import { IPromptToDesignHeader } from '../../../types/studio.types';

// styling
import '../../../styles/studio/prompt-to-design.scss';

const PromptToDesignHeader = ({ p2dStage }: IPromptToDesignHeader) => {
  let headerTitle = 'what kind of social post would you like to design?';
  let headerAdditionalInfo: null | ReactElement = (
    <div className="p2d-container--header--additional-info">
      <p className="p2d-container--header--additional-info--formats-hint">
        in any format for
      </p>
      <div className="p2d-container--header--additional-info--formats-container">
        <img
          src="../../../../static/img/icons/instagram-original-logo.svg"
          alt="instagram"
        />
        <img
          src="../../../../static/img/icons/facebook-original-logo.svg"
          alt="facebook"
        />
        <img
          src="../../../../static/img/icons/x(ex-twitter).svg"
          alt="twitter"
        />
        <img
          src="../../../../static/img/icons/linkedin-original-logo.svg"
          alt="linkedin"
        />
        <img
          src="../../../../static/img/icons/youtube-original-logo.svg"
          alt="youtube"
        />
        <img
          src="../../../../static/img/icons/tiktok-original-logo.svg"
          alt="tiktok"
        />
        <img
          src="../../../../static/img/icons/pinterest-original-logo.svg"
          alt="pinterest"
        />
        <img
          src="../../../../static/img/icons/twitch-original-logo.svg"
          alt="twitch"
        />
      </div>
      {/* <p className="p2d-container--header--additional-info--example-description">
        Describe what kind of social media post you’d like to design!
        <br />
        <span className="p2d-container--header--additional-info--example-description--example">
          ”Design an Instagram post for a ...”
        </span>
      </p> */}
    </div>
  );

  switch (p2dStage) {
    case 1.5:
      headerTitle = 'choosing design styles...';
      headerAdditionalInfo = null;
      break;
    case 2:
      headerTitle = 'select your favorite style';
      headerAdditionalInfo = null;
      break;
    case 2.5:
      headerTitle = 'generating your design...';
      headerAdditionalInfo = null;
      break;
    case 3:
      headerTitle = 'your design set';
      headerAdditionalInfo = null;
      break;
    default:
      break;
  }
  return (
    <div className="p2d-container--header">
      <p className="p2d-container--header--title">{headerTitle}</p>

      {headerAdditionalInfo}
    </div>
  );
};

export default PromptToDesignHeader;
