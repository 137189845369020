import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

// components
import Toggle from '../../../elements/Toggle';

// api
import { createCustomDesignAssistance } from '../../../api/helpers';

// types
import { ToggleOptionType } from '../../../types/elements.types';
import { TextareaChangeEventType } from '../../../types/common.types';

const SomethingCustomOption = () => {
  const [shouldAddBrandAssets, setShouldAddBrandAssets] =
    useState<ToggleOptionType | null>(null);
  const [addAssetInsteadOfReplacing, setAddAssetInsteadOfReplacing] =
    useState<ToggleOptionType | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [userRequest, setUserRequest] = useState('');

  const isProceedDisabled =
    !userRequest ||
    !shouldAddBrandAssets ||
    !addAssetInsteadOfReplacing ||
    !uploadedImage?.name;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = useCallback((acceptedFiles: any) => {
    setUploadedImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onToggleShouldAddBrandAssets = (selectedOption: ToggleOptionType) => {
    setShouldAddBrandAssets(selectedOption);
  };

  const onToggleAddAssetInsteadOfReplacing = (
    selectedOption: ToggleOptionType,
  ) => {
    setAddAssetInsteadOfReplacing(selectedOption);
  };

  const onUserRequestChange = (e: TextareaChangeEventType) => {
    setUserRequest(e.target.value);
  };

  const onProceedToBilling = () => {
    const data = new FormData();

    data.append('request', userRequest);
    data.append('attachment', uploadedImage);
    data.append('add_or_change_asset', addAssetInsteadOfReplacing?.value);
    data.append('brand_assets', shouldAddBrandAssets?.value);

    createCustomDesignAssistance(data).then((resp) => {
      console.log(resp);
    });
  };

  return (
    <div>
      <p className="design-assistance-label">
        Send a request for design assistance and a member of our team will get
        back to you!
      </p>
      <div className="design-assistance-request-container">
        <textarea
          name="request"
          cols={30}
          rows={10}
          className="design-assistance-request-container--request-field"
          placeholder="What are you thinking of?"
          value={userRequest}
          onChange={onUserRequestChange}
        />

        <div className="design-assistance-request-container-wrapper">
          <div
            className="design-assistance-request-container--upload-field"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <p className="design-assistance-request-container--upload-field--button">
              Upload Attachment
            </p>
            <p className="design-assistance-request-container--upload-field--formats">
              Accepts .gif, .jpg, and .png
            </p>
          </div>
          <div className="design-assistance-request-container-wrapper--uploaded-file-name">
            <p>{uploadedImage?.name}</p>
          </div>
        </div>
      </div>

      <p className="design-assistance-label">
        Would you like brand assets added to the design?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={shouldAddBrandAssets}
          toggleHandler={onToggleShouldAddBrandAssets}
        />
      </div>

      <p className="design-assistance-label">
        Would you like to add an asset or change an existing asset?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={addAssetInsteadOfReplacing}
          toggleHandler={onToggleAddAssetInsteadOfReplacing}
        />
      </div>

      <div className="design-assistance-conclusion">
        <p className="design-assistance-conclusion--total">total</p>
        <p className="design-assistance-conclusion--price">$100</p>
        <button
          type="button"
          className={cn('design-assistance-conclusion--proceed-billing', {
            disabled: isProceedDisabled,
          })}
          onClick={onProceedToBilling}
          disabled={isProceedDisabled}
        >
          Proceed to billing
        </button>
      </div>
    </div>
  );
};

export default SomethingCustomOption;
