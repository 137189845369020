import { useEffect, MutableRefObject } from 'react';

import { EmptyCallbackType } from '../types/common.types';

const useClickOutside = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  node: MutableRefObject<any>,
  callback: EmptyCallbackType,
  elementToAvoid = '',
  startEvent = 'mousedown',
  endEvent = 'mousedown',
) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (elementToAvoid) {
        if (
          !(
            event.target.id.includes('context-menu') ||
            event.target.parentElement.id.includes('context-menu')
          )
        ) {
          callback();
        }
      } else if (node.current && !node.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener(startEvent, handleClickOutside);

    return () => {
      document.removeEventListener(endEvent, handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, callback, elementToAvoid]);
};

export default useClickOutside;
