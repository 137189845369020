import React from 'react';

// components
import StudioProjects from './Projects/StudioProjects';
import StudioFormatExtender from './FormatExtender/StudioFormatExtender';
import ReGen from './ReGen';
import GenreAssets from './GenreAssets';
import PromptToDesign from './PromptToDesign';
import StudioEmptyPlaceholder from './StudioEmptyPlaceholder';

// types
import { IStudioContent } from '../../types/studio.types';

const StudioContent = ({ selectedSidebarElementId }: IStudioContent) => {
  switch (selectedSidebarElementId) {
    case 0:
      return <StudioProjects />;
    case 1:
      return <ReGen />;
    case 2:
      return <StudioFormatExtender />;
    case 3:
      return <StudioEmptyPlaceholder />;
    case 4:
      return <StudioEmptyPlaceholder />;
    case 5:
      return <GenreAssets />;
    case 6:
      return <PromptToDesign />;
    default:
      return null;
  }
};

export default StudioContent;
