import fetch from '../helpers/customFetch';

export const getCustomImages = (
  query = '',
  page = 1,
  perPage = 10,
  assetCategory,
  templateGenre,
  shouldIgnoreCaching = false,
) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }
  if (assetCategory) {
    searchParams.set('asset_categories', assetCategory);
  }
  if (templateGenre) {
    if (Array.isArray(templateGenre) && templateGenre.length) {
      searchParams.set('template_genres', templateGenre.join(','));
    }
  }
  if (shouldIgnoreCaching) {
    searchParams.set('ignore_caching', 1);
  }

  try {
    return fetch({
      url: `/assets/images/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getCustomPhotos = (
  query = '',
  page = 1,
  perPage = 10,
  assetCategory,
) => {
  const searchParams = new URLSearchParams();

  if (query || assetCategory) {
    searchParams.set('search', `${assetCategory}${query ? `,${query}` : ''}`);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/external-assets/images/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getCustomShapes = (query = '', page = 1, perPage = 10) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/assets/shapes/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getCustomStickers = (query = '', page = 1, perPage = 10) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/assets/stickers/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getCustomTemplates = (query = '', page = 1, perPage = 10) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/templates/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const removeImageBackground = (image) => {
  const data = new FormData();
  data.append('image', image);

  try {
    return fetch({
      url: '/helpers/remove-background/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getPageFormats = () => {
  try {
    return fetch({
      url: '/formats/',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const adjustSceneToSelectedPageFormat = (data) => {
  try {
    return fetch({
      url: '/helpers/scale-scene/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

// library - 'images' | 'stickers' | 'shapes' | 'textures' | '3d-models'
export const getAssetsCategoriesByLibrary = (library = 'images') => {
  try {
    return fetch({
      url: `/assets/categories/${library}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

// section - OTHER, RECOMMENDED, INTERNATIONAL
export const getCustomFonts = (section = '') => {
  const queryParams = new URLSearchParams();

  if (section) {
    queryParams.set('section', section);
  }

  try {
    return fetch({
      url: `/creative-editor-fonts/?${queryParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getTemplatesGenres = () => {
  try {
    return fetch({
      url: '/templates/template-genre/?ordering=name',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const addNewCustomImage = (data) => {
  try {
    return fetch({
      url: '/assets/images/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateCustomImage = (data, id) => {
  try {
    return fetch({
      url: `/assets/images/${id}/`,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const partialUpdateCustomImage = (data, id) => {
  try {
    return fetch({
      url: `/assets/images/${id}/`,
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteCustomImage = (id) => {
  try {
    return fetch({
      url: `/assets/images/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getTemplates = (
  query = '',
  page = 1,
  perPage = 10,
  isTemplatesUniqueByGenre = false,
) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }
  if (isTemplatesUniqueByGenre) {
    searchParams.set('distinct_by_genre', 1);
  }

  try {
    return fetch({
      url: `/templates/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getAssetsLayers = (
  query = '',
  page = 1,
  perPage = 10,
  assetCategory,
  templateGenre,
  shouldIgnoreCaching = false,
) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }
  if (assetCategory) {
    searchParams.set('asset_categories', assetCategory);
  }
  if (templateGenre) {
    if (Array.isArray(templateGenre) && templateGenre.length) {
      searchParams.set('template_genres', templateGenre.join(','));
    }
  }
  if (shouldIgnoreCaching) {
    searchParams.set('ignore_caching', 1);
  }

  try {
    return fetch({
      url: `/assets/layers/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const updateCustomLayer = (data, id) => {
  try {
    return fetch({
      url: `/assets/layers/${id}/`,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const addNewCustomLayerImage = (data) => {
  try {
    return fetch({
      url: '/assets/layers/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

// section - OTHER, RECOMMENDED, INTERNATIONAL
export const getDefaultFonts = (section = '') => {
  const queryParams = new URLSearchParams();

  if (section) {
    queryParams.set('section', section);
  }

  try {
    return fetch({
      url: `/creative-editor-default-fonts/?${queryParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const assetsUpdateReplaceableStatus = (ids, data) => {
  const queryParams = new URLSearchParams();

  if (ids) {
    queryParams.set('ids', ids);
  }

  try {
    return fetch({
      url: `/assets/layers/bulk_update/?${queryParams.toString()}`,
      method: 'PATCH',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const getSystemImages = (query = '', page = 1, perPage = 10) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/assets/system-images/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export const deleteLayer = (id) => {
  try {
    return fetch({
      url: `/assets/layers/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};
