import React from 'react';

// types
import { IReGenWelcome } from '../../../types/studio-re-gen';

// styles
import '../../../styles/studio/re-gen.scss';

const ReGenWelcome = ({ onReGenStart }: IReGenWelcome) => {
  return (
    <div className="re-gen--content">
      <div className="re-gen-welcome-container">
        <p className="re-gen-welcome-container--title">welcome to re-gen</p>
        <p className="re-gen-welcome-container--description">
          This feature can refresh your design with colors, texts, and images.
          This allows you to quickly produce another variant of your design to
          allow you to create a set of content from one singular design.
          <br />
          <br />
          You can choose a genre to start from or start from a single design. In
          addition, the tool will allow you to browse through a library of
          images, graphics, and easily replace existing imagery with new,
          relevant, and on-brand visuals.
          <br />
          <br />
          The re-gen tool is a powerful feature to help refresh your designs
          with minimal effort, and extends and updates your work.
        </p>
        <div className="re-gen-button-container">
          <button
            type="button"
            className="re-gen-button-container--btn"
            onClick={onReGenStart}
          >
            start
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReGenWelcome;
