import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { TOPBAR } from '../../helpers/constants';

import { ITopbar } from './types';

export const topbarAdapter = createEntityAdapter<ITopbar>();

const name = TOPBAR;
const initialState = topbarAdapter.getInitialState();

const setTopbar = topbarAdapter.upsertMany;

const reducers = {
  setTopbar,
};

export default createSlice({ name, initialState, reducers });
