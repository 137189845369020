import React, { useState } from 'react';
import { toast } from 'react-toastify';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// api
import { submitReGenFeedback } from '../../../api/helpers';

// types
import { IReGenFifthStep } from '../../../types/studio-re-gen';
import { TextareaChangeEventType } from '../../../types/common.types';

const ReGenFifthStep = ({ onReGenAgain, reGen }: IReGenFifthStep) => {
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);
  const [userFeedback, setUserFeedback] = useState('');

  const onTextAreaShow = () => {
    setIsTextAreaVisible(true);
  };

  const onSubmitFeedback = () => {
    submitReGenFeedback({ feedback: userFeedback, aiReGen: reGen?.id })
      .then(() => {
        toast.success(
          'Your feedback was successfully submitted',
          getToasterOptions(),
        );
        setUserFeedback('');
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred when submitting your feedback',
          getToasterOptions(),
        );
      });
  };

  const startReGenAgain = () => {
    onReGenAgain();
  };

  const onUserFeedbackChange = (e: TextareaChangeEventType) => {
    setUserFeedback(e.target.value);
  };

  const goBackToProjects = () => {
    const projectsSidebarEl = document.getElementById('projects');

    projectsSidebarEl && projectsSidebarEl.click();
  };

  return (
    <div className="re-gen--content">
      <div className="complete-container">
        <p className="complete-container--title">Thanks for trying re-gen!</p>

        <div className="complete-container--actions-container">
          <button
            type="button"
            className="re-gen-btn px-15-py-8 min-w-275"
            onClick={startReGenAgain}
          >
            <span className="re-gen-btn--title w-full lowercase">
              re-gen again
            </span>
          </button>
          <button
            type="button"
            className="re-gen-btn px-15-py-8 min-w-275"
            onClick={goBackToProjects}
          >
            <span className="re-gen-btn--title w-full lowercase">
              back to projects
            </span>
          </button>
          {isTextAreaVisible && (
            <div>
              <p className="label">Feedback</p>
              <textarea
                cols={30}
                rows={10}
                className="re-gen-ai-container--subject--field max-w-315"
                placeholder="Please leave us some feedback on re-gen!&#10;How could we improve the service?"
                onChange={onUserFeedbackChange}
                value={userFeedback}
              />
            </div>
          )}
          <button
            type="button"
            className="re-gen-btn px-15-py-8 mt-16 min-w-275"
            onClick={isTextAreaVisible ? onSubmitFeedback : onTextAreaShow}
          >
            <span className="re-gen-btn--title w-full lowercase">
              {isTextAreaVisible ? 'Submit feedback' : 'leave some feedback'}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReGenFifthStep;
