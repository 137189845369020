import React from 'react';

import cn from 'classnames';

import '../../../styles/sidebar/gridContent/loading-state.scss';

export default ({
  loading = false,
  message = '',
  positionAbsolute = false,
}) => {
  if (!loading && !message) return null;

  return (
    <div
      className={cn('AssetLibrarySectionSkeleton__content', {
        'absolute-potioning': positionAbsolute,
      })}
    >
      <span className="AssetLibrarySectionSkeleton__label">
        {message && (
          <span className="AssetLibrarySectionSkeleton__label">
            Cannot connect to asset source
          </span>
        )}
        {loading && (
          <>
            <img
              className="AssetLibrarySectionSkeleton__loadingSpinnerIcon"
              src="/static/img/icons/editor/loading-spinner.svg"
              alt="loading"
            />
            <span className="AssetLibrarySectionSkeleton__label">
              Loading …
            </span>
          </>
        )}
      </span>
    </div>
  );
};
