import React from 'react';
import cn from 'classnames';

import { IButton } from '../types/elements.types';

import '../styles/elements/button.scss';

const Button = ({
  leftContent = null,
  children,
  rightContent = null,
  handleClick = () => {},
  className = '',
  rightContentClassName = '',
  leftContentDisabled = false,
  mainContentDisabled = false,
  rightContentDisabled = false,
}: IButton) => {
  return (
    <div className="button-container">
      {leftContent && (
        <span
          className={cn('button-container--inner-section', {
            disabled: leftContentDisabled,
          })}
        >
          {leftContent}
        </span>
      )}
      <button
        type="button"
        onClick={handleClick}
        className={cn('button-container--inner-section', className, {
          disabled: mainContentDisabled,
        })}
        disabled={mainContentDisabled}
      >
        {children}
      </button>
      {rightContent && (
        <span
          className={cn(
            'button-container--inner-section',
            rightContentClassName,
            {
              disabled: rightContentDisabled,
            },
          )}
        >
          {rightContent}
        </span>
      )}
    </div>
  );
};

export default Button;
