import axios from 'axios';
import Cookies from 'universal-cookie';

const client = axios.create({
  baseURL: `${window.location.origin}/api`,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(
  (response) => {
    // triggers if status code is 2xx
    // add some additional data handling if needed
    return response;
  },
  (error) => {
    // triggers if status code is not 2xx
    // do something with error if needed
    return Promise.reject(error);
  },
);

const request = (options) => {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  const csrftoken = cookies.get('csrftoken');

  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error);
  };

  if (accessToken) {
    client.defaults.headers.Authorization = `Basic ${accessToken}`;
  }

  if (csrftoken) {
    client.defaults.headers['X-CSRFToken'] = csrftoken;
  }

  return client(options).then(onSuccess).catch(onError);
};

export default request;
