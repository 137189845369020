/* eslint-disable no-shadow */
import { ChangeEvent, ForwardedRef } from 'react';

export const gridContentVar = 'gridContent';

export const avatar = 'avatar';
export const body = 'body';
export const pose = 'pose';
export const lighting = 'lighting';

export type SelectedTopbarType =
  | typeof avatar
  | typeof body
  | typeof pose
  | typeof lighting
  | null;

export type InputChangeEventType = ChangeEvent<HTMLInputElement>;
export type TextareaChangeEventType = ChangeEvent<HTMLTextAreaElement>;
export type EmptyCallbackType = () => void;
export type RounderSliderCallbackValueType = {
  angle: number;
  value: number;
};
export type ColorHexType = `#${string}`;

type ColorType = {
  hex: ColorHexType;
  thumbUrl?: string;
  id: number;
};

export type ArrayOfColorsType = Array<ColorType>;

export type DivForwardedRef = ForwardedRef<HTMLDivElement>;

export type DropdownOptionType = {
  id: number;
  label: string;
  value: string;
};

export type RadioBtnType = {
  id: number;
  label: string;
  value: string;
};

export type SvgBtnProps = {
  options: typeof Option[];
  selectedValue: string;
  onValueChange: (value: string) => void;
  icons: Record<string, string>;
};

export enum ToneEnum {
  casual = 'casual',
  creative = 'creative',
  professional = 'professional',
  exciting = 'exciting',
}

export enum ReGenStatusEnum {
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED',
  approved = 'APPROVED',
  published = 'PUBLISHED',
}
