import React from 'react';

const GenreAssetsIcon = ({ fill = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M13.0962 5.05893H9.55372C9.48902 4.56557 9.32322 4.12074 9.05632 3.72444C8.66811 3.19064 8.06556 2.92374 7.2487 2.92374C6.08405 2.92374 5.2874 3.50202 4.85875 4.65858C4.63229 5.27325 4.51906 6.09012 4.51906 7.10919C4.51906 8.07972 4.63229 8.8602 4.85875 9.45061C5.27123 10.5505 6.04766 11.1005 7.18804 11.1005C7.99682 11.1005 8.57105 10.8821 8.91074 10.4454C9.25043 10.0087 9.45667 9.44252 9.52946 8.74697H13.0598C12.9789 9.79838 12.5988 10.7932 11.9194 11.7314C10.8356 13.2438 9.23021 14 7.10312 14C4.97602 14 3.41103 13.3692 2.40814 12.1075C1.40525 10.8458 0.903809 9.20797 0.903809 7.19411C0.903809 4.92143 1.45782 3.15425 2.56585 1.89255C3.67388 0.630849 5.20248 0 7.15164 0C8.80964 0 10.1644 0.372039 11.2158 1.11612C12.2753 1.8602 12.9021 3.17447 13.0962 5.05893Z"
        fill={fill}
      />
    </svg>
  );
};

export default GenreAssetsIcon;
