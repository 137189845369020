import fetch from '../helpers/customFetch';

export const getProfile = () => {
  try {
    return fetch({
      url: '/user/profile/',
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};
