import React from 'react';
import cn from 'classnames';

// types
import { IToggle } from '../types/elements.types';

// styles
import '../styles/elements/toggle.scss';

const Toggle = ({ options, selectedOption, toggleHandler }: IToggle) => {
  return (
    <div className="toggle-container">
      {options.map((option) => {
        return (
          <div
            key={option.id}
            className={cn('toggle-container--option', {
              active: option.id === selectedOption?.id,
            })}
            onClick={() => toggleHandler(option)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

export default Toggle;
