import React from 'react';

import { EntityId } from '@reduxjs/toolkit';
import Menu from './Menu';
import Content from './Content';

import { useAppSelector } from '../../helpers/hooks';

import { ISidebarComponent } from '../../types/studio.types';

const Sidebar = ({
  selectedId,
  setSelectedId,
  groupParams = { elementsInRow: 2, backgroundCover: true },
  gridParams = { elementsInRow: 2, backgroundCover: true },
}: ISidebarComponent) => {
  const { ids: navbarBtnsIds } = useAppSelector((state) => state.navbar);

  const onMenuButtonClick = (id: EntityId) => {
    setSelectedId(selectedId === id ? null : id);
  };

  const onContentCloseButtonClick = () => {
    setSelectedId(null);
  };

  return (
    <>
      <Menu
        data={navbarBtnsIds}
        selectedId={selectedId}
        handleButtonClick={onMenuButtonClick}
      />
      <Content
        selectedId={selectedId}
        data={navbarBtnsIds}
        handleCloseButtonClick={onContentCloseButtonClick}
        groupParams={groupParams}
        gridParams={gridParams}
      />
    </>
  );
};

export default Sidebar;
