import React from 'react';

import Button from '../../elements/Button';

import { IProjectsButton } from '../../types/studio.types';

import '../../styles/header/projects-button.scss';

const ProjectsButton = ({ handleClick, label }: IProjectsButton) => {
  return (
    <Button handleClick={handleClick} className="projects-button">
      <img
        className="projects-button--icon"
        src="/static/img/icons/editor/arrow-next.svg"
        alt={label}
      />
      <span>{label}</span>
    </Button>
  );
};

export default ProjectsButton;
