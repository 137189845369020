import React, { useState, useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from 'react-modal';

// components
import ProjectCard from '../StudioEditor/Projects/ProjectCard';
import ProjectsFolder from './ProjectsFolder';
import SingleFolder from '../StudioEditor/Projects/SingleFolder';
import GeneralProjectsContextMenu from '../StudioEditor/Projects/GeneralProjectsContextMenu';

// helpers
import { getToasterOptions } from '../../helpers/toaster';

// hooks
import useClickOutside from '../../hooks/useClickOutside';

// api
import {
  getProjects,
  getProjectsFolders,
  createProjectFolder,
  updateProject,
  duplicateProject,
  updateProjectFolder,
  duplicateProjectFolder,
  deleteProjectFolder,
  createProject,
  getProjectsSingleFolder,
  updateFoldersOrder,
  multipleProjectsDelete,
  moveProjectsToFolder,
} from '../../api/projects';

// types
import { IStudioProject, ProjectsFolderType } from '../../types/studio.types';

// styles
import '../../styles/studio/studio-content.scss';

const ProjectsContent = ({
  isToolStudio = false,
  allowSelectProjects = false,
  isDarkMode = false,
  currentProjectsPage = 1,
  setNextPage = () => {},
}) => {
  const [projects, setProjects] = useState<IStudioProject[]>([]);
  const [projectsLength, setProjectsLength] = useState(0);
  const [projectsHasNext, setProjectsHasNext] = useState(false);
  const [projectFolders, setProjectFolders] = useState<
    ProjectsFolderType[] | undefined
  >();
  const [isFolderView, setIsFolderView] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<
    ProjectsFolderType | undefined
  >();
  const [isProjectDragging, setIsProjectDragging] = useState(false);
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuXYPosition, setContextMenuXYPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isFolderDragging, setIsFolderDragging] = useState(false);
  const [shouldUpdateFoldersOrder, setShouldUpdateFoldersOrder] =
    useState(false);
  const [isProjectDuplicateLoading, setIsProjectDuplicateLoading] =
    useState(false);
  const [isFolderDuplicateLoading, setIsFolderDuplicateLoading] =
    useState(false);
  const [rightClickedProject, setRightClickedProject] = useState<null | number>(
    null,
  );
  const [rightClickedFolder, setRightClickedFolder] = useState<null | number>(
    null,
  );
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [deleteMultipleModalShown, setDeleteMultipleModalShown] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const folderId = searchParams.get('folderId');

  const projectsContainerRef = useRef<HTMLDivElement>(null);

  const sortValue = searchParams.get('sort');
  const filterValue = searchParams.get('filter');
  const searchValue = searchParams.get('search');

  const retrieveProjects = (isScrolling: boolean) => {
    let page = currentProjectsPage;

    if (isScrolling) {
      setNextPage();
      page++;
    }

    getProjects(null, sortValue, filterValue, page, 50, searchValue)
      .then((projectsData) => {
        setProjects((prevState) =>
          isScrolling
            ? [...prevState, ...projectsData.results]
            : projectsData.results,
        );
        setProjectsLength(projectsData.count);
        setProjectsHasNext(!!projectsData.next);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with projects',
          getToasterOptions(),
        );
      });
  };

  useEffect(() => {
    retrieveProjects(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, filterValue, searchValue]);

  useEffect(() => {
    getProjectsFolders(200)
      .then((projectsFolders) => {
        setProjectFolders(projectsFolders.results);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with folders',
          getToasterOptions(),
        );
      });
  }, []);

  useEffect(() => {
    if (folderId) {
      getProjectsSingleFolder(+folderId).then((folder) => {
        setSelectedFolder(folder);
        setIsFolderView(true);
      });
    }
  }, [folderId]);

  useEffect(() => {
    if (shouldUpdateFoldersOrder) {
      const projectFoldersWithOrder = projectFolders?.map(
        (innerProjectFolder, innerIndex) => ({
          id: innerProjectFolder.id,
          order: innerIndex,
        }),
      );

      updateFoldersOrder(projectFoldersWithOrder)
        .catch(() => {
          toast.error(
            'Ooooops, error occurred with keeping projects order',
            getToasterOptions(),
          );
        })
        .finally(() => {
          setShouldUpdateFoldersOrder(false);
        });
    }
  }, [shouldUpdateFoldersOrder, projectFolders]);

  const handleAddNewFolder = (closeContextMenu = false) => {
    createProjectFolder({
      name: 'New Folder',
      order: projectFolders?.length
        ? (projectFolders[projectFolders.length - 1].order as number) + 1
        : 0,
    })
      .then((newFolder: ProjectsFolderType) => {
        setProjectFolders((prevState) =>
          prevState?.length ? [...prevState, newFolder] : [newFolder],
        );
        if (closeContextMenu) {
          setIsContextMenuVisible(false);
        }
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with creating new folder',
          getToasterOptions(),
        );
      });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setProjects((prevProjects) =>
      update(prevProjects, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevProjects[dragIndex]],
        ],
      }),
    );
  }, []);

  const onDeleteProject = (id: number) => {
    setProjects((prevState) =>
      prevState.filter((project) => project.id !== id),
    );
  };

  const onProjectDuplicate = (id: number) => {
    setIsProjectDuplicateLoading(true);

    duplicateProject(id)
      .then((data) => {
        setProjects((prevState) => [data, ...prevState]);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with project duplication',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setIsProjectDuplicateLoading(false);
      });
  };

  const onProjectRename = (updatedProject: IStudioProject) => {
    setProjects((prevState) =>
      prevState.map((project) => {
        if (updatedProject.id === project.id) {
          return updatedProject;
        }

        return project;
      }),
    );
  };

  const checkIsProjectCardDragging = (isDragging: boolean) => {
    setIsProjectDragging(isDragging);
  };

  const checkIsFolderDragging = (isDragging: boolean) => {
    setIsFolderDragging(isDragging);
  };

  const onSelectRightClickedProject = (id: number | null) => {
    setRightClickedProject(id);
  };

  const onSelectRightClickedFolder = (id: number | null) => {
    setRightClickedFolder(id);
  };

  const onSelectProjects = (projectId: number | []) => {
    if (!allowSelectProjects) {
      return;
    }

    if (typeof projectId === 'number') {
      setSelectedProjects((prevState) => {
        if (prevState.includes(projectId)) {
          return prevState.filter(
            (innerProjectId) => innerProjectId !== projectId,
          );
        }

        return [...prevState, projectId];
      });
    } else {
      setSelectedProjects([]);
    }
  };

  const onSelectedProjectsUpdateStatus = (status: string) => {
    setProjects((prevState) =>
      prevState.map((innerProject) => {
        if (selectedProjects.includes(innerProject.id)) {
          return { ...innerProject, status };
        }

        return innerProject;
      }),
    );
  };

  const onMultipleDeletionModalOpen = () => {
    setDeleteMultipleModalShown(true);
    setIsContextMenuVisible(false);
  };

  const onMultipleDeletionModalClose = () => {
    setDeleteMultipleModalShown(false);
  };

  const onSelectedProjectsDelete = () => {
    multipleProjectsDelete(selectedProjects).then(() => {
      setProjects((prevState) =>
        prevState.filter(
          (innerProject) => !selectedProjects.includes(innerProject.id),
        ),
      );
      onMultipleDeletionModalClose();
    });
  };

  const onMoveProjectsToFolder = (innerFolderId: number) => {
    moveProjectsToFolder(innerFolderId, { project_ids: selectedProjects }).then(
      () => {
        setProjects((prevState) =>
          prevState.filter(
            (innerProject) => !selectedProjects.includes(innerProject.id),
          ),
        );

        setProjectFolders((prevState) => {
          if (prevState) {
            return prevState.map((innerFolder) => {
              if (innerFolder.id === innerFolderId) {
                return {
                  ...innerFolder,
                  projectTotal:
                    (innerFolder.projectTotal as number) +
                    selectedProjects.length,
                };
              }

              return innerFolder;
            });
          }
        });

        setSelectedProjects([]);
      },
    );
  };

  const renderCard = useCallback(
    (project: IStudioProject, index: number) => {
      return (
        <ProjectCard
          key={project.id}
          index={index}
          id={project.id}
          project={project}
          moveCard={moveCard}
          projects={projects}
          onDeleteProject={onDeleteProject}
          onProjectDuplicate={onProjectDuplicate}
          onProjectRename={onProjectRename}
          checkIsProjectCardDragging={checkIsProjectCardDragging}
          isProjectDuplicateLoading={isProjectDuplicateLoading}
          rightClickedProject={rightClickedProject}
          onSelectRightClickedProject={onSelectRightClickedProject}
          isToolStudio={isToolStudio}
          onSelectProjects={onSelectProjects}
          selectedProjects={selectedProjects}
          onSelectedProjectsUpdateStatus={onSelectedProjectsUpdateStatus}
          onMultipleDeletionModalOpen={onMultipleDeletionModalOpen}
          onMoveProjectsToFolder={onMoveProjectsToFolder}
          projectFolders={projectFolders}
          isDarkMode={isDarkMode}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projects,
      isProjectDragging,
      isProjectDuplicateLoading,
      rightClickedProject,
      selectedProjects,
      onSelectProjects,
      isDarkMode,
    ],
  );

  const moveFolder = useCallback((dragIndex: number, hoverIndex: number) => {
    setProjectFolders(
      (prevProjectsFolders) =>
        prevProjectsFolders &&
        update(prevProjectsFolders, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevProjectsFolders[dragIndex]],
          ],
        }),
    );
    setShouldUpdateFoldersOrder(true);
  }, []);

  const onProjectMoveToFolder = (id: number, projectFolderId: number) => {
    updateProject({ id, folder: projectFolderId })
      .then(() => {
        setProjects((prevState) =>
          prevState.filter((project) => project.id !== id),
        );
        setProjectFolders((prevState) =>
          prevState?.map((projectFolder) => {
            if (projectFolder.id === projectFolderId) {
              return {
                ...projectFolder,
                projectTotal: projectFolder?.projectTotal
                  ? projectFolder?.projectTotal + 1
                  : 1,
              };
            }

            return projectFolder;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating project',
          getToasterOptions(),
        );
      });
  };

  const onFolderOpen = (
    event: React.MouseEvent<HTMLDivElement>,
    projectFolder: ProjectsFolderType,
  ) => {
    if (event.detail == 2 || window.innerWidth <= 480) {
      setIsFolderView((prevState) => !prevState);
      setSelectedFolder(projectFolder);
    }
  };

  const onFolderClose = () => {
    setIsFolderView((prevState) => !prevState);
    setSearchParams('');
  };

  const onProjectMoveToHome = (id: number, projectFolderId: number) => {
    updateProject({ id, folder: null })
      .then((newProject) => {
        setProjects((prevState) => [...prevState, newProject]);
        setProjectFolders((prevState) =>
          prevState?.map((projectFolder) => {
            if (projectFolder.id === projectFolderId) {
              return {
                ...projectFolder,
                projectTotal: projectFolder?.projectTotal
                  ? projectFolder?.projectTotal - 1
                  : 0,
              };
            }

            return projectFolder;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating project',
          getToasterOptions(),
        );
      });
  };

  const onFolderRename = (projectFolder: ProjectsFolderType) => {
    updateProjectFolder(projectFolder)
      .then((updatedFolder) => {
        setProjectFolders((prevState) =>
          prevState?.map((project) => {
            if (updatedFolder.id === project.id) {
              return updatedFolder;
            }

            return project;
          }),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating folder',
          getToasterOptions(),
        );
      });
  };

  const onProjectFolderDuplicate = (id: number) => {
    setIsFolderDuplicateLoading(true);
    duplicateProjectFolder(id)
      .then((duplicatedProjectFolder: ProjectsFolderType) => {
        setProjectFolders((prevState) =>
          prevState
            ? [...prevState, duplicatedProjectFolder]
            : [duplicatedProjectFolder],
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with updating folder',
          getToasterOptions(),
        );
      })
      .finally(() => {
        setIsFolderDuplicateLoading(false);
      });
  };

  const onProjectFolderDelete = (id: number) => {
    deleteProjectFolder(id)
      .then(() => {
        setProjectFolders((prevState) =>
          prevState?.filter((project) => project.id !== id),
        );
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with deleting folder',
          getToasterOptions(),
        );
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRightMouseClick = (event: any) => {
    if (
      !(
        event.target.className.includes('studio-projects-folders--folder') ||
        event.target.className.includes('studio-project-item') ||
        event.target.parentElement.className.includes('studio-project-item')
      )
    ) {
      event.preventDefault();
      setIsContextMenuVisible(false);

      const contextMenuOverflowsYAxis = window.innerHeight - event.pageY <= 185; // 185 - context menu height
      const contextMenuOverflowsXAxis = window.innerWidth - event.pageX <= 110; // 110 - proper amount of pixels to fit screen

      const positionChange = {
        x: contextMenuOverflowsXAxis ? window.innerWidth - 200 : event.pageX,
        y: contextMenuOverflowsYAxis ? window.innerHeight - 115 : event.pageY,
      };

      setContextMenuXYPosition(positionChange);
      setIsContextMenuVisible(true);
    }
  };

  const onCreateBlankProject = (shouldCloseContextMenu = false) => {
    createProject()
      .then((newProject) => {
        setProjects((prevState) => [newProject, ...prevState]);

        if (shouldCloseContextMenu) {
          setIsContextMenuVisible(false);
        }
      })
      .catch((error) => {
        toast.error(
          error?.detail || 'Ooooops, error occurred while creating new project',
          getToasterOptions(),
        );
      });
  };

  const renderFolder = useCallback(
    (projectFolder: ProjectsFolderType, index: number) => {
      return (
        <ProjectsFolder
          key={projectFolder.id}
          projectFolder={projectFolder}
          id={projectFolder.id}
          onProjectMoveToFolder={onProjectMoveToFolder}
          onFolderOpen={onFolderOpen}
          isProjectDragging={isProjectDragging}
          onFolderRename={onFolderRename}
          onProjectFolderDuplicate={onProjectFolderDuplicate}
          onProjectFolderDelete={onProjectFolderDelete}
          index={index}
          moveFolder={moveFolder}
          checkIsFolderDragging={checkIsFolderDragging}
          isFolderDuplicateLoading={isFolderDuplicateLoading}
          rightClickedFolder={rightClickedFolder}
          onSelectRightClickedFolder={onSelectRightClickedFolder}
          isToolStudio={isToolStudio}
          isDarkMode={isDarkMode}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectFolders,
      isFolderDragging,
      isFolderDuplicateLoading,
      rightClickedFolder,
      isDarkMode,
    ],
  );

  useClickOutside(
    projectsContainerRef,
    () => {
      setIsContextMenuVisible(false);
    },
    'general-context-menu',
  );

  if (isFolderView) {
    return (
      <DndProvider backend={HTML5Backend}>
        <SingleFolder
          onBreadcrumbClick={onFolderClose}
          selectedFolder={selectedFolder}
          onProjectMoveToHome={onProjectMoveToHome}
          onFolderRename={onFolderRename}
          isToolStudio={isToolStudio}
          isDarkMode={isDarkMode}
        />
      </DndProvider>
    );
  }

  const isMultipleSelected = selectedProjects.length > 1;

  return (
    <div
      onContextMenu={handleRightMouseClick}
      ref={projectsContainerRef}
      className={cn('projects-content', {
        'w-full': !isToolStudio,
        'h-full': !isToolStudio,
      })}
    >
      <GeneralProjectsContextMenu
        position={contextMenuXYPosition}
        isVisible={isContextMenuVisible}
        handleAddNewFolder={handleAddNewFolder}
        onCreateBlankProject={onCreateBlankProject}
        isToolStudio={isToolStudio}
      />
      <Modal
        isOpen={deleteMultipleModalShown}
        ariaHideApp={false}
        onRequestClose={onMultipleDeletionModalClose}
        className="Modal__Content dark !max-w-[550px]"
        parentSelector={() => document.querySelector('#root') as HTMLElement}
      >
        <div className="studio-project-modal">
          <p className="studio-project-modal--label">
            Confirm deletion of {isMultipleSelected ? 'these' : 'this'}{' '}
            {isMultipleSelected ? 'designs' : 'design'}?
          </p>
          <p className="studio-project-modal--info">
            Are you sure you want to delete{' '}
            {isMultipleSelected ? 'these' : 'this'}{' '}
            {isMultipleSelected ? 'projects' : 'project'}?
          </p>
          <div className="studio-project-modal--btns-container">
            <button
              className="studio-project-modal--btns-container--btn cancel"
              type="button"
              onClick={onMultipleDeletionModalClose}
            >
              cancel
            </button>
            <button
              className="studio-project-modal--btns-container--btn submit"
              type="submit"
              onClick={onSelectedProjectsDelete}
            >
              delete
            </button>
          </div>
        </div>
      </Modal>
      <DndProvider backend={HTML5Backend}>
        {isToolStudio && (
          <span
            className="studio-content--label"
            onClick={() => handleAddNewFolder(false)}
          >
            <span>folders </span>
            <img
              src="/static/img/icons/white-bold-plus.svg"
              alt="add design"
              className="studio-content--plus-sign"
            />
          </span>
        )}
        {projectFolders?.length && (
          <div className="studio-projects-folders">
            {projectFolders?.map((projectFolder, index) =>
              renderFolder(projectFolder, index),
            )}
          </div>
        )}

        {isToolStudio && (
          <span
            className="studio-content--label"
            onClick={() => onCreateBlankProject(false)}
          >
            <span>projects </span>
            <img
              src="/static/img/icons/white-bold-plus.svg"
              alt="add design"
              className="studio-content--plus-sign"
            />
          </span>
        )}

        <div
          className="studio-content projects"
          id="scrollableDiv"
          style={{
            height: 750,
            overflow: 'auto',
            display: 'flex',
            width: '100%',
          }}
        >
          <InfiniteScroll
            // key={searchParams.get('sort')}
            key={projects.length.toString()}
            dataLength={projectsLength}
            next={() => retrieveProjects(true)}
            hasMore={projectsHasNext as unknown as boolean}
            loader={
              <h4 className="text-[#000000] dark:text-[color:#ffffff]">
                Loading...
              </h4>
            }
            scrollableTarget="scrollableDiv"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            {projects.map((project, index) => renderCard(project, index))}
          </InfiniteScroll>
        </div>
      </DndProvider>
    </div>
  );
};

export default ProjectsContent;
