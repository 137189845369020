import React from 'react';
import cn from 'classnames';

import '../../styles/unsupported-device-screen.scss';

const UnsupportedDeviceScreen = ({ additionalClass = '' }) => {
  return (
    <div className={cn('unsupported-device-container', additionalClass)}>
      <p className="unsupported-device-container--text-logo">coolab</p>
      <p className="unsupported-device-container--info">
        Coolab’s web app is only supported on desktop for now.
        <br />
        To access, please switch to a device with a bigger screen.
      </p>
    </div>
  );
};

export default UnsupportedDeviceScreen;
