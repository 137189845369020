export const convertImageUrlToFile = async (url: string, id: number) => {
  let imageFile;

  await fetch(url)
    .then((res) => res.blob())
    .then((myBlob) => {
      const myFile = new File([myBlob], `image-${id}.png`, {
        type: myBlob.type,
      });

      imageFile = myFile;
    });

  return imageFile;
};
