import React from 'react';

import Group from './Group';
import { useAppSelector } from '../../../helpers/hooks';
import { IGroups } from '../../../types/studio.types';

import '../../../styles/sidebar/gridContent/groups.scss';

export default ({
  entityId,
  groupParams = { elementsInRow: 2, backgroundCover: false },
}: IGroups) => {
  const selectedGroupId = useAppSelector(
    (state) => state.sidebarContent.entities[entityId]?.selectedGroupId || null,
  );

  const { groups = [] } =
    useAppSelector(
      (state) =>
        state.sidebarContent.entities[entityId]?.data.groups[
          selectedGroupId as number
        ],
    ) || {};

  return (
    <div className="AssetLibraryOverview__block">
      {groups.map((id) => (
        <Group
          key={`group_${id}`}
          entityId={entityId}
          id={id}
          groupParams={groupParams}
        />
      ))}
    </div>
  );
};
