import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const container = document.getElementById('root');

// ?: parsing the env vars inserted by the backend
// const envVars = JSON.parse(document.getElementById("env-vars").textContent);
// console.log(envVars);

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
}
