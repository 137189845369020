import React from 'react';
import cn from 'classnames';

// types
import { IReGenTopbar } from '../../../types/studio-re-gen';

// styles
import '../../../styles/studio/re-gen.scss';

const ReGenTopbar = ({ step }: IReGenTopbar) => {
  let topbarHeading = '';
  let topbarSubheading = '';
  let topbarHidden = false;

  switch (step) {
    case 0.5: {
      topbarHeading = 're-gen *beta';
      topbarSubheading = 'Generate a social post in seconds';

      break;
    }

    case 1: {
      topbarHeading = '1/ Pick a style';
      topbarSubheading =
        'Pick your inspo and our AI will use this as a base for your design.';

      break;
    }

    case 2: {
      topbarHeading = '2/ tell the ai what to write';
      topbarSubheading =
        'Describe who you’re making this for, the tone of voice, and what you’d like to say.';

      break;
    }

    case 2.5: {
      topbarHeading = 'loading';
      topbarSubheading = 're-gen is working';

      break;
    }

    case 3: {
      topbarHeading = '3/ pick your design(s)';
      topbarSubheading =
        'Select the generated options you’d like to save to your project folder, or download them directly.';

      break;
    }

    case 4: {
      topbarHeading = '4/ do you need design assistance';
      topbarSubheading =
        'An optional step: hire one of our creative designers to help you make modifications.';

      break;
    }

    case 5: {
      topbarHeading = 'complete!';
      topbarSubheading = 're-gen is complete!';

      break;
    }

    default: {
      topbarHeading = '';
      topbarSubheading = '';
      topbarHidden = true;
    }
  }

  return (
    <div
      className={cn('re-gen--topbar', {
        hidden: topbarHidden,
      })}
    >
      <h2 className="re-gen--topbar--heading">{topbarHeading}</h2>
      <p className="re-gen--topbar--subheading">{topbarSubheading}</p>
    </div>
  );
};

export default ReGenTopbar;
