import React, { useState } from 'react';
import Select from 'react-select';

// helpers
import { darkDropdownStyles } from '../../../helpers/common.data';

// styles
import '../../../styles/studio/studio-content.scss';

const ProjectsTopbar = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    string | undefined
  >('0');

  const dropdownOptions = [
    {
      value: '0',
      label: 'coming soon',
    },
  ];

  return (
    <div className="projects-topbar">
      <p className="projects-topbar--title">my projects</p>
      <div className="projects-topbar--workspace-container">
        <p className="projects-topbar--workspace-container--label">
          workstation:
        </p>
        <div className="projects-topbar--workspace-container--select-container">
          <Select
            placeholder="Choose workspace ..."
            styles={darkDropdownStyles()}
            isSearchable={false}
            options={dropdownOptions}
            defaultValue={dropdownOptions.find(
              (option) => option.value === selectedWorkspace,
            )}
            value={dropdownOptions.find(
              (option) => option.value === selectedWorkspace,
            )}
            onChange={(option) => setSelectedWorkspace(option?.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectsTopbar;
