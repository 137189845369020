export const customTextElements = [
  {
    id: 0,
    image: '/static/img/custom-text-elements/custom-text1.png',
    clipped: false,
    'dropShadow/clip': false,
    isFillEnabled: true,
    strokeColor: {
      a: 0.7200000286102295,
      b: 0.2880803644657135,
      g: 0.5179873704910278,
      r: 0.9958333373069763,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.724409580230713,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/poppins_bold_italic',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Poppins/Poppins-BoldItalic.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: { a: 1, b: 0, g: 0.9843137264251709, r: 1 },
  },
  {
    id: 1,
    image: '/static/img/custom-text-elements/custom-text2.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    dropShadowBlurRadiusX: 2.3622047901153564,
    dropShadowBlurRadiusY: 2.3622047901153564,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.3100000023841858,
      b: 0,
      g: 0,
      r: 0,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 5.785733190998975e-16,
    dropShadowOffsetY: 9.448819160461426,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/permanent_marker_regular',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Permanent_Marker/PermanentMarker-Regular.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 2,
    image: '/static/img/custom-text-elements/custom-text3.png',
    clipped: false,
    'dropShadow/clip': false,
    strokeColor: {
      a: 1,
      b: 1,
      g: 0,
      r: 0.679999828338623,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 9.448819160461426,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/bangers',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Bangers/Bangers-Regular.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 3,
    image: '/static/img/custom-text-elements/custom-text4.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/lobstertwo_bold_italic',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/LobsterTwo/LobsterTwo-BoldItalic.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0.014999999664723873,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.0602678582072258,
      g: 0.0602678582072258,
      r: 0.9375,
    },
  },
  {
    id: 4,
    image: '/static/img/custom-text-elements/custom-text5.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    dropShadowBlurRadiusX: 47.24409484863281,
    dropShadowBlurRadiusY: 47.24409484863281,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 1,
      g: 0.7599998712539673,
      r: 0,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    strokeColor: {
      a: 1,
      b: 0.5987856388092041,
      g: 0.5107142925262451,
      r: 1,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 7.086614608764648,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/bungee_inline',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_bungee_inline.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 1,
      g: 1,
      r: 1,
    },
  },
  {
    id: 5,
    image: '/static/img/custom-text-elements/custom-text6.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    strokeColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 2.3622047901153564,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/roboto_regular',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Roboto/Roboto-Regular.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 1,
      g: 1,
      r: 1,
    },
  },
  {
    id: 6,
    image: '/static/img/custom-text-elements/custom-text7.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    strokeColor: {
      a: 0.47999998927116394,
      b: 0.35357141494750977,
      g: 0.9668498039245605,
      r: 1,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 2.3622047901153564,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/elsie_swash_caps_black',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/ElsieSwashCaps/ElsieSwashCaps-Black.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.5992543697357178,
      g: 0.6499999761581421,
      r: 0.23678570985794067,
    },
  },
  {
    id: 7,
    image: '/static/img/custom-text-elements/custom-text8.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    dropShadowBlurRadiusX: 23.622047424316406,
    dropShadowBlurRadiusY: 23.622047424316406,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0.7333333492279053,
      g: 0.7333333492279053,
      r: 0.7333333492279053,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/caveat_regular',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Caveat/Caveat-Regular.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 8,
    image: '/static/img/custom-text-elements/custom-text9.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    dropShadowBlurRadiusX: 23.622047424316406,
    dropShadowBlurRadiusY: 23.622047424316406,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.07999999821186066,
      b: 0,
      g: 0,
      r: 0,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/monoton',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_monoton.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.2357291728258133,
      g: 0.7972166538238525,
      r: 0.9041666388511658,
    },
  },
  {
    id: 9,
    image: '/static/img/custom-text-elements/custom-text10.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 23.622047424316406,
    dropShadowBlurRadiusY: 23.622047424316406,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0.7333333492279053,
      g: 0.7333333492279053,
      r: 0.7333333492279053,
    },
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    isStrokeEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.3605952262878418,
      g: 0.9098095297813416,
      r: 0.9708333611488342,
    },
    strokeEnabled: true,
    strokeCornerGeometry: 'Miter',
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 2.3622047901153564,
    'text/horizontalAlignment': 'Left',
    'text/verticalAlignment': 'Top',
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/sancreek',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_sancreek.ttf',
    'text/text': 'Title',
    'text/fontSize': 32,
    textColor: {
      a: 1,
      b: 0,
      g: 0.29600000381469727,
      r: 0.6166666746139526,
    },
  },
  {
    id: 10,
    image: '/static/img/custom-text-elements/custom-text11.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    dropShadowBlurRadiusX: 47.24409484863281,
    dropShadowBlurRadiusY: 47.24409484863281,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.800000011920929,
      b: 0,
      g: 0,
      r: 1,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/bungee_inline',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_bungee_inline.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': -0.04500000178813934,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.2678571343421936,
      g: 0.2678571343421936,
      r: 1,
    },
  },
  {
    id: 11,
    image: '/static/img/custom-text-elements/custom-text12.png',
    clipped: false,
    isFillEnabled: true,
    'dropShadow/clip': false,
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    dropShadowBlurRadiusX: 94.48818969726562,
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    dropShadowBlurRadiusY: 94.48818969726562,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.49000000953674316,
      b: 0,
      g: 0,
      r: 0,
    },
    'dropShadow/enabled': true,
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/source_code_pro_regular',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/SourceCodePro/SourceCodePro-Regular.ttf',
    'text/fontSize': 32,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': -0.15000000596046448,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Title',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.0714285746216774,
      g: 1,
      r: 0.20142856240272522,
    },
  },
  {
    id: 12,
    image: '/static/img/custom-text-elements/custom-body-text-1.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: { a: 0.25, b: 0, g: 0, r: 0 },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.165143013000488,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'caviardreams_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/CaviarDreams_Q0fLocl.ttf`,
    'text/fontSize': 12.342708587646484,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text. ',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 13,
    image: '/static/img/custom-text-elements/custom-body-text-2.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.613060474395752,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'mrdafoe_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/MrDafoe-Regular_6euCK6k.ttf`,
    'text/fontSize': 13.67003345489502,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 14,
    image: '/static/img/custom-text-elements/custom-body-text-3.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.153653383255005,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'vanilla_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Vanilla_tGLuOwb.ttf`,
    'text/fontSize': 9.34532642364502,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 15,
    image: '/static/img/custom-text-elements/custom-body-text-4.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.023195266723633,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'love_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Love_hUbscBg.ttf`,
    'text/fontSize': 11.92206859588623,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.8659999966621399,
      g: 0.5214285850524902,
      r: 1,
    },
  },
  {
    id: 16,
    image: '/static/img/custom-text-elements/custom-body-text-5.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 0,
    dropShadowBlurRadiusY: 0,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0.2857142984867096,
      g: 0.2857142984867096,
      r: 1,
    },
    dropShadowOffsetX: 2.5054965019226074,
    dropShadowOffsetY: 2.5054965019226074,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 5.701683521270752,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'lobster_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Lobster-Regular_8ObQPEJ.ttf`,
    'text/fontSize': 16.895986557006836,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.862500011920929,
      g: 0.28339284658432007,
      r: 0.29497507214546204,
    },
  },
  {
    id: 17,
    image: '/static/img/custom-text-elements/custom-body-text-6.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.712378978729248,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/vt323',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_vt323.ttf',
    'text/fontSize': 13.964350700378418,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.04000002145767212,
      g: 1,
      r: 0,
    },
  },
  {
    id: 18,
    image: '/static/img/custom-text-elements/custom-body-text-7.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.4000000059604645,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 5.905511856079102,
    dropShadowOffsetY: 0,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.0977083370089531,
      g: 0.19861198961734772,
      r: 0.40833333134651184,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 1.1811023950576782,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/sancreek',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_sancreek.ttf',
    'text/fontSize': 14.304183959960938,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.17581845819950104,
      g: 0.3068423867225647,
      r: 0.5791666507720947,
    },
  },
  {
    id: 19,
    image: '/static/img/custom-text-elements/custom-body-text-8.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.5896830558776855,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'vanilla_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Vanilla_tGLuOwb.ttf`,
    'text/fontSize': 10.637434959411621,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.2964285612106323,
      g: 0.5249772667884827,
      r: 1,
    },
  },
  {
    id: 20,
    image: '/static/img/custom-text-elements/custom-body-text-9.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.165143013000488,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'caviardreams_bold_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/CaviarDreams-Bold.ttf`,
    'text/fontSize': 12.342708587646484,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
  },
  {
    id: 21,
    image: '/static/img/custom-text-elements/custom-body-text-10.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 35.43307113647461,
    dropShadowBlurRadiusY: 35.43307113647461,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.8399999737739563,
      b: 0.7520000338554382,
      g: 0.11428571492433548,
      r: 1,
    },
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.165143013000488,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'audiowide_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Audiowide-Regular_w0a4MTo.ttf`,
    'text/fontSize': 12.342708587646484,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.9228571057319641,
      g: 1,
      r: 0.3571428656578064,
    },
  },
  {
    id: 22,
    image: '/static/img/custom-text-elements/custom-body-text-11.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 47.24409484863281,
    dropShadowBlurRadiusY: 47.24409484863281,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0,
      g: 0.9487179517745972,
      r: 1,
    },
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.165143013000488,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'audiowide_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Audiowide-Regular_w0a4MTo.ttf`,
    'text/fontSize': 12.342708587646484,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 1,
      r: 0.37999993562698364,
    },
  },
  {
    id: 23,
    image: '/static/img/custom-text-elements/custom-body-text-12.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.42618727684021,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'homemadeapple_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/HomemadeApple-Regular_HZlLOwN.ttf`,
    'text/fontSize': 10.152937889099121,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 24,
    image: '/static/img/custom-text-elements/custom-body-text-13.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.42618727684021,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/poppins_light',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Poppins/Poppins-Light.ttf',
    'text/fontSize': 10.152937889099121,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 25,
    image: '/static/img/custom-text-elements/custom-body-text-14.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.42618727684021,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/manrope_light',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Manrope/Manrope-Light.ttf',
    'text/fontSize': 10.152937889099121,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0.18000000715255737,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 25,
    image: '/static/img/custom-text-elements/custom-body-text-15.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'baskervville_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Baskervville-Regular.ttf`,
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 26,
    image: '/static/img/custom-text-elements/custom-body-text-16.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/yeseva_one',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_yeseva_one.ttf',
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 27,
    image: '/static/img/custom-text-elements/custom-body-text-17.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 0,
    dropShadowBlurRadiusY: 0,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0,
      g: 0.9487179517745972,
      r: 1,
    },
    dropShadowOffsetX: 4.175827503204346,
    dropShadowOffsetY: 4.175827503204346,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'aabstractgroovy_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/aAbstractGroovy_iDkCQwL.ttf`,
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0.6599999666213989,
      r: 1,
    },
  },
  {
    id: 28,
    image: '/static/img/custom-text-elements/custom-body-text-18.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.336275815963745,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'ka1_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/ka1_DCAbucY.ttf`,
    'text/fontSize': 9.886496543884277,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 29,
    image: '/static/img/custom-text-elements/custom-body-text-19.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.709393501281738,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'hey_august_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Hey_August_x7USiXt.ttf`,
    'text/fontSize': 13.955498695373535,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 30,
    image: '/static/img/custom-text-elements/custom-body-text-20.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 35.43307113647461,
    dropShadowBlurRadiusY: 35.43307113647461,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0,
      g: 0.30000001192092896,
      r: 1,
    },
    dropShadowOffsetX: 0,
    dropShadowOffsetY: 0,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 4.042306423187256,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'linebeam_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Linebeam_s88hC0Y.ttf`,
    'text/fontSize': 11.97869873046875,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.7799999713897705,
      g: 0,
      r: 1,
    },
  },
  {
    id: 31,
    image: '/static/img/custom-text-elements/custom-body-text-21.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'chintzy_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/chintzy_k3Q9E7t.ttf`,
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0.19499999284744263,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 32,
    image: '/static/img/custom-text-elements/custom-body-text-22.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'poiretone_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/PoiretOne-Regular_EHFB7m8.ttf`,
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0.07500000298023224,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'body text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 33,
    image: '/static/img/custom-text-elements/custom-body-text-23.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0,
      g: 0.6000000238418579,
      r: 1,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.544600248336792,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'alba_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/ALBA_9aabzEm.ttf`,
    'text/fontSize': 14.601941108703613,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': -0.014999999664723873,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'body text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0.05999999865889549,
      r: 1,
    },
  },
  {
    id: 34,
    image: '/static/img/custom-text-elements/custom-body-text-24.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/sue_ellen_francisco',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/imgly_font_sue_ellen_francisco.ttf',
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 35,
    image: '/static/img/custom-text-elements/custom-body-text-25.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.283827781677246,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'bebasneue_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/BebasNeue-Regular_iAUQHGr.ttf`,
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 36,
    image: '/static/img/custom-text-elements/custom-body-text-26.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': true,
    dropShadowBlurRadiusX: 0,
    dropShadowBlurRadiusY: 0,
    dropShadowClip: false,
    dropShadowColor: {
      a: 1,
      b: 0.20000000298023224,
      g: 1,
      r: 0.4560000002384186,
    },
    dropShadowOffsetX: 2.5054965019226074,
    dropShadowOffsetY: 2.5054965019226074,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 5.042211055755615,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'nuku1_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/nuku1_IlU7iXo.ttf`,
    'text/fontSize': 14.941755294799805,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0.08297619223594666,
      g: 0.6833333373069763,
      r: 0.13100478053092957,
    },
  },
  {
    id: 37,
    image: '/static/img/custom-text-elements/custom-body-text-27.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: false,
    strokeColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: true,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 1.1811023950576782,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': '//ly.img.cesdk.fonts/montserrat_bold',
    'text/fontFileUri':
      '/extensions/ly.img.cesdk.fonts/fonts/Montserrat/Montserrat-Bold.ttf',
    'text/fontSize': 9.73107624053955,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
  {
    id: 38,
    image: '/static/img/custom-text-elements/custom-body-text-28.png',
    clipped: false,
    'dropShadow/clip': false,
    'dropShadow/enabled': false,
    dropShadowBlurRadiusX: 11.811023712158203,
    dropShadowBlurRadiusY: 11.811023712158203,
    dropShadowClip: false,
    dropShadowColor: {
      a: 0.25,
      b: 0,
      g: 0,
      r: 0,
    },
    dropShadowOffsetX: 20.87913703918457,
    dropShadowOffsetY: 20.87913703918457,
    isFillEnabled: true,
    strokeColor: {
      a: 1,
      b: 0.6700000166893005,
      g: 0.6700000166893005,
      r: 0.6700000166893005,
    },
    strokeCornerGeometry: 'Miter',
    strokeEnabled: false,
    strokePosition: 'Center',
    strokeStyle: 'Solid',
    strokeWidth: 3.42618727684021,
    'text/automaticFontSizeEnabled': false,
    'text/clipLinesOutsideOfFrame': false,
    'text/externalReference': 'devonshire_normal_normal',
    'text/fontFileUri': `https://storage.googleapis.com/${process.env.MEDIA_FOLDER_BUCKET_NAME}/media/upc_creativeeditorfont/Devonshire-Regular.ttf`,
    'text/fontSize': 10.152937889099121,
    'text/horizontalAlignment': 'Left',
    'text/letterSpacing': 0.15000000596046448,
    'text/lineHeight': 1,
    'text/maxAutomaticFontSize': -1,
    'text/minAutomaticFontSize': -1,
    'text/paragraphSpacing': 0,
    'text/text': 'Body Text',
    'text/verticalAlignment': 'Top',
    textColor: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
  },
];
