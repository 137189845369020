import React from 'react';

const ReGenIcon = ({ fill = 'white' }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5942 5.59385L10.8466 3.93561L9.18836 3.18804L10.8466 2.44047L11.5942 0.782227L12.3418 2.44047L14 3.18804L12.3418 3.93561L11.5942 5.59385ZM11.5942 15.2172L10.8466 13.559L9.18836 12.8114L10.8466 12.0638L11.5942 10.4056L12.3418 12.0638L14 12.8114L12.3418 13.559L11.5942 15.2172ZM4.79802 12.7978L3.30289 9.49485L0 7.99972L3.30289 6.50458L4.79802 3.20168L6.29317 6.50458L9.59606 7.99972L6.29317 9.49485L4.79802 12.7978ZM4.79802 10.2261L5.50482 8.70652L7.02445 7.99972L5.50482 7.29292L4.79802 5.77329L4.09122 7.29292L2.57159 7.99972L4.09122 8.70652L4.79802 10.2261Z"
        fill={fill}
        fillOpacity="0.9"
      />
    </svg>
  );
};

export default ReGenIcon;
