import React from 'react';

import { ISidebarCloseButton } from '../../types/studio.types';

import '../../styles/sidebar/close-button.scss';

export default ({ handleClick = () => {} }: ISidebarCloseButton) => (
  <button type="button" className="Button__block" onClick={handleClick}>
    <span>
      <img src="/static/img/icons/editor/close.svg" alt="close" />
    </span>
  </button>
);
