import React from 'react';

const ProjectBrandRoomIcon = ({ fill = '#484848' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.73193 12.6521V10.8743H8.95416V12.6521H2.73193ZM2.73193 9.09652V7.31874H12.5097V9.09652H2.73193ZM2.73193 5.54096V3.76318H12.5097V5.54096H2.73193ZM10.7319 17.9854V15.2521L15.643 10.3632C15.7764 10.2299 15.9245 10.1336 16.0875 10.0743C16.2505 10.015 16.4134 9.98541 16.5764 9.98541C16.7542 9.98541 16.9245 10.0187 17.0875 10.0854C17.2505 10.1521 17.3986 10.2521 17.5319 10.3854L18.3542 11.2076C18.4727 11.341 18.5653 11.4891 18.6319 11.6521C18.6986 11.815 18.7319 11.978 18.7319 12.141C18.7319 12.3039 18.7023 12.4706 18.643 12.641C18.5838 12.8113 18.4875 12.9632 18.3542 13.0965L13.4653 17.9854H10.7319ZM12.0653 16.6521H12.9097L15.5986 13.941L15.1986 13.5187L14.7764 13.1187L12.0653 15.8076V16.6521ZM15.1986 13.5187L14.7764 13.1187L15.5986 13.941L15.1986 13.5187Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectBrandRoomIcon;
