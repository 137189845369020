import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

// components
import Toggle from '../../../elements/Toggle';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// api
import { getPageFormats } from '../../../api/assets';
import { createMoreFormatsDesignAssistance } from '../../../api/helpers';

// types
import {
  IPageFormatOption,
  PageFormat,
} from '../../../types/studio-format-extender.types';
import { InputChangeEventType } from '../../../types/common.types';
import { ToggleOptionType } from '../../../types/elements.types';

// styles
import '../../../styles/studio/re-gen.scss';

const PageFormatOption = ({
  pageFormat,
  onPageFormatSelect,
}: IPageFormatOption) => {
  return (
    <div className="formats-container--dropdown--formats--option">
      <img
        src={pageFormat.originalLogo}
        alt="format icon"
        className="formats-container--dropdown--formats--option--icon"
      />{' '}
      <p className="formats-container--dropdown--formats--option--name">
        {pageFormat.name}
      </p>{' '}
      <input
        type="checkbox"
        className="formats-container--dropdown--formats--option--checkbox"
        onChange={(event) => onPageFormatSelect(pageFormat, event)}
      />
    </div>
  );
};

const CreateMoreFormatsOption = () => {
  const [selectedPageFormats, setSelectedPageFormats] = useState<PageFormat[]>(
    [],
  );
  const [pageFormats, setPageFormats] = useState<PageFormat[]>([]);
  const [isBrandAssetsAdded, setIsBrandAssetsAdded] =
    useState<ToggleOptionType | null>(null);
  const [addAssetInsteadOfReplacing, setAddAssetInsteadOfReplacing] =
    useState<ToggleOptionType | null>(null);
  const [pageFormatSearch, setPageFormatSearch] = useState('');

  useEffect(() => {
    getPageFormats()
      .then((innerPageFormats) => {
        setPageFormats(innerPageFormats);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with page formats',
          getToasterOptions(),
        );
      });
  }, []);

  const onPageFormatSelect = (
    pageFormat: PageFormat,
    event: InputChangeEventType,
  ) => {
    const updatedData = event.target.checked
      ? [...selectedPageFormats, pageFormat]
      : selectedPageFormats.filter(
          (innerPageFormat: PageFormat) => innerPageFormat.id !== pageFormat.id,
        );

    setSelectedPageFormats(updatedData);
  };

  const onToggleBrandAssetAdded = (selectedOption: ToggleOptionType) => {
    setIsBrandAssetsAdded(selectedOption);
  };

  const onToggleAddAssetInsteadOfReplacing = (
    selectedOption: ToggleOptionType,
  ) => {
    setAddAssetInsteadOfReplacing(selectedOption);
  };

  const onPageFormatChange = (e: InputChangeEventType) => {
    setPageFormatSearch(e.target.value);
  };

  const onProceedToBilling = () => {
    createMoreFormatsDesignAssistance({
      brandAssets: isBrandAssetsAdded?.value,
      addOrChangeAsset: addAssetInsteadOfReplacing?.value,
      formats: selectedPageFormats.map((pageFormat) => pageFormat.id),
    }).then((resp) => {
      console.log(resp);
    });
  };

  const isProceedDisabled =
    !selectedPageFormats.length ||
    !isBrandAssetsAdded ||
    !addAssetInsteadOfReplacing;

  return (
    <div>
      <p className="design-assistance-label">
        Which formats would you like to order?
      </p>
      <div className="design-assistance-formats-container">
        <div className="formats-container--dropdown">
          <div className="formats-container--dropdown--input-container">
            <input
              type="text"
              className="formats-container--dropdown--input-container--input"
              placeholder="Search"
              value={pageFormatSearch}
              onChange={onPageFormatChange}
            />
            <img
              src="/static/img/icons/search.svg"
              alt="search"
              className="formats-container--dropdown--input-container--icon"
            />
          </div>
          <div className="formats-container--dropdown--formats max-h-200">
            {pageFormats
              .filter((format) =>
                format.name
                  .toLowerCase()
                  .includes(pageFormatSearch.toLowerCase()),
              )
              .map((pageFormat) => (
                <PageFormatOption
                  key={pageFormat.id}
                  pageFormat={pageFormat}
                  onPageFormatSelect={onPageFormatSelect}
                />
              ))}
          </div>
        </div>

        <div className="selected-formats-container max-h-245">
          {selectedPageFormats.map((selectedPageFormat) => {
            return (
              <div
                key={selectedPageFormat.id}
                className="selected-formats-container--item"
              >
                <img
                  src={selectedPageFormat.originalLogo}
                  alt="format icon"
                  className="selected-formats-container--item--icon"
                />
                <p className="selected-formats-container--item--name">
                  {selectedPageFormat.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <p className="design-assistance-label">
        Would you like brand assets added to the design?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={isBrandAssetsAdded}
          toggleHandler={onToggleBrandAssetAdded}
        />
      </div>

      <p className="design-assistance-label">
        Would you like to add an asset or change an existing asset?
      </p>
      <div className="design-assistance-toggle-container">
        <Toggle
          options={[
            { id: 0, label: 'no', value: false },
            { id: 1, label: 'yes', value: true },
          ]}
          selectedOption={addAssetInsteadOfReplacing}
          toggleHandler={onToggleAddAssetInsteadOfReplacing}
        />
      </div>
      <div className="design-assistance-conclusion">
        <p className="design-assistance-conclusion--total">total</p>
        <p className="design-assistance-conclusion--price">$100</p>
        <button
          type="button"
          className={cn('design-assistance-conclusion--proceed-billing', {
            disabled: isProceedDisabled,
          })}
          disabled={isProceedDisabled}
          onClick={onProceedToBilling}
        >
          Proceed to billing
        </button>
      </div>
    </div>
  );
};

export default CreateMoreFormatsOption;
