import fetch from '../helpers/customFetch';

const createUploadedImage = (data) => {
  try {
    return fetch({
      url: '/user-uploaded-images/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const getAllUploadedImages = (query = '', page = 1, perPage = 10) => {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.set('search', query);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (perPage) {
    searchParams.set('page_size', perPage);
  }

  try {
    return fetch({
      url: `/user-uploaded-images/?${searchParams.toString()}`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const deleteUploadedImageById = (id) => {
  try {
    return fetch({
      url: `/user-uploaded-images/${id}/`,
      method: 'DELETE',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export { createUploadedImage, getAllUploadedImages, deleteUploadedImageById };
