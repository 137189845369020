import React from 'react';
import '@google/model-viewer';

import { useAppSelector } from '../../helpers/hooks';

import '../../styles/sandbox/three-d-scene.scss';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface ModelViewerAttributes {
      src: string;
      alt?: string;
      ar: boolean;
      poster: string;
    }
    interface IntrinsicElements {
      'model-viewer': ModelViewerAttributes;
    }
  }
}

const ThreeDScene = () => {
  const data = useAppSelector((state) => state.sandbox);

  return (
    <div className="three-d-scene-container">
      <model-viewer
        src={data.src || ''}
        alt={data.alt || ''}
        ar
        environment-image={data.envImg}
        poster={data.poster || ''}
        shadow-intensity="1"
        camera-controls
        touch-action="pan-y"
      />
    </div>
  );
};

export default ThreeDScene;
