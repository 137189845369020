import React from 'react';

const ProjectBrandRoomIcon = ({ fill = '#484848' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42307 19.1922L2.58655 8.19231H19.4134L17.5769 19.1922H4.42307ZM5.67497 17.6923H16.325L17.6 9.69226H4.39997L5.67497 17.6923ZM8.99997 13.4423H13C13.2125 13.4423 13.3906 13.3704 13.5343 13.2265C13.6781 13.0827 13.7499 12.9045 13.7499 12.6919C13.7499 12.4793 13.6781 12.3012 13.5343 12.1577C13.3906 12.0141 13.2125 11.9423 13 11.9423H8.99997C8.78747 11.9423 8.60936 12.0142 8.46562 12.158C8.32187 12.3018 8.25 12.48 8.25 12.6926C8.25 12.9052 8.32187 13.0833 8.46562 13.2269C8.60936 13.3705 8.78747 13.4423 8.99997 13.4423ZM4.99997 6.99999C4.78747 6.99999 4.60936 6.92808 4.46562 6.78426C4.32187 6.64046 4.25 6.46226 4.25 6.24966C4.25 6.03708 4.32187 5.859 4.46562 5.71541C4.60936 5.57181 4.78747 5.50001 4.99997 5.50001H17C17.2125 5.50001 17.3906 5.57192 17.5343 5.71574C17.6781 5.85956 17.7499 6.03776 17.7499 6.25034C17.7499 6.46294 17.6781 6.64103 17.5343 6.78461C17.3906 6.9282 17.2125 6.99999 17 6.99999H4.99997ZM6.99997 4.30771C6.78747 4.30771 6.60936 4.23581 6.46562 4.09199C6.32187 3.94817 6.25 3.76997 6.25 3.55739C6.25 3.34479 6.32187 3.1667 6.46562 3.02311C6.60936 2.87953 6.78747 2.80774 6.99997 2.80774H15C15.2125 2.80774 15.3906 2.87965 15.5343 3.02346C15.6781 3.16726 15.7499 3.34546 15.7499 3.55806C15.7499 3.77065 15.6781 3.94873 15.5343 4.09231C15.3906 4.23591 15.2125 4.30771 15 4.30771H6.99997Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectBrandRoomIcon;
