import React from 'react';

const ProjectsFolderIcon = ({ fill = '#484848' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01579 16.875C3.59474 16.875 3.23684 16.7314 2.94211 16.4442C2.64737 16.1571 2.5 15.8083 2.5 15.3981V6.37244C2.5 5.9485 2.64737 5.59295 2.94211 5.30577C3.23684 5.01859 3.59474 4.875 4.01579 4.875H9.13158L10.5 6.20833H16.9842C17.4053 6.20833 17.7632 6.35192 18.0579 6.6391C18.3526 6.92628 18.5 7.275 18.5 7.68526V15.3981C18.5 15.8083 18.3526 16.1571 18.0579 16.4442C17.7632 16.7314 17.4053 16.875 16.9842 16.875H4.01579ZM3.76316 6.37244V15.3981C3.76316 15.4665 3.78786 15.5247 3.83726 15.5728C3.88611 15.6204 3.94561 15.6442 4.01579 15.6442H16.9842C17.0544 15.6442 17.1142 15.6204 17.1636 15.5728C17.2124 15.5247 17.2368 15.4665 17.2368 15.3981V7.68526C17.2368 7.61688 17.2124 7.55862 17.1636 7.51049C17.1142 7.4629 17.0544 7.4391 16.9842 7.4391H9.97368L8.62632 6.10577H4.01579C3.94561 6.10577 3.88611 6.12984 3.83726 6.17797C3.78786 6.22556 3.76316 6.29038 3.76316 6.37244ZM3.76316 6.37244V6.10577V15.6442V15.3981V6.37244Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectsFolderIcon;
