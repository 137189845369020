import fetch from '../helpers/customFetch';

const getDigitalGoodById = (id) => {
  try {
    return fetch({
      url: `/digital-good/user-digital-good/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const partialUpdateDigitalGoodById = (id, data) => {
  try {
    return fetch({
      url: `/digital-good/user-digital-good/${id}/`,
      method: 'PATCH',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const createDigitalGood = (data) => {
  try {
    return fetch({
      url: '/digital-good/user-digital-good/',
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const getDigitalGoodTemplateById = (id) => {
  try {
    return fetch({
      url: `/digital-good/digital-good-template/${id}/`,
      method: 'GET',
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

const randomizeTemplate = (data) => {
  try {
    return fetch({
      url: `helpers/pick-template/`,
      method: 'POST',
      data,
    });
  } catch (err) {
    if (err && err.data) {
      throw err.data;
    }
    throw err;
  }
};

export {
  getDigitalGoodById,
  partialUpdateDigitalGoodById,
  createDigitalGood,
  getDigitalGoodTemplateById,
  randomizeTemplate,
};
