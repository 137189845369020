import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

// components
import ProjectsHeader from './ProjectsHeader';

// helpers
import { getToasterOptions } from '../../helpers/toaster';

// api
import { getBrandRoom, updateBrandRoom } from '../../api/brand-room';
import { getCustomFonts } from '../../api/assets';

// types
import {
  BrandRoomType,
  IProjectsTabContent,
  UploadedFontType,
} from '../../types/studio.types';

// styles
import '../../styles/projects/projects-brand-room.scss';
import '../../styles/elements/toaster.scss';

interface IBrandNameForm {
  brandName: string;
}

interface IBrandDescriptionForm {
  brandDescription: string;
}

const ProjectsBrandRoomSetupTab = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [isBrandRoomSetupModalOpen, setIsBrandRoomSetupModalOpen] =
    useState(false);
  const [isBrandRoomTextStylesModalOpen, setIsBrandRoomTextStylesModalOpen] =
    useState(false);
  const [isBrandRoomLogoModalOpen, setIsBrandRoomLogoModalOpen] =
    useState(false);
  const [isBrandRoomDescriptionModalOpen, setIsBrandRoomDescriptionModalOpen] =
    useState(false);
  const [isDeleteFontModalVisible, setIsDeleteFontModalVisible] =
    useState(false);
  const [brandRoomInfo, setBrandRoomInfo] = useState<null | BrandRoomType>();
  const [fonts, setFonts] = useState<UploadedFontType[] | null>(null);
  const [fontFilterValue, setFontFilterValue] = useState('');
  const [selectedFonts, setSelectedFonts] = useState<UploadedFontType[] | null>(
    null,
  );

  // brand-name form
  const {
    watch: firstFormWatch,
    register: firstFormRegister,
    setValue: firstFormSetValue,
    handleSubmit: firstFormHandleSubmit,
  } = useForm<IBrandNameForm>();

  // brand-description form
  const {
    watch: secondFormWatch,
    register: secondFormRegister,
    setValue: secondFormSetValue,
    handleSubmit: secondFormHandleSubmit,
  } = useForm<IBrandDescriptionForm>();

  const openBrandRoomSetupModal = () => {
    setIsBrandRoomSetupModalOpen(true);
  };

  const closeBrandRoomSetupModal = () => {
    setIsBrandRoomSetupModalOpen(false);
  };

  const openBrandRoomTextStylesModal = () => {
    setIsBrandRoomTextStylesModalOpen(true);
  };

  const closeBrandRoomTextStylesModal = () => {
    setIsBrandRoomTextStylesModalOpen(false);
  };

  const openBrandRoomLogoModal = () => {
    setIsBrandRoomLogoModalOpen(true);
  };

  const closeBrandRoomLogoModal = () => {
    setIsBrandRoomLogoModalOpen(false);
  };

  const openBrandRoomDescriptionModal = () => {
    setIsBrandRoomDescriptionModalOpen(true);
  };

  const closeBrandRoomDescriptionModal = () => {
    setIsBrandRoomDescriptionModalOpen(false);
  };

  const openDeleteFontModal = () => {
    setIsDeleteFontModalVisible(true);
  };

  const closeDeleteFontModal = () => {
    setIsDeleteFontModalVisible(false);
  };

  const onFontFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontFilterValue(e.target.value);
  };

  const handleSubmitBrandNameForm = (data: IBrandNameForm) => {
    const formData = new FormData();

    formData.append('brand_name', data.brandName);

    updateBrandRoom(formData)
      .then((resp) => {
        closeBrandRoomSetupModal();
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const handleSubmitBrandDescriptionForm = (data: IBrandDescriptionForm) => {
    const formData = new FormData();

    formData.append('info', data.brandDescription);

    updateBrandRoom(formData)
      .then((resp) => {
        closeBrandRoomDescriptionModal();
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageUpload = (file: any) => {
    const formData = new FormData();

    formData.append('logo', file[0]);

    toast.info('Uploading logo');

    updateBrandRoom(formData)
      .then((resp) => {
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const onImageDelete = () => {
    const formData = new FormData();

    formData.append('logo', '');

    updateBrandRoom(formData)
      .then((resp) => {
        setBrandRoomInfo(resp);
        toast.success('Data saved', getToasterOptions());
      })
      .catch(() => {
        toast.error('Could not process data', getToasterOptions());
      });
  };

  const onFontSelect = (font: UploadedFontType) => {
    setSelectedFonts((prevState) => {
      const isFontAlreadySelected = prevState
        ?.map((innerSelectedFont) => innerSelectedFont.slug)
        .includes(font.slug);
      return prevState && !isFontAlreadySelected
        ? [...prevState, font]
        : [font];
    });
  };

  const onRemoveFontFromFavorites = (font: UploadedFontType) => {
    setSelectedFonts((prevState) => {
      const filteredSelectedFonts = prevState?.filter(
        (innerSelectedFont) => innerSelectedFont.slug !== font.slug,
      );

      return filteredSelectedFonts?.length ? filteredSelectedFonts : null;
    });
    closeDeleteFontModal();
  };

  useEffect(() => {
    getBrandRoom().then((res) => {
      setBrandRoomInfo(res);
      firstFormSetValue('brandName', res.brandName);
      secondFormSetValue('brandDescription', res.info);
      setSelectedFonts(res.titleTextFonts);
    });

    getCustomFonts().then((resp) => {
      setFonts(resp);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredFonts = fonts?.filter((font) =>
    font.fontFamily
      .toLocaleLowerCase()
      .includes(fontFilterValue.toLocaleLowerCase()),
  );

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Brand Room"
        isNewDesignBtnVisible={false}
      />
      <div className="projects-tab-content--body">
        <div className="brand-room-container">
          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomSetupModal}
            >
              brand name
              {brandRoomInfo?.brandName && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomSetupModalOpen}
              ariaHideApp={false}
              onRequestClose={closeBrandRoomSetupModal}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <form
                  action="submit"
                  onSubmit={firstFormHandleSubmit(handleSubmitBrandNameForm)}
                  className="brand-room-modal--content form"
                >
                  <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                    Choose a brand name
                  </p>
                  <input
                    type="text"
                    className="brand-room-modal--content--input dark:!bg-[#2d2d2d] dark:!border-[#212121]"
                    placeholder="Enter your brand name"
                    {...firstFormRegister('brandName')}
                  />
                  <div className="brand-room-modal--content--btns-container">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                      type="button"
                      onClick={closeBrandRoomSetupModal}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit',
                        {
                          disabled: !firstFormWatch('brandName'),
                          'dark-submit':
                            isDarkMode && firstFormWatch('brandName'),
                          'dark-disabled':
                            isDarkMode && !firstFormWatch('brandName'),
                        },
                      )}
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomTextStylesModal}
            >
              text styles
              {!!brandRoomInfo?.titleTextFonts.length && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomTextStylesModalOpen}
              ariaHideApp={false}
              onRequestClose={closeBrandRoomTextStylesModal}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                  Choose a title text
                </p>
                <div className="brand-room-modal--content--fonts-container">
                  <div className="w-1/2 flex flex-col">
                    <div className="relative">
                      <input
                        type="text"
                        value={fontFilterValue}
                        onChange={onFontFilterValueChange}
                        className="w-full p-2 font-fam-open-sans text-sm leading-[24px] outline-none border-[1px] border-solid border-[#BCBCBC] pl-[40px] rounded-[4px_4px_0_0]
                        placeholder:text-[#888888] placeholder:font-fam-courier-prime placeholder:text-sm placeholder:leading-[24px]
                        dark:border-[#888888] dark:outline-none dark:placeholder:text-[color:#888888] dark:focus:border-[#EEEEEE] dark:focus:bg-[color:#2D2D2D]
                        dark:focus:text-[color:#ffffff] dark:focus:placeholder:text-[color:#ffffff] dark:hover:bg-[color:#363636] dark:bg-[color:#2D2D2D] dark:text-[#eeeeee]
                        "
                        placeholder="Search"
                      />
                      <img
                        src={`/static/img/icons/search-${
                          isDarkMode ? 'light' : 'dark'
                        }.svg`}
                        alt="search"
                        className="absolute top-[10px] left-[10px]"
                      />
                    </div>
                    <div className="border-[1px] border-solid border-[#BCBCBC] border-t-0 rounded-[0_0_4px_4px] px-[24px]">
                      {filteredFonts?.length ? (
                        filteredFonts.map((font) => {
                          return (
                            <p
                              className={cn(
                                'brand-room-modal--content--fonts-container--font',
                                {
                                  dark: isDarkMode,
                                },
                              )}
                              key={font.slug}
                              onClick={() => onFontSelect(font)}
                            >
                              {font.fontFamily}
                            </p>
                          );
                        })
                      ) : (
                        <p className="brand-room-modal--content--fonts-container--no-templates">
                          No Fonts
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2 flex flex-col">
                    <p className="brand-room-modal--content--fonts-container--title dark:!text-[#eee]">
                      Your fonts
                    </p>
                    <div>
                      {selectedFonts?.map((selectedFont) => {
                        return (
                          <div
                            className="flex justify-between items-center"
                            key={selectedFont.slug}
                          >
                            <p
                              className={cn(
                                'brand-room-modal--content--fonts-container--font',
                                {
                                  dark: isDarkMode,
                                },
                              )}
                            >
                              {selectedFont.fontFamily}
                            </p>
                            <img
                              src="/static/img/icons/cross.svg"
                              alt="cross"
                              className="cursor-pointer"
                              onClick={openDeleteFontModal}
                            />
                            <Modal
                              isOpen={isDeleteFontModalVisible}
                              ariaHideApp={false}
                              onRequestClose={closeDeleteFontModal}
                              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                                isDarkMode ? 'dark' : ''
                              }`}
                              parentSelector={() =>
                                document.querySelector('#root') as HTMLElement
                              }
                            >
                              <div className="brand-room-modal--content">
                                <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                                  delete this font?
                                </p>

                                <p className="brand-room-modal--content--hint dark:!text-[#eeeeee]">
                                  Are you sure you want to delete this font?
                                </p>

                                <div className="brand-room-modal--content--btns-container">
                                  <div className="flex gap-2">
                                    <button
                                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                                      type="button"
                                      onClick={closeDeleteFontModal}
                                    >
                                      cancel
                                    </button>
                                    <button
                                      className={cn(
                                        'brand-room-modal--content--btns-container--submit',
                                        {
                                          'dark-submit': isDarkMode,
                                        },
                                      )}
                                      type="button"
                                      onClick={() =>
                                        onRemoveFontFromFavorites(selectedFont)
                                      }
                                    >
                                      delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="brand-room-modal--content--btns-container !justify-between">
                  <button
                    className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                    type="button"
                  >
                    upload
                  </button>
                  <div className="flex gap-4">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                      type="button"
                      onClick={closeBrandRoomTextStylesModal}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit',
                        {
                          disabled: !selectedFonts?.length,
                          'dark-submit': isDarkMode && selectedFonts?.length,
                          'dark-disabled': isDarkMode && !selectedFonts?.length,
                        },
                      )}
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomLogoModal}
            >
              logo
              {brandRoomInfo?.logo && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomLogoModalOpen}
              ariaHideApp={false}
              onRequestClose={closeBrandRoomLogoModal}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                  upload brand logo
                </p>
                {brandRoomInfo?.logo ? (
                  <img
                    src={brandRoomInfo?.logo}
                    alt="logo"
                    className="brand-room-modal--content--logo"
                  />
                ) : (
                  <Dropzone
                    onDrop={(acceptedFiles) => onImageUpload(acceptedFiles)}
                  >
                    {({
                      getRootProps: getUploadReplacementRootProps,
                      getInputProps: getUploadReplacementInputProps,
                    }) => (
                      <div
                        className="brand-room-modal--content--image-dropzone"
                        {...getUploadReplacementRootProps()}
                      >
                        <input {...getUploadReplacementInputProps()} />
                        <div className="flex flex-col">
                          <button
                            type="button"
                            className="brand-room-modal--content--image-dropzone--btn"
                          >
                            choose a file or drag it here
                          </button>
                          <p className="brand-room-modal--content--image-dropzone--hint">
                            Accepts .gif, .jpg, and .png
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
                <div className="brand-room-modal--content--btns-container">
                  {!brandRoomInfo?.logo && (
                    <button
                      className="brand-room-modal--content--btns-container--outlined-btn"
                      type="button"
                      onClick={closeBrandRoomLogoModal}
                    >
                      go back
                    </button>
                  )}

                  {brandRoomInfo?.logo && (
                    <div className="flex gap-2">
                      <button
                        className="brand-room-modal--content--btns-container--outlined-btn"
                        type="button"
                        onClick={onImageDelete}
                      >
                        delete
                      </button>
                      <Dropzone
                        onDrop={(acceptedFiles) => onImageUpload(acceptedFiles)}
                      >
                        {({
                          getRootProps: getUploadReplacementRootProps,
                          getInputProps: getUploadReplacementInputProps,
                        }) => (
                          <button
                            className="brand-room-modal--content--btns-container--outlined-btn"
                            type="button"
                            {...getUploadReplacementRootProps()}
                          >
                            <input {...getUploadReplacementInputProps()} />
                            replace
                          </button>
                        )}
                      </Dropzone>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>

          <div className="brand-room-container--card-container">
            <div
              className="brand-room-container--card-container--field-card dark:!bg-[#000] dark:!text-[#eee] dark:!border-[#212121] dark:hover:!bg-[#191919]"
              id="context-menu-brand-name"
              onClick={openBrandRoomDescriptionModal}
            >
              brand description
              {brandRoomInfo?.info && (
                <img
                  src={`/static/img/icons/small-check-circle${
                    isDarkMode ? '-white' : ''
                  }.svg`}
                  alt="checkmark"
                />
              )}
            </div>
            <Modal
              isOpen={isBrandRoomDescriptionModalOpen}
              ariaHideApp={false}
              onRequestClose={closeBrandRoomDescriptionModal}
              className={`Modal__Content brand-room-modal dark:!bg-[#000] ${
                isDarkMode ? 'dark' : ''
              }`}
              parentSelector={() =>
                document.querySelector('#root') as HTMLElement
              }
            >
              <div className="brand-room-modal--content">
                <form
                  action="submit"
                  onSubmit={secondFormHandleSubmit(
                    handleSubmitBrandDescriptionForm,
                  )}
                  className="brand-room-modal--content form"
                >
                  <p className="brand-room-modal--content--title dark:!text-[#eeeeee]">
                    describe your brand
                  </p>
                  <textarea
                    cols={5}
                    rows={5}
                    className="brand-room-modal--content--textarea dark:!bg-[#2d2d2d] dark:!border-[#212121]"
                    maxLength={1000}
                    placeholder="What does your brand do? What are its values? "
                    {...secondFormRegister('brandDescription')}
                  />
                  <div className="brand-room-modal--content--btns-container">
                    <button
                      className="brand-room-modal--content--btns-container--cancel dark:!text-[#fff]"
                      type="button"
                      onClick={closeBrandRoomDescriptionModal}
                    >
                      cancel
                    </button>
                    <button
                      className={cn(
                        'brand-room-modal--content--btns-container--submit',
                        {
                          disabled: !secondFormWatch('brandDescription'),
                          'dark-submit':
                            isDarkMode && secondFormWatch('brandDescription'),
                          'dark-disabled':
                            isDarkMode && !secondFormWatch('brandDescription'),
                        },
                      )}
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsBrandRoomSetupTab;
