// api
import {
  getCustomImages,
  getCustomShapes,
  getCustomStickers,
  getCustomTemplates,
  getCustomPhotos,
  getSystemImages,
} from '../../api/assets';
import { getAllUploadedImages } from '../../api/userUploadedImages';

// helpers
import { ASSETS_PAGE_SIZE, customAssetSourceId } from '../data/canvasConstants';
import { retrieveImageAssetCategoryName } from '../scene.helpers';

export const translateToAssetResult = (image, sectionInfo) => {
  const isCustomShapesSection =
    sectionInfo.sourceId === customAssetSourceId.shapes;
  const isCustomTemplatesSection =
    sectionInfo.sourceId === customAssetSourceId.templates;

  return {
    id: isCustomShapesSection
      ? `//ly.img.cesdk.vectorpaths/${image.id}`
      : image.id.toString(),
    locale: 'en',
    // hid text which appears on image, templates etc hover in cesdk sidebar
    label: isCustomShapesSection ? image.name : undefined,
    meta: {
      blockType: isCustomShapesSection
        ? '//ly.img.ubq/graphic'
        : sectionInfo.blockType,
      ...(isCustomShapesSection
        ? {
            vectorPath: isCustomShapesSection ? image.vectorPath : '',
            shapeType: '//ly.img.ubq/shape/vector_path',
          }
        : {
            uri: image.resource || (image.src && image.src.original),
            fillType: '//ly.img.ubq/fill/image',
          }),
      thumbUri: image.preview || (image.src && image.src.medium) || '',
      width: isCustomShapesSection
        ? image.width || (image.size && image.size.width)
        : 128,
      height: isCustomShapesSection
        ? image.height || (image.size && image.size.height)
        : 128,
    },
    context: {
      sourceId:
        isCustomShapesSection || isCustomTemplatesSection
          ? sectionInfo.blockType
          : sectionInfo.sourceId,
    },
  };
};

export const findCustomAssets = async (queryData, info) => {
  let assetApiHandler = null;

  switch (info.sourceId) {
    case customAssetSourceId.templates:
      assetApiHandler = getCustomTemplates;
      break;
    case customAssetSourceId.stickers:
      assetApiHandler = getCustomStickers;
      break;
    case customAssetSourceId.shapes:
      assetApiHandler = getCustomShapes;
      break;
    case customAssetSourceId.customUploads:
      assetApiHandler = getAllUploadedImages;
      break;
    case customAssetSourceId.pexels:
      assetApiHandler = getCustomPhotos;
      break;
    case customAssetSourceId.systemImages:
      assetApiHandler = getSystemImages;
      break;
    case customAssetSourceId.images:
    default:
      assetApiHandler = info.sourceId.includes('photo')
        ? getCustomPhotos
        : getCustomImages;
      break;
  }

  const pageSize = customAssetSourceId.customUploads ? 20 : ASSETS_PAGE_SIZE;
  const assetCategory = info.sourceId.includes('photo')
    ? retrieveImageAssetCategoryName(info.sourceId)
    : info.assetCategoryId;

  const { results, count, photos, totalResults } = await assetApiHandler(
    queryData.query,
    queryData.page || 1,
    pageSize,
    assetCategory,
  );

  const nextPage =
    (count || totalResults) &&
    Math.ceil((count || totalResults) / pageSize) > queryData.page
      ? queryData.page + 1
      : undefined;

  let sceneAssets;

  if (info.sourceId === customAssetSourceId.shapes) {
    sceneAssets = results;
  } else if (results) {
    sceneAssets = results.filter((image) => {
      if (!image.preview) {
        return false;
      }

      if (!image.resource) {
        return false;
      }

      return true;
    });
  } else {
    sceneAssets = photos;
  }

  return {
    assets: sceneAssets.map((image) => translateToAssetResult(image, info)),
    total: count || totalResults || 0,
    currentPage: queryData.page,
    nextPage,
  };
};
