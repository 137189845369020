import React from 'react';

// types
import { IGenreAssetsContextMenu } from '../../../types/studio.types';

const GenreAssetsContextMenu = ({
  position,
  isShown,
  onDeleteGenre,
  // onLockUnlockAsset,
  onDownloadAsset,
  onStartEditing,
}: IGenreAssetsContextMenu) => {
  if (!isShown) {
    return null;
  }

  return (
    <div style={{ top: position.y, left: position.x }} className="context-menu">
      <div
        className="context-menu--option"
        id="context-menu-lock"
        onClick={onDownloadAsset}
      >
        <img src="/static/img/icons/export.svg" alt="download" width={14} />
        <span>Download</span>
      </div>
      <div
        className="context-menu--option"
        id="context-menu-lock"
        onClick={onStartEditing}
      >
        <img src="/static/img/icons/edit-white.svg" alt="download" width={14} />
        <span>Edit</span>
      </div>
      {/* <div
        className="context-menu--option"
        id="context-menu-lock"
        onClick={onLockUnlockAsset}
      >
        <img
          src="/static/img/icons/lock.svg"
          alt="delete"
          style={{ marginBottom: '4px' }}
        />
        <span>Lock/Unlock</span>
      </div> */}
      <div
        className="context-menu--option"
        id="context-menu-delete"
        onClick={onDeleteGenre}
      >
        <img src="/static/img/icons/delete-white.svg" alt="delete" />
        <span>Delete</span>
      </div>
    </div>
  );
};

export default GenreAssetsContextMenu;
