import React from 'react';

import { IHeader } from '../../types/studio.types';

import '../../styles/header.scss';

const Header = ({
  leftEl = null,
  middleEl = null,
  rightEl = null,
}: IHeader) => {
  return (
    <div className="header-container">
      <div className="header-container--left-container">{leftEl}</div>
      <div className="header-container--middle-container">{middleEl}</div>
      <div className="header-container--right-container">{rightEl}</div>
    </div>
  );
};

export default Header;
