import React from 'react';

// components
import CreateMoreFormatsOption from './CreateMoreFormatsOption';
import CreateNewVariantsOption from './CreateNewVariantsOption';
import SomethingCustomOption from './SomethingCustomOption';

// types
import { IDesignAssistanceOptionContent } from '../../../types/studio-re-gen';

const DesignAssistanceOptionContent = ({
  assistanceOption,
}: IDesignAssistanceOptionContent) => {
  switch (assistanceOption) {
    case 1: {
      return <CreateMoreFormatsOption />;
    }

    case 2: {
      return <CreateNewVariantsOption />;
    }

    case 3: {
      return <SomethingCustomOption />;
    }

    default:
      return null;
  }
};

export default DesignAssistanceOptionContent;
