import React, { useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// types
import { IReGenSecondStep, ITone } from '../../../types/studio-re-gen';
import { createReGen } from '../../../api/helpers';

// styles
import '../../../styles/studio/re-gen.scss';

const toneData = [
  {
    id: 1,
    icon: '/../static/img/scene-formats/casual.svg',
    title: 'casual',
  },

  {
    id: 22,
    icon: '/../static/img/scene-formats/creative.svg',
    title: 'creative',
  },
  {
    id: 3,
    icon: '/../static/img/scene-formats/professional.svg',
    title: 'professional',
  },
  {
    id: 4,
    icon: '/../static/img/scene-formats/exciting.svg',
    title: 'exciting',
  },
];

const Tone = ({ tone, onToneSelect, selectedTone }: ITone) => {
  return (
    <div
      className={cn('re-gen-ai-container--tones-container--tone', {
        selected: selectedTone === tone.title,
      })}
      onClick={() => onToneSelect(tone.title)}
    >
      <img
        src={tone.icon}
        alt="tone"
        className="re-gen-ai-container--tones-container--tone--icon"
      />
      <p className="re-gen-ai-container--tones-container--tone--title">
        {tone.title}
      </p>
    </div>
  );
};

const ReGenSecondStep = ({
  onMoveToPreloader,
  onMoveToThirdStep,
  selectedTemplate,
  onReGenCreate,
  onReGenStart,
  handleSelectTemplate,
}: IReGenSecondStep) => {
  const [selectedTone, setSelectedTone] = useState<null | string>(null);
  const [subject, setSubject] = useState('');

  const onToneSelect = (title: string) => {
    setSelectedTone(title);
  };

  const onSubjectChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubject(event.target.value);
  };

  const onSecondStepSubmit = () => {
    onMoveToPreloader();
    createReGen({
      inspoTemplate: selectedTemplate?.id,
      subject,
      tone: selectedTone,
    })
      .then((resp) => {
        onReGenCreate(resp);
        onMoveToThirdStep();
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with creating design',
          getToasterOptions(),
        );
        handleSelectTemplate(null);

        onReGenStart();
      });
  };

  return (
    <div className="re-gen--content">
      <div className="re-gen-ai-container">
        <div>
          <p className="label">Subject</p>
          <textarea
            cols={30}
            rows={10}
            className="re-gen-ai-container--subject--field"
            placeholder="What are you making?"
            onChange={onSubjectChange}
          />
          <p className="label mt-24">Tone</p>
          <div className="re-gen-ai-container--tones-container">
            {toneData.map((tone) => (
              <Tone
                key={tone.id}
                tone={tone}
                onToneSelect={onToneSelect}
                selectedTone={selectedTone}
              />
            ))}
          </div>
        </div>
        <div className="re-gen-button-container">
          <button
            type="submit"
            className={cn('re-gen-button-container--btn', {
              disabled: !selectedTone || !subject,
            })}
            onClick={onSecondStepSubmit}
            disabled={!selectedTone || !subject}
          >
            next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReGenSecondStep;
