import React from 'react';

const ProjectBrandRoomIcon = ({ fill = '#484848' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88621 18.759H4.52876C4.05334 18.759 3.65092 18.5943 3.32151 18.2649C2.9921 17.9355 2.82739 17.5331 2.82739 17.0577V4.4604C2.82739 3.98498 2.9921 3.58256 3.32151 3.25315C3.65092 2.92374 4.05334 2.75903 4.52876 2.75903H9.88621V18.759ZM8.47447 17.3473V4.17078H4.52876C4.44429 4.17078 4.3749 4.19793 4.3206 4.25224C4.26629 4.30654 4.23914 4.37593 4.23914 4.4604V17.0577C4.23914 17.1421 4.26629 17.2115 4.3206 17.2658C4.3749 17.3201 4.44429 17.3473 4.52876 17.3473H8.47447ZM11.7686 9.81785V2.75903H17.126C17.6014 2.75903 18.0039 2.92374 18.3333 3.25315C18.6627 3.58256 18.8274 3.98498 18.8274 4.4604V9.81785H11.7686ZM13.1803 8.40611H17.4156V4.4604C17.4156 4.37593 17.3885 4.30654 17.3342 4.25224C17.2799 4.19793 17.2105 4.17078 17.126 4.17078H13.1803V8.40611ZM11.7686 18.759V11.7002H18.8274V17.0577C18.8274 17.5331 18.6627 17.9355 18.3333 18.2649C18.0039 18.5943 17.6014 18.759 17.126 18.759H11.7686ZM13.1803 17.3473H17.126C17.2105 17.3473 17.2799 17.3201 17.3342 17.2658C17.3885 17.2115 17.4156 17.1421 17.4156 17.0577V13.112H13.1803V17.3473Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectBrandRoomIcon;
