import React, { useMemo, useEffect } from 'react';

import Breadcrumb from './Breadcrumb';

import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import { actions as sidebarContentActions } from '../../../ducks/sidebarContent';
import { IBreadcrumbs } from '../../../types/studio.types';

import '../../../styles/sidebar/gridContent/breadcrumbs.scss';

export default ({ entityId }: IBreadcrumbs) => {
  const dispatch = useAppDispatch();

  const selectedGroupId = useAppSelector(
    (state) => state.sidebarContent.entities[entityId]?.selectedGroupId || null,
  );

  const breadcrumbs = useAppSelector(
    (state) => state.sidebarContent.entities[entityId]?.breadcrumbs || [],
  );

  const length = useMemo(() => breadcrumbs.length, [breadcrumbs]);

  useEffect(() => {
    dispatch(
      sidebarContentActions.updateBreadcrumbs({
        entityId,
        groupId: selectedGroupId,
      }),
    );
  }, [selectedGroupId, dispatch, entityId]);

  if (length <= 1) return null;

  return (
    <>
      <div className="AssetLibraryBreadcrumb__breadcrumb">
        {breadcrumbs.map((id, index) => (
          <Breadcrumb
            entityId={entityId}
            groupId={id}
            isLast={index + 1 === length}
            key={`breadcrumb_${id}`}
          />
        ))}
      </div>
      <hr className="Separator__block" />
    </>
  );
};
