import React from 'react';
import cn from 'classnames';

// types
import { IFormatExtenderTopbar } from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const FormatExtenderTopbar = ({ step }: IFormatExtenderTopbar) => {
  let topbarHeading = '';
  let topbarSubheading = '';
  let topbarHidden = false;

  switch (step) {
    case 1: {
      topbarHeading = 'format extender';
      topbarSubheading = 'Select one of your projects below';

      break;
    }

    case 2: {
      topbarHeading = 'format extender';
      topbarSubheading = 'Select the formats you’d like';

      break;
    }

    case 3: {
      topbarHeading = '3/ pick your design(s)';
      topbarSubheading = `
          Select the generated options you’d like to save to your project
          folder, or download them directly.
        `;

      break;
    }

    case 4: {
      topbarHeading = 'complete!';
      topbarSubheading = 'format-extender is working!';

      break;
    }

    default: {
      topbarHeading = '';
      topbarSubheading = '';
      topbarHidden = true;
    }
  }

  return (
    <div
      className={cn('format-extender--topbar', {
        hidden: topbarHidden,
      })}
    >
      <h2 className="format-extender--topbar--heading">{topbarHeading}</h2>
      <p className="format-extender--topbar--subheading">{topbarSubheading}</p>
    </div>
  );
};

export default FormatExtenderTopbar;
