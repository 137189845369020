import React, { useState, useEffect } from 'react';
import cn from 'classnames';

// api
import { getTemplates } from '../../../api/assets';

// types
import { IReGenFirstStep, IReGenTemplate } from '../../../types/studio-re-gen';
import { TemplateType } from '../../../types/studio.types';

// styles
import '../../../styles/studio/re-gen.scss';

const ReGenTemplate = ({
  template,
  selectedTemplate,
  handleSelectTemplate,
}: IReGenTemplate) => {
  return (
    <div
      className="re-gen-inspo-container--templates-container--template"
      onClick={() => handleSelectTemplate(template)}
    >
      <img
        src={template.previewMedium}
        alt="template"
        className="re-gen-inspo-container--templates-container--template--image"
      />
      <div
        className={cn(
          're-gen-inspo-container--templates-container--template--selected-container',
          {
            selected: template.id === selectedTemplate?.id,
          },
        )}
      >
        <img src="/static/img/icons/white-tick-in-circle.svg" alt="tick" />
      </div>
    </div>
  );
};

const ReGenFirstStep = ({
  selectedTemplate,
  onMoveToSecondStep,
  handleSelectTemplate,
}: IReGenFirstStep) => {
  const [templates, setTemplates] = useState<TemplateType[]>([]);

  useEffect(() => {
    getTemplates('', 1, 50, true).then((resp) => {
      setTemplates(resp.results);
    });
  }, []);

  return (
    <div className="re-gen--content">
      <div className="re-gen-inspo-container">
        <div className="re-gen-inspo-container--templates-container">
          {templates.map((template) => (
            <ReGenTemplate
              key={template.id}
              template={template}
              selectedTemplate={selectedTemplate}
              handleSelectTemplate={handleSelectTemplate}
            />
          ))}
        </div>
        <div className="re-gen-button-container">
          <button
            type="button"
            className={cn('re-gen-button-container--btn', {
              disabled: !selectedTemplate,
            })}
            onClick={onMoveToSecondStep}
            disabled={!selectedTemplate}
          >
            next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReGenFirstStep;
