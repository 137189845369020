import React, { useEffect } from 'react';

import Breadcrumbs from './Breadcrumbs';
import Grid from './Grid';
import Groups from './Groups';

import { useAppSelector, useAppDispatch } from '../../../helpers/hooks';
import { actions as sidebarContentActions } from '../../../ducks/sidebarContent';
import { IGridContent } from '../../../types/studio.types';

export default ({
  id: entityId,
  groupParams = { elementsInRow: 2, backgroundCover: false },
  gridParams = { elementsInRow: 2, backgroundCover: true },
}: IGridContent) => {
  const dispatch = useAppDispatch();
  const { rootGroupId, selectedGroupId } =
    useAppSelector((state) => state.sidebarContent.entities[entityId]) || {};

  const { assets, groups }: { assets?: object; groups?: object } =
    useAppSelector((state) =>
      selectedGroupId
        ? state.sidebarContent.entities[entityId]?.data.groups[
            selectedGroupId
          ] || {}
        : {},
    );

  useEffect(() => {
    dispatch(
      sidebarContentActions.setSelectedGroupId({
        entityId,
        groupId: rootGroupId || null,
      }),
    );
  }, [entityId, rootGroupId, dispatch]);

  return (
    <>
      <Breadcrumbs entityId={entityId} />
      {groups && <Groups entityId={entityId} groupParams={groupParams} />}
      {assets && !groups && (
        <Grid entityId={entityId} gridParams={gridParams} />
      )}
    </>
  );
};
